import Api from '../../commons/api';
import { getCoreApi, getInsightsAnalyticApi, getResourceApi } from '../../commons/utils';
import { ISpaceUser } from '../../types/User';
import { VideoChatMessageDetails, VideoChatMessageResponse } from '../ChatInfoTable/types';
import { SpaceAnalyticsSummary } from '../Summary/type';
import { SpaceAnalyticsQueryParams } from '../hooks/useSpaceAnalyticsQuery';
import { ApiResult } from '../types/common';
import { EngagementSummary, EngagementUserSummary } from '../types/engagement';
import { AllResourceMetricsResponse, ResourcesSummary } from '../types/resources';
import { UserTrafficSummary, SpaceTrafficSummary } from '../types/userTraffic';

export function fetchEngagementSummary({
  spaceId,
  startDate,
  endDate,
  excludedUserInfoIds = [],
  isPublic = false,
  users = [],
}: SpaceAnalyticsQueryParams) {
  const exUserInfoIds = excludedUserInfoIds as number[];
  let url = `/api/spaces/${spaceId}/engagement/summary?startDate=${startDate}&endDate=${endDate}`;
  if (isPublic) {
    url += `&isPublic=${isPublic}`;
  }
  if (exUserInfoIds.length > 0) {
    const excludes = `excludes=${exUserInfoIds.join('&excludes=')}`;
    url += `&${excludes}`;
  }
  return Api.post(`${getInsightsAnalyticApi()}${url}`, {
    users: users,
  }).then((resp) => resp as ApiResult<EngagementSummary>);
}

export async function fetchSpaceUsersBasicInfo(
  spaceId: string,
  userInfoId: number | undefined = undefined
) {
  let url = `/spaces/${spaceId}/users-basic-info`;
  if (userInfoId) {
    url += `?userInfoId=${userInfoId}`;
  }
  return Api.get(`${getCoreApi()}${url}`);
}

export function fetchEngagementUserSummary({
  spaceId,
  userInfoId,
  startDate,
  endDate,
  isPublic,
}: SpaceAnalyticsQueryParams) {
  return Api.get(
    `${getInsightsAnalyticApi()}/api/spaces/${spaceId}/engagement/users/${userInfoId}/summary?startDate=${startDate}&endDate=${endDate}&isPublic=${isPublic}`
  ).then((resp) => resp as ApiResult<EngagementUserSummary>);
}

export function fetchEngagementUsers(spaceId: string, startDate: string, endDate: string) {
  return Api.get(
    `${getInsightsAnalyticApi()}/api/spaces/${spaceId}/engagement/users?startDate=${startDate}&endDate=${endDate}`
  );
}

export function fetchUserTraffic({
  spaceId,
  startDate,
  endDate,
  isPublic,
  excludedUserInfoIds = [],
  userInfoIds = [],
}: SpaceAnalyticsQueryParams) {
  const exUserInfoIds = excludedUserInfoIds as number[];

  const baseUrl = `/api/spaces/${spaceId}/user-traffic/summary?startDate=${startDate}&endDate=${endDate}`;
  let url = `${baseUrl}`;
  if (isPublic) {
    url += `&isPublic=${isPublic}`;
  }
  if (exUserInfoIds.length > 0) {
    const excludes = `excludes=${exUserInfoIds.join('&excludes=')}`;
    url += `&${excludes}`;
  }

  return Api.post(`${getInsightsAnalyticApi()}${url}`, { userInfoIds }).then(
    (resp) => resp as ApiResult<SpaceTrafficSummary>
  );
}

export function fetchUserTrafficDetails(
  spaceId: string,
  startDate: string,
  endDate: string,
  participantId: number,
  isPublic: boolean | undefined
) {
  return Api.get(
    `${getInsightsAnalyticApi()}/api/spaces/${spaceId}/user-traffic/${participantId}/detail?startDate=${startDate}&endDate=${endDate}&isPublic=${
      isPublic || false
    }`
  ).then((resp) => resp as ApiResult<UserTrafficSummary>);
}

export function downloadUserTrafficParticipants(
  startDate: string,
  endDate: string,
  participantsInfo: ISpaceUser[],
  isPublic: boolean | undefined,
  spaceId?: string
) {
  return Api.post(
    `${getInsightsAnalyticApi()}/api/spaces/${spaceId}/user-traffic/detail/download?startDate=${startDate}&endDate=${endDate}&isPublic=${
      isPublic || false
    }`,
    {
      participantsInfo,
    }
  );
}

export async function fetchResourcesSummary({
  spaceId,
  startDate,
  endDate,
  isPublic = false,
  availableMaterialIds,
  excludedUserInfoIds = [],
  userInfoIds = [],
}: SpaceAnalyticsQueryParams) {
  const exUserInfoIds = excludedUserInfoIds as number[];
  let url = `/api/spaces/${spaceId}/resources/summary?startDate=${startDate}&endDate=${endDate}&isPublic=${isPublic}`;
  if (exUserInfoIds.length > 0) {
    const excludes = `excludes=${exUserInfoIds.join('&excludes=')}`;
    url += `&${excludes}`;
  }
  return Api.post(`${getInsightsAnalyticApi()}${url}`, { availableMaterialIds, userInfoIds }).then(
    (resp) => resp as ApiResult<ResourcesSummary>
  );
}

export async function fetchResourcesMetrics({
  spaceId,
  startDate,
  endDate,
  groupBy,
  isPublic,
  availableMaterialIds,
  notSupportedViewMaterialIds,
  excludedUserInfoIds = [],
  userInfoIds = [],
}: SpaceAnalyticsQueryParams) {
  const exUserInfoIds = excludedUserInfoIds as number[];
  let url = `/api/spaces/${spaceId}/resources?groupBy=${groupBy}&startDate=${startDate}&endDate=${endDate}&isPublic=${isPublic}`;
  if (exUserInfoIds.length > 0) {
    const excludes = `excludes=${exUserInfoIds.join('&excludes=')}`;
    url += `&${excludes}`;
  }
  return Api.post(`${getInsightsAnalyticApi()}${url}`, {
    availableMaterialIds,
    notSupportedViewMaterialIds,
    userInfoIds,
  }).then((resp) => resp as ApiResult<AllResourceMetricsResponse>);
}

export async function fetchSpaceResourcesByUser(
  spaceId: string,
  userInfoId: number,
  startDate: string,
  endDate: string,
  isPublic: boolean,
  availableMaterialIds: number[],
  notSupportedViewMaterialIds: number[]
) {
  return Api.post(
    `${getInsightsAnalyticApi()}/api/spaces/${spaceId}/users/${userInfoId}/resources?startDate=${startDate}&endDate=${endDate}&isPublic=${isPublic}`,
    { availableMaterialIds, notSupportedViewMaterialIds }
  );
}

export function fetchSpaceChatSummary(spaceId: string, excludeUserIds: number[]) {
  const userInfoIds = excludeUserIds as number[];
  let url = `/spaces/${spaceId}/comments/summary`;
  if (userInfoIds.length > 0) {
    const excludes = `excludes=${userInfoIds.join('&excludes=')}`;
    url += `?${excludes}`;
  }
  return Api.get(`${getCoreApi()}${url}`);
}

export function fetchSpaceChatDetail(spaceId: string, participantId: number, endDate: string) {
  return Api.get(
    `${getCoreApi()}/spaces/${spaceId}/comments/detail?ParticipantId=${participantId}&endDate=${endDate}`
  );
}

export function getVideoMessageSummaryByIds({
  spaceId,
  startDate,
  endDate,
  chatMessageId,
  userInfoIds,
  excludedUserInfoIds,
}: SpaceAnalyticsQueryParams) {
  const exUserInfoIds = excludedUserInfoIds as number[];
  let url = `/api/spaces/${spaceId}/video-message/summary`;
  if (exUserInfoIds.length > 0) {
    const excludes = `excludes=${exUserInfoIds.join('&excludes=')}`;
    url += `?${excludes}`;
  }
  return Api.post(`${getInsightsAnalyticApi()}${url}`, {
    startDate,
    endDate,
    chatMessageId,
    userInfoIds,
  }).then((resp) => resp as ApiResult<VideoChatMessageResponse>);
}

export function getVideoMessageDetailByIds({
  spaceId,
  startDate,
  endDate,
  chatMessageId,
  excludedUserInfoIds,
}: SpaceAnalyticsQueryParams) {
  const userInfoIds = excludedUserInfoIds as number[];
  let url = `/api/spaces/${spaceId}/video-message/detail`;
  if (userInfoIds.length > 0) {
    const excludes = `excludes=${userInfoIds.join('&excludes=')}`;
    url += `?${excludes}`;
  }
  return Api.post(`${getInsightsAnalyticApi()}${url}`, {
    startDate,
    endDate,
    chatMessageId,
  }).then((resp) => resp as ApiResult<VideoChatMessageDetails>);
}

export const fetchSpaceMaterialsBasicInfo = (spaceId: string, cmsMaterialIds: number[]) => {
  if (!spaceId) {
    return Promise.resolve({ resources: [] });
  }
  return Api.post(`${getResourceApi()}/api/spaces/${spaceId}/resources?forAnalytics=true`, {
    cmsMaterialIds,
  });
};

export function fetchSpaceResourceDetailsById(
  spaceId: string,
  materialId?: number,
  startDate?: string,
  endDate?: string,
  isPublic?: boolean,
  excludedUserInfoIds?: number[],
  userInfoIds?: number[]
) {
  let url = `/api/spaces/${spaceId}/resources/${materialId}?startDate=${startDate}&endDate=${endDate}&isPublic=${
    isPublic || false
  }`;
  if (excludedUserInfoIds && excludedUserInfoIds.length > 0) {
    const excludes = `excludes=${excludedUserInfoIds.join('&excludes=')}`;
    url += `&${excludes}`;
  }
  return Api.post(`${getInsightsAnalyticApi()}${url}`, { userInfoIds }).then((resp) => resp);
}

export function fetchSpaceAnalyticsSummary({
  spaceId,
  startDate,
  endDate,
  excludedUserInfoIds = [],
  isPublic = false,
  users = [],
  availableMaterialIds = [],
}: SpaceAnalyticsQueryParams) {
  const exUserInfoIds = excludedUserInfoIds as number[];
  let url = `/api/spaces/${spaceId}/summary?startDate=${startDate}&endDate=${endDate}`;
  if (isPublic) {
    url += `&isPublic=${isPublic}`;
  }
  if (exUserInfoIds.length > 0) {
    const excludes = `excludes=${exUserInfoIds.join('&excludes=')}`;
    url += `&${excludes}`;
  }
  return Api.post(`${getInsightsAnalyticApi()}${url}`, {
    users: users,
    availableMaterialIds: availableMaterialIds,
  }).then((resp) => resp as ApiResult<SpaceAnalyticsSummary>);
}
