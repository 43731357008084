import React, { PureComponent, createRef } from 'react';
import { createRoot } from 'react-dom/client';

import PropTypes from 'prop-types';
import { IconButton, ThemeProvider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import i18n from '../../i18n';
import OfficialButton from '../OfficialButtons';
import { getTheme } from '../../theme';
import './AddNewParticipantDialog.scss';
import TextFieldEnhanced from '../TextFieldEnhanced';
import { isValidEmail } from '../../commons/ValidationUtils';

let resolve;
let containerElement;
let rootEl;

class AddNewParticipantDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      email: props.emailDefault || '',
      firstName: '',
      lastName: '',
      error: '',
      users: props.users,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.isValid = this.isValid.bind(this);
    this.dialogRef = createRef();
    this.emailRef = createRef();
    this.firstNameRef = createRef();
    this.lastNameRef = createRef();
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    console.log('dialogRef', this.dialogRef.current);
    if (this.dialogRef.current && !this.dialogRef.current.contains(event.target)) {
      this.handleClose();
    }
  }

  static destroy(data) {
    if (rootEl) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 100);
    }
    if (resolve) {
      resolve(data);
      resolve = null;
    }
  }

  static show({ emailDefault, users }) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    document.body.appendChild(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <AddNewParticipantDialog emailDefault={emailDefault} users={users} />
      </ThemeProvider>
    );

    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose = (data) => {
    this.setState({ isOpen: false }, () => AddNewParticipantDialog.destroy(data));
  };

  handleCancel = () => {
    this.setState({ isOpen: false }, () => AddNewParticipantDialog.destroy());
  };

  handleInputChange(name, value) {
    console.log('event', name, value);
    this.setState({ [name]: value });
  }

  validateEmail(value) {
    let error = null;
    const lowerCaseValue = value.toLowerCase();
    if (!isValidEmail(lowerCaseValue)) {
      error = i18n.t('Please enter a valid email address.');
    }
    if (this.state.users.some((participant) => participant.contactEmail === lowerCaseValue)) {
      error = i18n.t('This email address is already in use by another user.');
    }
    return error;
  }

  isValid() {
    const isValidFirstName = this.firstNameRef.current.isValid();
    const isValidLastName = this.lastNameRef.current.isValid();
    const isEmailValid = this.emailRef.current.isValid();
    console.log('isEmailValid: ', isEmailValid);
    return isValidFirstName && isValidLastName && isEmailValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email, firstName, lastName } = this.state;
    const isValid = this.isValid();
    console.log('isValid: ', isValid);
    if (isValid) {
      const data = { email, firstName, lastName };
      this.handleClose(data);
    }
  }

  render() {
    const { email, firstName, lastName } = this.state;

    if (!this.state.isOpen) {
      return null;
    }

    return (
      <div className="white add-new-participant-dialog" id="add-new-participant-dialog">
        <form onSubmit={this.handleSubmit} ref={this.dialogRef} noValidate>
          <div className="mobile-join-space-box">
            <div className="content" ref={this.dialogRef}>
              <IconButton
                aria-label="close"
                onClick={this.handleCancel}
                className="close-button"
                color="primary"
              >
                <CloseIcon className="close-icon" />
              </IconButton>
              <div className="message">
                <div className="title">{i18n.t(`Add new user`)}</div>
              </div>
              <div className="form-content">
                <div className="form fullwidth">
                  <div className="form-item">
                    <p className="item-label">{i18n.t('Email')}</p>

                    <TextFieldEnhanced
                      autoFocus
                      ref={this.emailRef}
                      required
                      name="email"
                      maxLength={100}
                      variant="outlined"
                      placeholder={i18n.t('Your Email')}
                      defaultValue={email}
                      validate={this.validateEmail}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form">
                  <div className="form-item">
                    <p className="item-label">{i18n.t('First name')}</p>

                    <TextFieldEnhanced
                      ref={this.firstNameRef}
                      required
                      maxLength={100}
                      name="firstName"
                      placeholder={i18n.t('First name')}
                      value={firstName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-item">
                    <p className="item-label">{i18n.t('Last name')}</p>

                    <TextFieldEnhanced
                      ref={this.lastNameRef}
                      required
                      maxLength={100}
                      name="lastName"
                      placeholder={i18n.t('Last name')}
                      value={lastName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="button-wrapper">
                <OfficialButton variant="large-primary" type="submit" label={i18n.t('Add')} />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

AddNewParticipantDialog.propTypes = {
  emailDefault: PropTypes.string,
  users: PropTypes.instanceOf(Array),
};

export default AddNewParticipantDialog;
