/* eslint react/jsx-props-no-spreading: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { useDebouncedCallback } from 'use-debounce';
import { TextField, Chip } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import _remove from 'lodash/remove';
import _isObject from 'lodash/isObject';

import ParticipantSelectOption from './ParticipantSelectOption';

import i18n from '../../i18n';
import AddNewParticipantDialog from './AddNewParticipantDialog';
import './AutocompleteUsers.scss';
import { useAppContext } from '../AppProvider';
import useSpaceUsersQuery from '../../spaces/SpaceUsers/useSpaceUsersQuery';
import useOrganizationsUsersQuery from '../../users/useOrganizationsUsersQuery';

const getOptions = memoizeOne((users, exceptUsers) => {
  const exceptUserIds = exceptUsers.map((e) => e.userInfoId);
  const options = users.map((e) => ({
    id: e.userInfoId,
    fullName: e.fullName,
    email: e.contactEmail,
    firstName: e.firstName,
    lastName: e.lastName,
    disabled: exceptUserIds.includes(e.userInfoId),
  }));
  return options;
});

const getSelectedOptions = memoizeOne((users, value) => {
  const result = [];
  users.forEach((u) => {
    if (value.includes(u.id)) {
      result.push(u);
    }
  });
  return result;
});

const filter = createFilterOptions({
  matchFrom: 'any',
  limit: 20,
  stringify: (option) => `${option.email}`,
});

const filterOptions = memoizeOne((opts, inputValue) => {
  let options = opts;
  if (inputValue.length === 0) {
    if (!opts.find((o) => o.selectedAll)) {
      options.unshift({ selectedAll: true });
    }
    if (!options.find((o) => o.isNewEmail)) {
      options.unshift({ title: 'Add new', isNewEmail: true, label: 'add new' });
    }
    return options;
  } else {
    options = options.filter((o) => !o.selectedAll && !o.isNewEmail);
    const filtered = filter(options, { inputValue });

    if (filtered.length === 0 && inputValue.length > 0 && !filtered.find((o) => o.isNewEmail)) {
      filtered.unshift({ title: 'Add new', isNewEmail: true, label: 'add new' });
    }
    return filtered;
  }
});

function validateInput(value, props) {
  if (!value) return null;
  if (props?.required && !value?.length) {
    return { type: 'required' };
  }
  return null;
}

function renderErrorMessage(errors, property) {
  if (errors && errors[property]) {
    if (errors[property].type === 'required') {
      return i18n.t('This field is required');
    }
  }
  return null;
}

function getValidOptions(options) {
  return options?.filter((item) => item.disabled !== true && !item.selectedAll && !item.isNewEmail);
}

function AutocompleteUsers(props) {
  const { spaceId } = props;
  const { isPortal } = useAppContext();
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(props.defaultValue || []); // array/list of selected options
  const [inputValue, setInputValue] = useState(''); // for filter purpose
  const [errors, setErrors] = useState(null); // for validation
  const getSpaceUsersQuery = useSpaceUsersQuery(spaceId, isPortal);

  const { data: orgUsers } = useOrganizationsUsersQuery(true, 'space-users-select');

  useEffect(() => {
    if (orgUsers) {
      setUsers(orgUsers);
    }
  }, [orgUsers]);

  useEffect(() => {
    if (users && getSpaceUsersQuery.data) {
      const optionsData = getOptions(users, getSpaceUsersQuery.data);
      setOptions(optionsData);
    }
  }, [users, getSpaceUsersQuery.data]);

  const availableOptions = getValidOptions(options);
  const isSelectAll = value.length === availableOptions?.length;

  function handleInputChanged(event, newValue) {
    setInputValue(newValue);
  }
  function handleOnChangeUsers(newValue) {
    if (props.onChange) {
      const data = newValue.map((e) => {
        return {
          email: e.email,
          firstName: e.firstName,
          lastName: e.lastName,
        };
      });
      props.onChange(data);
    }
  }
  function handleAddNewParticipant(data) {
    const newValues = [data.id, ...value];
    const newUsers = [data, ...users];
    const newOptions = [data, ...options];

    setValue(newValues);
    setUsers(newUsers);
    setOptions(newOptions);
    const newData = newOptions.filter((e) => newValues.includes(e.id));
    handleOnChangeUsers(newData);
  }

  const handleAddNewUser = async (text = '') => {
    const emailDefault = text || inputValue || '';

    try {
      const newParticipant = await AddNewParticipantDialog.show({
        emailDefault,
        users,
      });
      if (newParticipant) {
        const { email, firstName, lastName } = newParticipant;
        const data = {
          email: email,
          firstName: firstName,
          lastName: lastName,
          id: users.length + 1,
          fullName: `${firstName} ${lastName}`,
          selected: true,
          userInfoId: users.length + 1,
          contactEmail: email,
        };
        handleAddNewParticipant(data);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleOnClickAddNew = (participant) => {
    handleAddNewUser(participant.email);
  };

  function handleInputValidation(name, newValue) {
    // newValue is always an array
    const validationMethod = props.validationMethod || validateInput;
    const result = validationMethod(newValue, props);

    let newErrors = null;
    if (errors) {
      newErrors = { errors };
      delete newErrors[name];
    }

    let isValid = true;
    if (result) {
      isValid = false;
      newErrors = { ...newErrors, [name]: result };
    }

    if (newErrors && Object.keys(newErrors).length === 0) newErrors = null;
    setErrors(newErrors);

    return isValid;
  }
  const handleSelectAll = () => {
    const finalValue = isSelectAll ? [] : availableOptions;

    setValue(finalValue?.map((e) => e.id));
    handleOnChangeUsers(finalValue);
  };

  function handleOnChange(event, newValue, reason) {
    if (typeof newValue === 'string') return;
    if (!newValue) {
      setValue([]);
      return;
    }
    const isAddNew = newValue?.find((e) => e?.isNewEmail);

    if (
      (event.type === 'keydown' && event.code === 'Enter' && isAddNew) ||
      reason === 'createOption'
    ) {
      handleAddNewUser(inputValue);
      return;
    }

    if (reason === 'selectOption' || reason === 'removeOption') {
      if (newValue.find((option) => option?.selectedAll)) {
        handleSelectAll();
      } else {
        setValue(() => {
          const finalValue = newValue.filter(
            (e) => _isObject(e) && e?.disabled !== true && !e.selectedAll && !e.isNewEmail
          );
          const foundInputValues = _remove(newValue, (e) => e?.inputValue);

          if (foundInputValues.length > 0) {
            const option = options.find((o) => o.email === foundInputValues[0].inputValue.trim());
            if (option) {
              finalValue.push(option);
            }
          }
          const finalValueIds = finalValue.map((e) => e.id);

          handleInputValidation(props.name, finalValueIds);
          handleOnChangeUsers(finalValue);
          return finalValueIds;
        });
      }
    }
  }

  const handleInputChangedDebounced = useDebouncedCallback((e, v) => handleInputChanged(e, v), 300);

  const getFilterOptions = () => {
    const filtereds = filterOptions(options, inputValue) || [];

    const selectedAllOption = filtereds.find((item) => item.selectedAll);
    if (inputValue.length === 0) {
      selectedAllOption.selected = isSelectAll;
      selectedAllOption.indeterminate = value.length > 0 && value.length < availableOptions.length;
      selectedAllOption.selectedText = `${value.length}/${availableOptions.length} selected`;
      selectedAllOption.label = 'select all';
    }

    return filtereds;
  };

  function render() {
    return (
      <div className="auto-complete-users-input">
        <Autocomplete
          id="user-select-autocomplete"
          classes={{
            root: 'user-select-autocomplete',
            popper: 'user-select-autocomplete-popper',
          }}
          fullWidth
          handleHomeEndKeys
          disableCloseOnSelect
          multiple
          options={options}
          getOptionLabel={(option) => option?.email || option.label}
          onInputChange={handleInputChangedDebounced}
          value={getSelectedOptions(options, value)}
          onChange={handleOnChange}
          filterOptions={getFilterOptions}
          getOptionDisabled={(option) => option?.disabled}
          freeSolo
          clearOnBlur
          renderTags={(tags, getTagProps) => {
            return tags.map((option, index) => (
              <Chip
                color="primary"
                label={`${option.fullName}`}
                deleteIcon={<CloseIcon />}
                {...getTagProps({ index })}
              />
            ));
          }}
          renderOption={(optionProps, option, { selected }) => {
            return (
              <ParticipantSelectOption
                {...optionProps}
                option={option}
                selected={selected}
                canSelect
                disabledText={i18n.t('This user is already added to the space.')}
                onAddParticipantClick={handleOnClickAddNew}
              />
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              autoFocus
              name={props.name} // must have the name & autocomplete=new-password to disable autocomplete and autofill
              variant="outlined"
              error={!!errors?.[props.name]}
              helperText={(props.renderErrorMessageMethod || renderErrorMessage)(
                errors,
                props.name
              )}
              placeholder={value.length ? '' : props.placeholder}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              className="participants-select-control-input"
            />
          )}
        />
      </div>
    );
  }
  return render();
}

AutocompleteUsers.propTypes = {
  spaceId: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  defaultValue: PropTypes.instanceOf(Array),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onAddParticipantClick: PropTypes.func,
};

AutocompleteUsers.defaultProps = {
  multiple: true,
  required: false,
  defaultValue: null,
  placeholder: null,
  name: 'user-select-autocomplete-input',
};

export default AutocompleteUsers;
