import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Range } from 'react-date-range';

import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import Loading from '../components/Loading';
import { ISpace } from '../types/types';
import { CacheKeys } from '../app/queryCache';
import { getSpaceById } from '../homepage/services';
import SpaceVideoMessageModal from './SpaceUserDetailsModal/SpaceVideoMessageModal';
import ResourceDetailsModal, { SpaceResourceDetailsTab } from './ResourceDetailsModal';
import { MaterialBasicInfo, ResourceMetricsDataItem } from './types/resources';
import { FeatureFlagsType, PrivacySpace } from '../app/appConstants';
import useSpaceUsersBasicInfo from './queries/useSpaceUsersBasicInfo';
import { useLocalStorage } from '../commons/CustomHooks';
import { newDate } from '../commons/DateTimeUtils';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { ISpaceUser } from '../types/User';
import { calculateStartDate } from '../CompanyAnalytics/utils/DateFormatUtils';
import SpaceUserDetailsModal, { SpaceUserDetailsTab } from './SpaceUserDetailsModal';

interface SpaceAnalyticsContextProps {
  space?: ISpace;
  reportEndDate: Date;
  users: ISpaceUser[];
  visibleUsers: ISpaceUser[];
  excludeUserIds: number[];
  isFetchingUsers: boolean;
  openSpaceUserModal: (
    userInfo: ISpaceUser,
    tab: (typeof SpaceUserDetailsTab)[keyof typeof SpaceUserDetailsTab]
  ) => void;
  getLastThirtyDays: () => Date | undefined;
  openSpaceVideoMessageModal: (messageId: number, title: string) => void;
  openSpaceResourceModal: (
    resource: ResourceMetricsDataItem<MaterialBasicInfo>,
    tab: (typeof SpaceResourceDetailsTab)[keyof typeof SpaceResourceDetailsTab]
  ) => void;
  selectedResource: ResourceMetricsDataItem<MaterialBasicInfo> | undefined;
  isPublic?: boolean;
  isExcludeInternalUser: boolean;
  setIsExcludeInternalUser: (checked: boolean) => void;
  dateRange: DateFilter;
  handleFilterByDateChange: () => void;
}

interface DateFilter {
  startDate?: string;
  endDate?: string;
}

const SpaceAnalyticsContext = createContext<SpaceAnalyticsContextProps>({
  space: undefined,
  reportEndDate: new Date(),
  users: [],
  visibleUsers: [],
  excludeUserIds: [],
  isFetchingUsers: false,
  openSpaceUserModal: () => {},
  getLastThirtyDays: () => undefined,
  openSpaceVideoMessageModal: () => {},
  openSpaceResourceModal: () => {},
  selectedResource: undefined,
  isPublic: undefined,
  isExcludeInternalUser: false,
  setIsExcludeInternalUser: () => {},
  dateRange: {},
  handleFilterByDateChange: () => {},
});

const getSpaceAnalyticsContext = memoizeOne(
  (
    space,
    reportEndDate,
    users,
    visibleUsers,
    excludeUserIds,
    isFetchingUsers,
    openSpaceUserModal,
    getLastThirtyDays,
    openSpaceVideoMessageModal,
    openSpaceResourceModal,
    selectedResource,
    isExcludeInternalUser,
    setIsExcludeInternalUser,
    dateRange,
    handleFilterByDateChange
  ) => {
    return {
      space,
      reportEndDate,
      users,
      visibleUsers,
      excludeUserIds,
      isFetchingUsers,
      openSpaceUserModal,
      getLastThirtyDays,
      openSpaceVideoMessageModal,
      openSpaceResourceModal,
      selectedResource,
      isPublic: space?.spacePrivacy === PrivacySpace.PUBLIC,
      isExcludeInternalUser,
      setIsExcludeInternalUser,
      dateRange,
      handleFilterByDateChange,
    };
  }
);

export const SpaceAnalyticsContextProvider = ({ children }: { children: ReactNode }) => {
  const [space, setSpace] = useState<ISpace>();
  const [reportEndDate, setReportEndDate] = useState(new Date());
  const [isVideoMessage, setIsVideoMessage] = useState<boolean | null>(false);
  const [resourceId, setResourceId] = useState<number>(0);
  const [videoMessageTitle, setVideoMessageTitle] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<ISpaceUser | null>(null);
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [datePickerEnabled] = checkEnabledFeatures([FeatureFlagsType.DATE_PICKER]);
  const [dateRange, setDateRange] = useLocalStorage('spaceAnalyticsDateRange', {
    startDate: datePickerEnabled ? calculateStartDate('Last 30 days') : space?.created,
    endDate: newDate().set({ hour: 23, minute: 59, second: 59 }).utc().format(),
  });
  const [defaultTab, setDefaultTab] = useState<
    (typeof SpaceUserDetailsTab)[keyof typeof SpaceUserDetailsTab]
  >(SpaceUserDetailsTab.ENGAGEMENT);
  const { spaceId } = useParams<{ spaceId?: string }>();

  const [isExcludeInternalUser, setIsExcludeInternalUser] = useState<boolean>(false);

  const [selectedResource, setSelectedResource] =
    useState<ResourceMetricsDataItem<MaterialBasicInfo> | null>(null);

  const {
    users,
    visibleUsers,
    excludeUserIds,
    isFetching: isFetchingUsers,
  } = useSpaceUsersBasicInfo(spaceId, moment(reportEndDate).toISOString(), isExcludeInternalUser);

  const { data, isLoading: isFetchingSpace } = useQuery({
    queryKey: [CacheKeys.getSpaceById, spaceId],
    queryFn: async () => {
      const resp = await getSpaceById(spaceId, true);
      return resp; // why we have this change (use resp but not resp.space here). The query had been using in many places, query data might be cached with format data.space, so we need to keep the same format
    },

    retry: 1,
    retryDelay: () => 5000,
    // cacheTime: 0,
    enabled: !!spaceId,
  });

  const handleFilterByDateChange = React.useCallback(
    (value: Range) => {
      if (typeof value === 'object' && value.startDate && value.endDate) {
        const formattedStartDate = format(value.startDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        const formattedEndDate = format(value.endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        setDateRange({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      } else if (typeof value === 'string') {
        const newStartDate = calculateStartDate(value);
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);
        const newEndDate = format(endOfDay, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        setDateRange({
          startDate: newStartDate,
          endDate: newEndDate,
        });
      }
    },
    [setDateRange]
  );

  useEffect(() => {
    if (data) {
      setSpace(data.space);
    }
  }, [data, spaceId]);

  useEffect(() => {
    setReportEndDate(new Date());
  }, []);

  const openSpaceUserModal = useCallback(
    (userInfo: ISpaceUser, tab: (typeof SpaceUserDetailsTab)[keyof typeof SpaceUserDetailsTab]) => {
      setDefaultTab(tab);
      setSelectedUser(userInfo);
    },
    []
  );

  const openSpaceVideoMessageModal = useCallback((messageId: number, title: string) => {
    if (messageId) {
      setIsVideoMessage(true);
      setResourceId(messageId);
      setVideoMessageTitle(title === '' ? 'Untitled video message' : title);
    }
  }, []);

  const openSpaceResourceModal = useCallback(
    (
      resource: ResourceMetricsDataItem<MaterialBasicInfo>,
      tab: (typeof SpaceResourceDetailsTab)[keyof typeof SpaceResourceDetailsTab]
    ) => {
      setDefaultTab(tab);
      setSelectedResource(resource);
    },
    []
  );

  const getLastThirtyDays = React.useCallback((): Date | undefined => {
    if (!reportEndDate || !space?.created) {
      return undefined;
    }
    const endDate = new Date(reportEndDate);
    const lastThirtyDays = new Date(endDate.setDate(endDate.getDate() - 30));
    if (space?.created && lastThirtyDays < space?.created) {
      return space?.created;
    }
    return lastThirtyDays;
  }, [reportEndDate, space?.created]);

  const contextValue = getSpaceAnalyticsContext(
    space,
    reportEndDate,
    users,
    visibleUsers,
    excludeUserIds,
    isFetchingUsers,
    openSpaceUserModal,
    getLastThirtyDays,
    openSpaceVideoMessageModal,
    openSpaceResourceModal,
    selectedResource,
    isExcludeInternalUser,
    setIsExcludeInternalUser,
    dateRange,
    handleFilterByDateChange
  );

  const renderLoading = () => <Loading />;

  if (isFetchingSpace || isFetchingUsers || !space) {
    return renderLoading();
  }

  return (
    <SpaceAnalyticsContext.Provider value={contextValue}>
      {children}
      {!!selectedUser && (
        <SpaceUserDetailsModal
          open
          defaultTab={defaultTab}
          userInfo={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      )}
      {isVideoMessage && (
        <SpaceVideoMessageModal
          messageId={resourceId}
          title={videoMessageTitle}
          open
          onClose={() => setIsVideoMessage(false)}
        />
      )}
      {!!selectedResource && (
        <ResourceDetailsModal
          open
          defaultTab={defaultTab}
          onClose={() => setSelectedResource(null)}
        />
      )}
    </SpaceAnalyticsContext.Provider>
  );
};

export const useSpaceAnalyticsContext = () => useContext(SpaceAnalyticsContext);
