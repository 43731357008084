/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ResourceState, ResourceType } from '../../../app/appConstants';
// import { canViewIntegrationResource } from '../../../integrations/integrationResourceUtils';
import { getFolderIndicatorType, getResourceIndicatorType } from '../../../commons/ResourceUtils';
import ResourceListItem from '../../ResourceListItem';
import DndResourceListItem from '../../ResourceListItem/DndResourceListItem';
import ResourceGridItem from '../../ResourceGridItem';
import DndResourceGridItem from '../../ResourceGridItem/DndResourceGridItem';
import { ViewModeType } from '../../../resources/resourceConstants';
import { SERVICE_PROVIDER } from '../../../integrations/integrationConstants';

const ResourceItem = (props) => {
  const {
    resource,
    selected,
    newFiles,
    processingResources,
    isMobile,
    subscriptionLock,
    selectedFileId,
    isViewOnly,
    dragObject,
    disabled,
    labelId,
    showCheckbox,
    columns,
    onClickItem,
    onClickCheckbox,
    onClickMoreButton,
  } = props;

  const folderSettings = resource?.externalFolderSettings;

  const resourceName = resource.resourceName || resource.displayName || resource.name;
  const lastModified = resource.lastModified;
  const numberItems = resource.directSubFolderCount;

  const isDisabled =
    disabled ||
    (!!resource.resourceId && [ResourceState.ConversionFailed].includes(resource.state));

  const isProcessed =
    resource.isTranscoded === null || resource.isTranscoded === undefined
      ? true
      : resource.isTranscoded;
  const isConvertingFile = processingResources?.includes(resource.resourceId);

  let isProcessing =
    !!resource.resourceId &&
    (!isProcessed ||
      resource.isConvertingFile ||
      resource.state === ResourceState.Converting ||
      resource.state === ResourceState.Transcoding);

  let shouldConvertToPDF;
  if (props.isWebinar) {
    shouldConvertToPDF = resource.shouldConvertToPDF;
  } else {
    shouldConvertToPDF =
      resource.state !== ResourceState.Converted &&
      resource.type === ResourceType.cad &&
      !resource.externalId;
  }
  const dimmed = shouldConvertToPDF;

  if (isConvertingFile) {
    isProcessing = isConvertingFile;
  }

  const isFolder = resource.isFolder || resource.directoryType !== undefined;
  const isNewFile = !!newFiles?.find((i) => resource.id === i);

  const getSourceIcon = useCallback(() => {
    if (!isFolder) {
      return getResourceIndicatorType(resource);
    } else {
      return getFolderIndicatorType(resource);
    }
  }, [isFolder, resource]);

  const indicator = getSourceIcon();

  const handleOnClick = useCallback(
    (event) => {
      if (isDisabled || isProcessing || subscriptionLock) return;
      event.stopPropagation();
      event.preventDefault();
      if (onClickItem) onClickItem(event, resource);
    },
    [isDisabled, isProcessing, onClickItem, resource, subscriptionLock]
  );

  const handleClickCheckbox = useCallback(
    (event) => {
      if (onClickCheckbox) onClickCheckbox(event.target.checked, resource);
    },
    [onClickCheckbox, resource]
  );

  const handleClickMoreButton = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (onClickMoreButton) onClickMoreButton(event, resource);
    },
    [onClickMoreButton, resource]
  );

  const scrollIntoView = selectedFileId || resource?.id === props.newResourceId;

  const numberFileOfFolder = useCallback(() => {
    let itemsCount = null;
    let renderItem = null;

    // Not show items in Google Drive
    if (folderSettings?.provider === SERVICE_PROVIDER.GOOGLE_DRIVE) return null;

    if (resource.isExternal) {
      renderItem = numberItems <= 1 ? 'item' : 'items';
      itemsCount = numberItems;
    } else {
      itemsCount = (resource.spaceResources?.length || 0) + (resource.subFolders?.length || 0);
      renderItem = itemsCount <= 1 ? 'item' : 'items';
    }

    return (
      <span className="number-file">
        <span className="dot"> &bull;</span>
        {itemsCount !== null && itemsCount !== undefined && `${itemsCount} ${renderItem}`}
      </span>
    );
  }, [
    resource.spaceResources?.length,
    numberItems,
    resource.isExternal,
    resource.subFolders?.length,
    folderSettings?.provider,
  ]);

  console.log('ResourceItem', resource);

  if (props.viewMode === ViewModeType.LIST) {
    if (dragObject) {
      // dragable
      return (
        <DndResourceListItem
          id={resource.id}
          key={resource.id > 0 ? resource.id : resource.externalId}
          dragObject={dragObject}
          resourceId={resource.resourceId || resource.id}
          hasChanges={resource.hasChanges}
          lastModified={lastModified}
          created={resource.created}
          resourceType={resource.resourceType !== undefined ? resource.resourceType : resource.type}
          isExternal={resource.isExternal}
          resourceName={resourceName}
          src={resource.src}
          state={resource.state}
          extension={resource.extension}
          selected={selected}
          isMobile={isMobile}
          subscriptionLock={subscriptionLock}
          isViewOnly={isViewOnly}
          isFolder={isFolder}
          isNewFile={isNewFile}
          isDisabled={isDisabled}
          isProcessing={isProcessing}
          dimmed={dimmed}
          indicator={indicator}
          onClick={handleOnClick}
          onClickCheckbox={handleClickCheckbox}
          onClickMoreButton={handleClickMoreButton}
          showActionMenu={props.showActionMenu}
          disabledMoreButton={props.disabledMoreButton}
          scrollIntoView={scrollIntoView}
          numberFileOfFolder={numberFileOfFolder}
          isConvertingFile={isConvertingFile}
          isPortal={props.isPortal}
          columns={columns}
        />
      );
    }

    return (
      <ResourceListItem
        resourceId={resource.resourceId || resource.id}
        hasChanges={resource.hasChanges}
        lastModified={lastModified}
        created={resource.created}
        resourceType={resource.resourceType !== undefined ? resource.resourceType : resource.type}
        isExternal={resource.isExternal}
        resourceName={resourceName}
        src={resource.src}
        state={resource.state}
        extension={resource.extension}
        selected={selected}
        isMobile={isMobile}
        subscriptionLock={subscriptionLock}
        isViewOnly={isViewOnly}
        isFolder={isFolder}
        isNewFile={isNewFile}
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        dimmed={dimmed}
        indicator={indicator}
        onClick={handleOnClick}
        onClickCheckbox={handleClickCheckbox}
        onClickMoreButton={handleClickMoreButton}
        showActionMenu={props.showActionMenu}
        disabledMoreButton={props.disabledMoreButton}
        scrollIntoView={scrollIntoView}
        numberFileOfFolder={numberFileOfFolder}
        isConvertingFile={isConvertingFile}
        isPortal={props.isPortal}
        columns={columns}
      />
    );
  }

  if (dragObject) {
    return (
      // dragable
      <DndResourceGridItem
        id={resource.id}
        key={resource.id > 0 ? resource.id : resource.externalId}
        dragObject={dragObject}
        resourceId={resource.resourceId || resource.id || resource.externalId}
        hasChanges={resource.hasChanges}
        lastModified={lastModified}
        created={resource.created}
        resourceType={resource.resourceType !== undefined ? resource.resourceType : resource.type}
        isExternal={resource.isExternal}
        resourceName={resourceName}
        thumbnail={resource.thumbnail}
        src={resource.src}
        state={resource.state}
        extension={resource.extension}
        selected={selected}
        isMobile={isMobile}
        subscriptionLock={subscriptionLock}
        isViewOnly={isViewOnly}
        isFolder={isFolder}
        isNewFile={isNewFile}
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        dimmed={dimmed}
        indicator={indicator}
        onClick={handleOnClick}
        onClickCheckbox={handleClickCheckbox}
        onClickMoreButton={handleClickMoreButton}
        showActionMenu={props.showActionMenu}
        disabledMoreButton={props.disabledMoreButton}
        showCheckbox={showCheckbox}
        labelId={labelId}
        scrollIntoView={scrollIntoView}
        numberFileOfFolder={numberFileOfFolder}
        isConvertingFile={isConvertingFile}
        isPortal={props.isPortal}
        provider={resource?.provider}
      />
    );
  }

  return (
    <ResourceGridItem
      // newMarkResourceRef={props.newResourceId === resource.id ? newMarkResourceRef : null}
      resourceId={resource.resourceId || resource.id || resource.externalId}
      hasChanges={resource.hasChanges}
      lastModified={lastModified}
      created={resource.created}
      resourceType={resource.resourceType !== undefined ? resource.resourceType : resource.type}
      isExternal={resource.isExternal}
      resourceName={resourceName}
      thumbnail={resource.thumbnail}
      src={resource.src}
      state={resource.state}
      extension={resource.extension}
      selected={selected}
      isMobile={isMobile}
      subscriptionLock={subscriptionLock}
      isViewOnly={isViewOnly}
      isFolder={isFolder}
      isNewFile={isNewFile}
      isDisabled={isDisabled}
      isProcessing={isProcessing}
      dimmed={dimmed}
      indicator={indicator}
      onClick={handleOnClick}
      onClickCheckbox={handleClickCheckbox}
      onClickMoreButton={handleClickMoreButton}
      showActionMenu={props.showActionMenu}
      disabledMoreButton={props.disabledMoreButton}
      showCheckbox={showCheckbox}
      scrollIntoView={scrollIntoView}
      numberFileOfFolder={numberFileOfFolder}
      isConvertingFile={isConvertingFile}
      isPortal={props.isPortal}
      provider={resource?.provider}
    />
  );
};

ResourceItem.propTypes = {
  viewMode: PropTypes.string,
  resource: PropTypes.instanceOf(Object),
  dragObject: PropTypes.instanceOf(Object),
  newFiles: PropTypes.instanceOf(Array),
  selected: PropTypes.bool,
  onClickItem: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  onClickMoreButton: PropTypes.func,
  showActionMenu: PropTypes.bool,
  disabledMoreButton: PropTypes.bool,
  processingResources: PropTypes.instanceOf(Array),
  newResourceId: PropTypes.number,
  isWebinar: PropTypes.bool,
  isMobile: PropTypes.bool,
  subscriptionLock: PropTypes.bool,
  selectedFileId: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  disabled: PropTypes.bool,
  labelId: PropTypes.string,
  isPortal: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(ResourceItem);
