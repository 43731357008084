import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import i18n from '../../i18n';
import './ScriptPreview.scss';
import { loadScript, parseScript, removeScript, ScriptProvider } from '../cmsScriptUtils';
import { useSpaceContext } from '../../spaces/SpaceContext';

const ScriptPreview = React.memo((props) => {
  const { id, script, isPortal, useStyle } = props;
  const { isSpaceUserLoggedIn } = useSpaceContext();
  const [scriptObject, setScriptObject] = React.useState(null);
  const [loadSalesforceForm, setLoadSalesforceForm] = React.useState(false);
  const isLoading = React.useRef(false);

  React.useEffect(() => {
    const initializeScript = _debounce(async () => {
      console.log('initializeScript');
      if (script) {
        if (isLoading.current) {
          return;
        }
        isLoading.current = true;
        const parsedScript = parseScript(script);
        setScriptObject(parsedScript);
        setLoadSalesforceForm(
          parsedScript !== null && parsedScript.provider === ScriptProvider.Salesforce
        );
        await loadScript(parsedScript, id);
        isLoading.current = false;
      } else {
        setLoadSalesforceForm(false);
        setScriptObject(null);
        removeScript(id);
        isLoading.current = false;
      }
    }, 500);

    if (isSpaceUserLoggedIn) {
      initializeScript();
    }
  }, [script, isSpaceUserLoggedIn, id]);

  React.useEffect(() => {
    return () => {
      setLoadSalesforceForm(false);
      removeScript(id);
    };
  }, [id]);

  function renderPreviewMessage() {
    if (!isPortal || (scriptObject && !scriptObject.isPopup)) {
      return null;
    }

    return (
      <>
        <div className="script-preview-message">
          {i18n.t('A preview of your script will be shown here')}
        </div>
        <span className="script-preview-hint">
          {i18n.t(
            'Please note that a preview can not be shown if the script is a popup or lightbox'
          )}
        </span>
      </>
    );
  }

  function getCustomStyle() {
    let customStyle = {};
    if (!isPortal && (!scriptObject || scriptObject?.isPopup)) {
      customStyle = {
        padding: 0,
      };
    } else {
      customStyle = {
        paddingTop: props.paddingStyle?.paddingTop || '50px',
        paddingBottom: props.paddingStyle?.paddingBottom || '50px',
        paddingLeft: '0px',
        paddingRight: '0px',
      };
    }

    return customStyle;
  }

  return (
    <div className="script-preview" style={getCustomStyle()}>
      {script && <div id={`script-content-${id}`} className="script-content" />}
      {renderPreviewMessage()}
      {loadSalesforceForm && (
        <div
          className={useStyle && 'salesforce-default-style'}
          dangerouslySetInnerHTML={{ __html: scriptObject.form }}
        />
      )}
    </div>
  );
});

ScriptPreview.propTypes = {
  id: PropTypes.string,
  script: PropTypes.string,
  isPortal: PropTypes.bool,
  paddingStyle: PropTypes.instanceOf(Object),
  useStyle: PropTypes.bool,
};

export default ScriptPreview;
