import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryCache, { CacheKeys } from '../app/queryCache';
import { getUrlParameter } from '../commons/utils';
import Loading from '../components/Loading';
import spaceUser from './spaceUser';

const RedirectToNewSpace = ({ urlSlug, clearCache = false }) => {
  const [flag, setFlag] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setFlag(true);
    }, 1000);
  }, []);

  if (!flag) {
    return (
      <div className="app app-runtime">
        <Loading />
      </div>
    );
  }
  if (clearCache) {
    queryCache.removeQueries({
      queryKey: [CacheKeys.getSpaceAccessToken, urlSlug, spaceUser.getInvc()],
      exact: true,
    });
    spaceUser.reset();
  }

  const pathname = `/s/${urlSlug}`;

  let search = '?';
  const c = getUrlParameter('invc', '');
  if (c) {
    search = `${search}&invc=${c}`;
  }
  const materialId = getUrlParameter('srId', '');
  if (materialId) {
    search = `${search}&srId=${materialId}`;
  }
  const sharingFolderId = getUrlParameter('drId', '');
  if (sharingFolderId) {
    search = `${search}&drId=${sharingFolderId}`;
  }
  const cmsBlockId = getUrlParameter('cbId', '');
  if (cmsBlockId) {
    search = `${search}&cbId=${cmsBlockId}`;
  }
  const requestAccessId = getUrlParameter('req-id', '');
  if (requestAccessId) {
    search = `${search}&req-id=${requestAccessId}`;
  }

  const recordingId = getUrlParameter('recordingId', '');
  if (recordingId) {
    search = `${search}&recordingId=${recordingId}`;
  }
  const channelId = getUrlParameter('channelId', '');
  if (channelId) {
    search = `${search}&channelId=${channelId}`;
  }

  const pageId = getUrlParameter('pageId', '');
  if (pageId) {
    search = `${search}&pageId=${pageId}`;
  }

  const error = getUrlParameter('error', '');
  if (error) {
    search = `${search}&error=${error}`;
  }

  const toURL = `${pathname}${search}`;
  return <Navigate to={toURL} state={location.state} replace />;
};

RedirectToNewSpace.propTypes = {
  urlSlug: PropTypes.string,
  clearCache: PropTypes.bool,
};

export default RedirectToNewSpace;
