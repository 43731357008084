import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import i18n from '../i18n';
import './Banner.scss';

const Banner = ({ message, className }) => {
  const [openBanner, setOpenBanner] = useState(true);

  function handleOnClickDismiss() {
    setOpenBanner(false);
  }

  if (!openBanner) {
    return null;
  }

  return (
    <div className={`banner ${className}`}>
      <div className="banner-left">
        <InfoIcon className="info-icon" />
        <p className="description">{message}</p>
      </div>
      <div className="banner-right">
        <Button size="small" className="dismiss-button" onClick={handleOnClickDismiss}>
          {i18n.t('Dismiss')}
        </Button>
      </div>
    </div>
  );
};

Banner.propTypes = {
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
};

Banner.defaultProps = {
  message: '',
  className: 'banner-warning',
};

export default Banner;
