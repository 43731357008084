/* eslint react/jsx-props-no-spreading: 0 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextFieldEnhanced from '../components/TextFieldEnhanced';
import i18n from '../i18n';
import { getUrlParameter, removeURLParams, sendNotification } from '../commons/utils';
import localUserInfo from '../commons/LocalUserInfo';
import { signUpSpaceUser } from './spaceAccessServices';
import spaceUser from './spaceUser';
import RequestAccessDialog from '../components/RequestAccessDialog';
import { TermsAndConditionData } from './TermsAndConditionData';
import { FeatureFlagsType } from '../app/appConstants';
import { FeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import withRouter from '../commons/withRouter';
import OfficialButton from '../components/OfficialButtons';
import './SpaceUserSignup.scss';

class SpaceUserSignup extends PureComponent {
  constructor(props) {
    super(props);
    const userInfo = localUserInfo.getUserInfo();
    this.state = {
      isOpen: true,
      email: userInfo?.email || '',
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
      customerTC: false,
      spceTC: false,
      showError: false,
      errorMessageEmail: '',
    };
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleClickEnterSpace = this.handleClickEnterSpace.bind(this);
    this.handleConfirmCustomerTC = this.handleConfirmCustomerTC.bind(this);
    this.handleConfirmSpceTC = this.handleConfirmSpceTC.bind(this);
    this.error = getUrlParameter('error');
    this.isSettingNotifications = getUrlParameter('type', '') === 'notification';
    this.validateRequiredWorkEmail = this.validateRequiredWorkEmail.bind(this);
    this.emailRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
  }

  componentDidMount() {
    if (this.error === 'invalid_magic_link') {
      setTimeout(() => {
        sendNotification(i18n.t('This link is already used or expired. Please try again.'), {
          type: 'error',
        });
        removeURLParams(['error']);
      }, 200);
    }
  }
  isValid() {
    const isValidEmail = this.emailRef.current.isValid();
    const isValidFirstName = this.firstNameRef.current.isValid();
    const isValidLastName = this.lastNameRef.current.isValid();
    return isValidEmail && isValidFirstName && isValidLastName;
  }

  handleChangeTextField(name, value) {
    this.setState({ [name]: value });
    if (this.state.errorMessageEmail) {
      this.setState({ errorMessageEmail: false });
    }
  }

  handleCancel() {
    this.setState({ isOpen: false });
  }

  handleConfirmCustomerTC(isChecked) {
    this.setState({ customerTC: isChecked, showError: false });
  }

  handleConfirmSpceTC(isChecked) {
    this.setState({ spceTC: isChecked, showError: false });
  }

  goToSpace(invitation) {
    let url = `/s/${this.props.urlSlug}?invc=${invitation.code}`;
    const isViewOnly = spaceUser.isViewOnly();
    if (isViewOnly) {
      url += `&viewOnly=1`;
    }

    const materialId = getUrlParameter('srId', '');
    if (materialId) {
      url += `&srId=${materialId}`;
    }
    const sharingFolderId = getUrlParameter('drId', '');
    if (sharingFolderId) {
      url += `&drId=${sharingFolderId}`;
    }
    if (this.isSettingNotifications) {
      url += `&type=notification`;
    }
    const cmsBlockId = getUrlParameter('cbId', '');
    if (cmsBlockId) {
      url += `&cbId=${cmsBlockId}`;
    }
    const todoId = getUrlParameter('todoId', '');
    if (todoId) {
      url += `&todoId=${todoId}`;
    }
    const recordingId = getUrlParameter('recordingId', '');
    if (recordingId) {
      url += `&recordingId=${recordingId}`;
    }
    const sharedCode = getUrlParameter('c', '');
    if (sharedCode) {
      url += `&c=${sharedCode}`;
    }
    const pageId = getUrlParameter('pageId', '');
    if (pageId) {
      url += `&pageId=${pageId}`;
    }
    this.props.navigate(url);
  }

  async handleClickEnterSpace(event) {
    event.preventDefault();
    if (!this.isValid()) {
      return;
    }

    const [enabledTC] = this.context.checkEnabledFeatures([FeatureFlagsType.TC]);

    if (this.props.space?.termsAndConditions?.src) {
      if ((!this.state.customerTC && enabledTC) || !this.state.spceTC) {
        this.setState({ showError: true });
        return;
      }
    } else if (!this.state.spceTC) {
      this.setState({ showError: true });
      return;
    }
    this.setState({ isSavingData: true });

    const { email, firstName, lastName } = this.state;
    const data = {
      email: email.trim(),
      firstName,
      lastName,
    };

    try {
      const { id } = this.props.space;

      localUserInfo.setUserInfo(data);

      const resp = await signUpSpaceUser(id, data, spaceUser.isViewOnly());

      const { invitation } = resp;

      this.goToSpace(invitation);
    } catch (e) {
      console.log('ERROR', e);
      this.setState({ isSavingData: false });

      if (e.error.type === 'SpaceAccessException') {
        this.setState({ showRequestDialog: true });
        await RequestAccessDialog.show(
          'NO ACCESS',
          'This is a private space, the host has to grant you access. ',
          data,
          this.props.space.id
        );
        this.setState({ showRequestDialog: false });
      } else if (e.error.type === 'ValidationException') {
        const errorMessageEmail = e.error.errors.find((error) => error.field === 'Payload.Email');
        this.setState({ errorMessageEmail: errorMessageEmail.error });
        this.emailRef.current.isValid();
      } else {
        sendNotification(i18n.t('Sorry, something went wrong. Please try again!'), {
          type: 'error',
        });
      }
    }
  }
  validateRequiredWorkEmail() {
    return this.state.errorMessageEmail;
  }

  render() {
    const { isSavingData, isOpen, email, firstName, lastName, showRequestDialog } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <div className={`white join-space-dialog${showRequestDialog ? ' hidden' : ''}`}>
        <form onSubmit={this.handleClickEnterSpace}>
          <div className="mobile-join-space-box">
            <div className="content">
              <div className="message">
                <div className="title" title={this.props.space.name}>
                  {i18n.t(`Welcome to ${this.props.space.name}`)}
                </div>
                <span className="sub-title">
                  {i18n.t('Please provide your name and email address to join.')}
                </span>
              </div>
              <div className="form fullwidth">
                <TextFieldEnhanced
                  ref={this.emailRef}
                  type="email"
                  autoFocus
                  required
                  maxLength={200}
                  name="email"
                  defaultValue={email}
                  onChange={this.handleChangeTextField}
                  validate={this.validateRequiredWorkEmail}
                  placeholder={`${i18n.t('Email')} *`}
                />
              </div>
              <div className="form">
                <TextFieldEnhanced
                  ref={this.firstNameRef}
                  required
                  maxLength={100}
                  name="firstName"
                  defaultValue={firstName}
                  onChange={this.handleChangeTextField}
                  placeholder={`${i18n.t('First name')} *`}
                />
                <TextFieldEnhanced
                  ref={this.lastNameRef}
                  required
                  maxLength={100}
                  name="lastName"
                  defaultValue={lastName}
                  onChange={this.handleChangeTextField}
                  placeholder={`${i18n.t('Last name')} *`}
                />
              </div>
              <div className="terms-and-condition">
                <TermsAndConditionData
                  termsAndConditions={this.props.space?.termsAndConditions}
                  organizationName={this.props.space?.organizationName}
                  handleConfirmCustomerTC={this.handleConfirmCustomerTC}
                  handleConfirmSpceTC={this.handleConfirmSpceTC}
                  showError={this.state.showError}
                />
              </div>

              <div className="button-wrapper">
                <OfficialButton
                  label={i18n.t('Enter space')}
                  disabled={isSavingData}
                  isProcessing={isSavingData}
                  type="submit"
                  fullWidth
                  variant="large-primary"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SpaceUserSignup.propTypes = {
  space: PropTypes.instanceOf(Object),
  urlSlug: PropTypes.string,
  navigate: PropTypes.func,
};

SpaceUserSignup.contextType = FeatureFlagsContext;

export default withRouter(SpaceUserSignup);
