import React, { useEffect, useState, useRef } from 'react';
import memoizeOne from 'memoize-one';
import { useQuery } from '@tanstack/react-query';

import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import { Cropper } from 'react-cropper';
import { CacheKeys } from '../../app/queryCache';
import ResponsiveDialog from '../ResponsiveDialog';
import { getResourcesForViewer } from '../../resources/ResourceServices';
import i18n from '../../i18n';
import './CropperImage.scss';
import 'cropperjs/dist/cropper.css';
import Loading from '../Loading';
import { uploadSpaceMaterial } from '../../commons/CommonServices';
import { getExtensionFromFileName } from '../../commons/ResourceUtils';
import LightTooltip from '../LightTooltip';
import ColorPicker from '../../cms/commons/ColorPicker';
import ImageSettings from './ImageSettings';
import OfficialButton from '../OfficialButtons';
import { ResourceType } from '../../app/appConstants';
import { isIntegrationResourceDeleted } from '../../integrations/integrationResourceUtils';
// import { sleep } from '../../commons/utils';

const getRatio = (value) => {
  if (!value) {
    return 16 / 9;
  }
  const numbers = value.split(':');
  return numbers[0] / numbers[1];
};

const getZoomDefault = memoizeOne((data, canvasData, isReset = true) => {
  const { width, height } = data;
  const { naturalWidth, naturalHeight } = canvasData;
  let zoom;
  if (isReset) {
    zoom = naturalWidth <= naturalHeight ? height / naturalHeight : width / naturalWidth;
  } else {
    zoom =
      naturalWidth <= naturalHeight
        ? canvasData.height / naturalHeight
        : canvasData.width / naturalWidth;
  }

  return zoom;
});

const CropperImage = (props) => {
  const {
    resourceId,
    isPortal,
    ratio,
    onChange,
    disabledCropper,
    helpTextByType,
    setSavedColors,
    defaultBackgroundColor,
    savedColors,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [imageInfo, setImageInfo] = useState();
  const [zoom, setZoom] = useState(0);
  const [cropper, setCropper] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [fileType, setFileType] = useState();

  const [backgroundColor, setBackgroundColor] = useState(defaultBackgroundColor || '#ffffff');

  const initialRatio = getRatio(ratio);

  const cropperRef = useRef();

  const getResourceQuery = useQuery({
    queryKey: [CacheKeys.getResourceForViewer, props.spaceId, resourceId, isPortal],
    queryFn: async () => {
      const resp = await getResourcesForViewer([resourceId], isPortal);
      if (resp?.length > 0) {
        // console.log('### getResourcesForViewer', resp);
        return resp[0];
      }
      return null;
    },
    retry: 1,
    retryDelay: () => 3000,
    enabled: resourceId > 0,
  });

  useEffect(() => {
    const data = getResourceQuery.data;
    if (data) {
      data.name = data.resourceName;
      data.type = data.resourceType;
      setFileType(getExtensionFromFileName(data.name));
      const isDeleted = isIntegrationResourceDeleted(data.url);
      if (!isDeleted && data.url) {
        setImageInfo(data);
      }
    }
  }, [getResourceQuery.data]);

  function handleCancel() {
    setIsReady(false);
    setIsOpen(false);
    setBackgroundColor(backgroundColor);
    cropperRef.current.cropper.reset();
  }

  function handleOpen() {
    if (disabledCropper?.includes(fileType)) {
      return;
    }
    setIsOpen(true);
  }

  async function handleUploadFile(file) {
    if (!file) return;
    const { spaceId } = props;
    const newFile = file;

    newFile.resourceType = imageInfo.type;
    newFile.fileName = imageInfo.name;
    newFile.fileDescription = '';

    const uploader = await uploadSpaceMaterial(spaceId, newFile);

    uploader.on('fileSuccess', (f, message) => {
      const messageObj = JSON.parse(message);
      messageObj.sessionId = uploader.opts.sessionId;
      messageObj.name = messageObj.fileName || file.name;
      messageObj.isCropped = true;
      messageObj.cropperData = {
        cropBoxData: cropperRef.current.cropper.getCropBoxData(),
        canvasData: cropperRef.current.cropper.getCanvasData(),
        canvasBackground: backgroundColor,
      };

      if (backgroundColor !== '#ffffff') {
        messageObj.backgroundImageCropped = backgroundColor;
      }
      if (onChange) onChange(messageObj);

      setTimeout(() => {
        // delete old resource
        // deleteResource(imageInfo.resourceId); // should not delete the physical storage here because some spaces are sharing that resource.

        setIsProcessing(false);
        handleCancel();
      }, 500);
    });

    uploader.on('fileError', (error) => {
      console.log('### error.message: ', error);
    });

    // uploader.on('complete', () => {});
  }

  const getCropData = () => {
    if (!imageInfo) return;
    if (cropper) {
      setIsProcessing(true);
      const canvas = cropper.getCroppedCanvas({ fillColor: backgroundColor });
      setSavedColors([...savedColors, backgroundColor]);
      const extension = getExtensionFromFileName(imageInfo.name);
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
      let blobType = '';
      switch (extension) {
        case 'jpg':
        case 'jpeg':
          blobType = 'image/jpeg';
          break;
        case 'webp':
          blobType = 'image/webp';
          break;
        default:
          blobType = 'image/png';
          break;
      }
      canvas.toBlob(async (blob) => {
        const typeFile = `image/${extension}`;
        const file = new File([blob], imageInfo.name, { type: typeFile });
        await handleUploadFile(file);
      }, blobType);
    }
  };

  function handleZoomChange(event, newValue) {
    if (newValue <= 0.1) {
      setZoom(0.1);
    } else if (newValue >= 5) {
      setZoom(5);
    } else {
      setZoom(newValue);
    }
  }

  const handleZoom = (e) => {
    const value = Number(Number.parseFloat(e.detail.ratio).toFixed(2));
    if (value <= 0.1) {
      setZoom(0.1);
      e.preventDefault();
    } else if (value >= 5) {
      setZoom(5);
      e.preventDefault();
    } else {
      setZoom(e.detail.ratio);
    }
  };

  function handleBackgroundColorChange(value) {
    setBackgroundColor(value);
    document.getElementsByClassName('cropper-view-box')[0].style.backgroundColor = value;
  }

  /**
   * Return data of CanvasData fixed with CropBox
   * @returns CanvasData
   */
  function getCanvasFixWithOriginalSize() {
    const cropperElement = cropperRef?.current?.cropper;
    const containerData = cropperElement.getContainerData();
    const { naturalHeight, naturalWidth } = cropperElement.getImageData();
    const cropBoxData = {
      width: initialRatio * 350,
      height: 350,
    };
    let canvasData = {};

    if (naturalWidth > naturalHeight) {
      canvasData = {
        width: cropBoxData.width,
        height: (cropBoxData.width / naturalWidth) * naturalHeight,
      };
    } else {
      canvasData = {
        height: cropBoxData.height,
        width: (cropBoxData.height / naturalHeight) * naturalWidth,
      };
    }

    canvasData.left = (containerData.width - canvasData.width) / 2;
    canvasData.top = (containerData.height - canvasData.height) / 2;

    return canvasData;
  }

  /**
   * Return data of CropBox fixed with container base on ratio
   * @returns CropBoxData
   */
  function getCropperFixWithOriginalSize() {
    const cropperElement = cropperRef?.current?.cropper;
    const containerData = cropperElement.getContainerData();

    const cropBoxData = {
      width: initialRatio * 350,
      height: 350,
    };
    let maxCropBoxWidth = containerData.width;
    let maxCropBoxHeight = containerData.height;

    if (initialRatio) {
      if (maxCropBoxHeight * initialRatio > maxCropBoxWidth) {
        maxCropBoxHeight = maxCropBoxWidth / initialRatio;
      } else {
        maxCropBoxWidth = maxCropBoxHeight * initialRatio;
      }
    }
    cropBoxData.width = Math.max(cropBoxData.width, maxCropBoxWidth);
    cropBoxData.height = Math.max(cropBoxData.height, maxCropBoxHeight);
    cropBoxData.left = (containerData.width - cropBoxData.width) / 2;
    cropBoxData.top = (containerData.height - cropBoxData.height) / 2;

    return cropBoxData;
  }

  /**
   * Handle reset CopperBox with size of canvas to Original Image
   */
  function handleOnChangeToOriginalSize() {
    const cropperEl = cropperRef.current.cropper;

    cropperEl.reset();

    cropperEl.setCropBoxData(getCropperFixWithOriginalSize());
    cropperEl.setCanvasData(getCanvasFixWithOriginalSize());

    const defaultZoom = getZoomDefault(cropperEl.getCropBoxData(), cropperEl.getCanvasData());

    setZoom(defaultZoom);
  }

  function readyCropper() {
    // await sleep(10);
    console.log('### Cropper readyCropper');
    setIsReady(true);
    // Cropper apply background color
    if (document.getElementsByClassName('cropper-view-box')[0]) {
      document.getElementsByClassName('cropper-view-box')[0].style.backgroundColor =
        backgroundColor;
    }

    const cropperEl = cropperRef.current.cropper;

    if (props.cropperData) {
      // Cropper has saved coppered data
      cropperEl.reset();

      const { cropBoxData, canvasData } = props.cropperData;
      const defaultZoom = getZoomDefault(cropBoxData, canvasData, false);

      setZoom(defaultZoom);
      cropperEl.setCropBoxData(cropBoxData);
      cropperEl.setCanvasData(canvasData);
    } else {
      handleOnChangeToOriginalSize();
    }
  }

  const renderCropper = () => {
    if (getResourceQuery.isLoading) {
      return (
        <div className="loading-box">
          <Loading />
        </div>
      );
    }

    return (
      <Cropper
        ref={cropperRef}
        zoomTo={zoom}
        zoom={handleZoom}
        style={{ height: 350, width: '100%', maxWidth: '100%' }}
        initialAspectRatio={initialRatio}
        aspectRatio={initialRatio}
        dragMode="move"
        src={imageInfo?.url}
        viewMode={0}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        autoCropArea={1}
        responsive
        guides
        checkOrientation={false}
        onInitialized={(instance) => {
          // console.log('### Cropper onInitialized', instance);
          setCropper(instance);
        }}
        ready={readyCropper}
        className="app-cropper"
      />
    );
  };

  function renderSlide() {
    if (!cropperRef?.current) {
      return null;
    }
    return (
      <div className="slider-zoom">
        <InsertPhotoIcon fontSize="small" className="min-image" />
        <Slider
          className="slider-zoom-input"
          value={zoom}
          max={5}
          min={0.1}
          step={0.01}
          onChange={handleZoomChange}
        />
        <InsertPhotoIcon className="max-image" />
      </div>
    );
  }

  return (
    <>
      {imageInfo && imageInfo.resourceType === ResourceType.image && (
        <LightTooltip title={disabledCropper?.includes(fileType) ? helpTextByType[fileType] : ''}>
          <OfficialButton
            label={i18n.t('Crop')}
            onClick={handleOpen}
            variant="rectangle-primary"
            className="cropper-image-button"
          />
        </LightTooltip>
      )}

      {isOpen && (
        <ResponsiveDialog
          maxWidth="md"
          className="white cropper-image-popup"
          onClose={handleCancel}
          title={i18n.t('Crop your image')}
        >
          <div className="content">
            <Box display="flex" justifyContent="space-between" className="setting-image">
              <ImageSettings onClick={handleOnChangeToOriginalSize} disabled={!isReady} />
              <ColorPicker
                label="Background color"
                defaultColor={backgroundColor}
                savedColors={savedColors}
                onChange={handleBackgroundColorChange}
              />
            </Box>
            <section className="cropper-wrapper">
              {renderCropper()}
              {isReady && (
                <>
                  {renderSlide()}
                  <div className="group-buttons">
                    <OfficialButton
                      onClick={handleCancel}
                      label="Cancel"
                      variant="regular-secondary"
                    />
                    <OfficialButton
                      onClick={getCropData}
                      label="Save"
                      variant="regular-primary"
                      isProcessing={isProcessing}
                    />
                  </div>
                </>
              )}
              {!isReady && (
                <Box display="flex" flexDirection="column" gap={20} className="backdrop">
                  <span className="icon-spinner"></span>
                  <span>Loading the image might take a moment...</span>
                </Box>
              )}
            </section>
          </div>
        </ResponsiveDialog>
      )}
    </>
  );
};

CropperImage.propTypes = {
  spaceId: PropTypes.string,
  resourceId: PropTypes.number,
  isPortal: PropTypes.bool,
  ratio: PropTypes.string,
  onChange: PropTypes.func,
  disabledCropper: PropTypes.instanceOf(Array),
  helpTextByType: PropTypes.instanceOf(Object),
  cropperData: PropTypes.instanceOf(Object),
  savedColors: PropTypes.instanceOf(Array),
  setSavedColors: PropTypes.func,
  defaultBackgroundColor: PropTypes.string,
};

CropperImage.defaultProps = {
  ratio: '16:9',
  disabledCropper: [],
  helpTextByType: {},
};

export default CropperImage;
