/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import memoizeOne from 'memoize-one';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography, Box } from '@mui/material';
import _cloneDeep from 'lodash/cloneDeep';
import EditingCmsComponentPanel from './EditingCmsComponentPanel';
import ResizableDrawer from '../../../components/ResizableDrawer/ResizableDrawer';

import TextImage from '../../../images/cms/text.svg';
import ImageTextImage from '../../../images/cms/text-img.svg';
import OverlayImageImage from '../../../images/cms/overlay-img.svg';
import HeroImage from '../../../images/cms/hero.svg';
import VideoImage from '../../../images/cms/video.svg';
import PagesImage from '../../../images/cms/pages.svg';
import SliderImage from '../../../images/cms/slider.svg';
import WidGetImage from '../../../images/cms/widget.svg';
import WebContentImage from '../../../images/cms/web-content.svg';
import HtmlContentImage from '../../../images/cms/html-content.svg';
import Script from '../../../images/cms/script.svg';

import '../../cmsStyle.scss';
import './SidePanel.scss';
import i18n from '../../../i18n';
import { CMS_COMPONENT_TYPE, CMS_COMPONENT_TYPE_NAME } from '../../cmsConstants';
import {
  getKeyByValue,
  generateComponentByType,
  // findComponentByType,
  mapPagesBlockToBlock,
} from '../../cmsUtils';
import ItemSidePanel from './ItemSidePanel';
import { useThemeSettingsContext } from '../context/ThemeSettingsContextProvider';
import { isJSON, newGuid, sendNotification } from '../../../commons/utils';
import { useSpaceContext } from '../../../spaces/SpaceContext';
import { useFeatureFlagsContext } from '../../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../../../app/appConstants';
import { getDefaultBlockSizeByComponentType } from './functions';
import { WIDGET_FEATURES, WIDGET_NAME } from '../../cmsConstantsForWidget';
import { addPagesBlock } from '../../cmsServices';

const getAvailableComponents = memoizeOne(
  (isEnabledHTMLCms, isEnabledScriptCms, isEnabledCMSPages) => {
    const availableItems = [
      {
        id: 1,
        icon: 'icon-pages',
        name: i18n.t('Pages'),
        type: CMS_COMPONENT_TYPE.PAGES,
        popupContent: {
          image: PagesImage,
          name: i18n.t('Pages'),
          description: i18n.t('Add up to five pages to your space.'),
        },
        disabled: !isEnabledCMSPages,
      },
      {
        id: 2,
        icon: 'icon-text',
        name: i18n.t('Text'),
        type: CMS_COMPONENT_TYPE.CARD_TEXT,
        popupContent: {
          image: TextImage,
          name: i18n.t('Text'),
          description: i18n.t(
            'Add up to four columns of text. Use them to divide your space into sections or add information.'
          ),
        },
      },
      {
        id: 3,
        icon: 'icon-image-text',
        name: i18n.t('Image and text under'),
        type: CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT,
        popupContent: {
          name: i18n.t('Image and text under'),
          image: ImageTextImage,
          description: i18n.t(
            'Add up to four columns of images with text underneath. Suitable for presenting product features, pricing tiers or team members.'
          ),
        },
      },
      {
        id: 4,
        icon: 'icon-image-text-overlay',
        name: i18n.t('Image and text overlay'),
        type: CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY,
        popupContent: {
          name: i18n.t('Image and text overlay'),
          image: OverlayImageImage,
          description: i18n.t(
            'An image with text on it. Suitable for introducing your product or company, or as an eye-catching promotion spot.'
          ),
        },
      },
      {
        id: 5,
        icon: 'icon-hero',
        name: i18n.t('Image beside text'),
        type: CMS_COMPONENT_TYPE.CARD_HERO,
        popupContent: {
          image: HeroImage,
          name: i18n.t('Image beside text'),
          description: i18n.t(
            'Pair an image with text and display them side-by-side. Suitable for showcasing your product with an explanatory text.'
          ),
        },
      },
      {
        id: 6,
        icon: 'icon-carousel',
        name: i18n.t('Slideshow'),
        type: CMS_COMPONENT_TYPE.CAROUSEL,
        popupContent: {
          image: SliderImage,
          name: i18n.t('Slideshow'),
          description: i18n.t(
            'Display a slideshow of large images. Suitable for showing your product or brand as a story line.'
          ),
        },
      },
      {
        id: 7,
        icon: 'icon-video',
        name: i18n.t('Video'),
        type: CMS_COMPONENT_TYPE.VIDEO,
        popupContent: {
          image: VideoImage,
          name: i18n.t('Video'),
          description: i18n.t(
            'Upload a video or add a Youtube or Vimeo link. Suitable for showing videos directly in the space.'
          ),
        },
      },
      {
        id: 8,
        icon: 'icon-website',
        name: i18n.t('Website'),
        type: CMS_COMPONENT_TYPE.WEB_CONTENT,
        popupContent: {
          image: WebContentImage,
          name: i18n.t('Website'),
          description: i18n.t(
            'Add a link to a website to show it in the space. Suitable for highlighting a specific webpage.'
          ),
        },
      },
      {
        id: 9,
        icon: 'icon-widget',
        name: i18n.t('Resource Widget'),
        type: CMS_COMPONENT_TYPE.WIDGET,
        popupContent: {
          image: WidGetImage,
          name: i18n.t('Resource Widget'),
          description: i18n.t('Highlight resources from the resource library in this block.'),
        },
      },
      {
        id: 10,
        icon: 'icon-js-script',
        name: i18n.t('Script'),
        type: CMS_COMPONENT_TYPE.SCRIPT,
        popupContent: {
          image: Script,
          name: i18n.t('Script'),
          description: i18n.t('Add a custom HubSpot, Marketo or Salesforce script to your space.'),
        },
        disabled: !isEnabledScriptCms,
      },
      {
        id: 11,
        icon: 'icon-web-link',
        name: i18n.t('HTML / CSS'),
        type: CMS_COMPONENT_TYPE.HTML_CONTENT,
        popupContent: {
          image: HtmlContentImage,
          name: i18n.t('HTML / CSS'),
          description: i18n.t(
            'Add a customized block built with HTML and CSS. Suitable for navigations and menus.'
          ),
        },
        disabled: !isEnabledHTMLCms,
      },
    ];
    return availableItems;
  }
);

function canAddNewComponent(blocks, pagesBlock, type) {
  let message = '';
  if (type === CMS_COMPONENT_TYPE.PAGES && !!pagesBlock) {
    message = i18n.t('There is currently support for one pages block per space.');
  }
  // else if (type === CMS_COMPONENT_TYPE.SCRIPT) {
  //   let allBlocks = blocks;
  //   if (pagesBlock) {
  //     // join all blocks in pagesBlock
  //     allBlocks = pagesBlock.items?.[0]?.pages.reduce((acc, item) => {
  //       return acc.concat(item.blocks);
  //     }, []);
  //   }
  //   const foundComponent = findComponentByType(allBlocks, type);
  //   if (foundComponent) {
  //     message = i18n.t('You can add only one script per space');
  //   }
  // }

  return message;
}

function SidePanel(props) {
  const { open, spaceId, siblingBlock, setSiblingBlock } = props;
  const {
    selectedBlock,
    setSelectedBlock,
    blocks,
    setCurrentPage,
    openUnsplashPreview,
    handleScrollingToBlock,
    validateCopiedData,
    fetchSpaceInvitationQuery,
    saveSpaceCMSDesign,
    currentPageRef,
  } = useThemeSettingsContext();

  const { getFeatureSpace, pagesBlock } = useSpaceContext();
  const spaceFeatures = getFeatureSpace(true);

  const [drawerWidth, setDrawerWidth] = useState(293);
  const [header, setHeader] = useState(null);
  const [footer, setFooter] = useState(null);
  // const classes = useStyles(drawerWidth)();

  const isEditing = !!selectedBlock;
  const { checkEnabledFeatures } = useFeatureFlagsContext();

  async function handleAddComponent(type) {
    const message = canAddNewComponent(blocks, pagesBlock, type);
    if (message) {
      sendNotification(message, {
        type: 'error',
      });
      return;
    }

    let newBlock = null;
    if (type === CMS_COMPONENT_TYPE.PAGES) {
      // handleAddPagesNavigation();
      await saveSpaceCMSDesign();

      const newPagesBlock = await addPagesBlock(spaceId);
      fetchSpaceInvitationQuery.updateSpace({ pagesBlock: newPagesBlock });
      newBlock = mapPagesBlockToBlock(newPagesBlock);
    } else {
      let generatedComponent = generateComponentByType(type);
      if (type === CMS_COMPONENT_TYPE.WIDGET && !spaceFeatures?.Resources) {
        // Get the first feature space;
        const firstFeature = Object.keys(spaceFeatures)?.[0];
        if (firstFeature) {
          generatedComponent = {
            ...generatedComponent,
            widgetName: WIDGET_NAME[WIDGET_FEATURES[firstFeature]],
            settings: {
              subType: WIDGET_FEATURES[firstFeature],
            },
          };
        }
      }
      const blockId = newGuid();
      newBlock = {
        id: blockId,
        columns: 1,
        items: [generatedComponent],
      };

      const blockSize = getDefaultBlockSizeByComponentType(type);
      newBlock = {
        id: blockId,
        columns: 1,
        size: blockSize,
        items: [generatedComponent],
      };
      const newBlocks = _cloneDeep(blocks);
      if (siblingBlock) {
        const index = blocks.findIndex((r) => r.id === siblingBlock.id);
        newBlocks.splice(index + 1, 0, newBlock);
      } else {
        newBlocks.push(newBlock); // push at the end
      }
      console.log('### 501 currentPageRef: ', currentPageRef);
      setCurrentPage((prevState) => {
        if (!prevState || prevState.id !== currentPageRef.current.id) {
          return prevState;
        }
        return {
          ...prevState,
          blocks: newBlocks,
        };
      });
    }

    setSelectedBlock(newBlock);
    setSiblingBlock(null);
    handleScrollingToBlock(newBlock);
  }

  function renderHeader() {
    if (header) return header;
    const type = selectedBlock?.items?.[0]?.type;
    const key = getKeyByValue(CMS_COMPONENT_TYPE, type);
    const title = isEditing ? CMS_COMPONENT_TYPE_NAME[key] : i18n.t('Select a block');
    return (
      <Box className="designer-side-panel-header">
        <Typography className="title">{title}</Typography>
        <IconButton className="close-btn" onClick={props.onClose}>
          <CloseIcon className="close" />
        </IconButton>
      </Box>
    );
  }

  function renderContent() {
    if (isEditing) {
      return (
        <div className="designer-side-panel-content styled-scrollbar">
          <EditingCmsComponentPanel
            key={selectedBlock?.id}
            setHeader={setHeader}
            setFooter={setFooter}
            spaceId={spaceId}
            onClose={props.onClose}
            isAdmin={props.isAdmin}
          />
        </div>
      );
    }
    const [isEnabledScriptCms, isEnabledCMSPages, isEnabledHTMLCms] = checkEnabledFeatures([
      FeatureFlagsType.SCRIPT,
      FeatureFlagsType.CMS_PAGES,
      FeatureFlagsType.HTML_CSS,
    ]);

    const availableComponents = getAvailableComponents(
      isEnabledHTMLCms,
      isEnabledScriptCms,
      isEnabledCMSPages
    );
    const listComponentsToAdjustHeight = { webContent: 260, widget: 280, script: 250 };
    return (
      <div className="designer-side-panel-content styled-scrollbar">
        {availableComponents.map((item) => (
          <ItemSidePanel
            key={item.id}
            {...item}
            onClick={handleAddComponent}
            minHeight={listComponentsToAdjustHeight[item.type]}
          />
        ))}
      </div>
    );
  }

  function renderFooter() {
    if (footer && !openUnsplashPreview) return footer;
    return <div className="designer-side-panel-footer"></div>;
  }

  function stopEvent(e) {
    e.stopPropagation();
  }

  function handleOnCopy(e) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Selection/type
    const selection = document.getSelection();
    // console.log('### 504 data: ', selection?.type, selection?.rangeCount);
    if (selection?.type === 'Range') {
      e.stopPropagation();
    }
  }

  function handleOnPaste(e) {
    const copiedData = (e.clipboardData || window.clipboardData)?.getData('text');
    const isValidJson = isJSON(copiedData);
    // console.log('### 505 copiedData: ', copiedData, isValidJson);
    if (!isValidJson) {
      e.stopPropagation();
      return;
    }
    const data = JSON.parse(copiedData);
    const isValidBlock = validateCopiedData(data);
    // console.log('### 505 data: ', data, isValidBlock);
    if (!isValidBlock) {
      e.stopPropagation();
    }
  }

  return (
    <ResizableDrawer
      variant="persistent"
      open={open}
      setDrawerWidth={setDrawerWidth}
      className={clsx('designer-sidepanel', open ? 'open' : 'close')}
      classes={{
        paper: 'designer-sidepanel-paper',
        root: 'designer-sidepanel-root',
      }}
      drawerWidth={drawerWidth}
      paperProps={{
        style: {
          width: drawerWidth,
        },
      }}
    >
      <div
        className="designer-side-panel"
        onKeyDown={stopEvent}
        onCopy={handleOnCopy}
        onPaste={handleOnPaste}
      >
        {renderHeader()}
        {renderContent()}
        {renderFooter()}
      </div>
    </ResizableDrawer>
  );
}

SidePanel.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  spaceId: PropTypes.string,
  siblingBlock: PropTypes.instanceOf(Object),
  setSiblingBlock: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default SidePanel;
