import memoizeOne from 'memoize-one';
import moment from 'moment';
import _find from 'lodash/find';
import { newDate, parseDateTimeStringFromServer } from '../commons/DateTimeUtils';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    w: 'a week',
    ww: '%dw',
    M: 'a month',
    MM: '%dm',
    y: 'a year',
    yy: '%dy',
  },
});

moment.updateLocale('spce-en-detail', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export function getPercent(value, total) {
  return Math.round((value / total) * 100);
}

export function getMonthYear(value) {
  if (!value) {
    const date = newDate();
    return { month: date.month() + 1, year: date.year() };
  }
  const date = parseDateTimeStringFromServer(value);

  return {
    month: date.month() + 1,
    year: date.year(),
  };
}

export function getDuration(value) {
  if (!value) {
    return '--';
  }
  const formatted = moment.utc(value * 1000).format('HH:mm:ss');
  return formatted;
}

export function getStringTime(value, skipSeconds = false) {
  if (!value) {
    return 'N/A';
  }

  const duration = moment.duration(value, 'seconds');

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let stringTime = `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''}`;

  if (!skipSeconds) {
    stringTime = `${stringTime} ${seconds ? `${seconds}s` : ''}`;
  }

  if (stringTime.trim().length === 0) {
    stringTime = `${Math.round(value * 100) / 100}`;
    if (hours) {
      stringTime = `${stringTime}h`;
    } else if (minutes) {
      stringTime = `${stringTime}m`;
    } else if (seconds) {
      stringTime = `${stringTime}s`;
    }
  }
  return stringTime;
}

export function renderValueSummary(label, value, skipSeconds = false) {
  if (
    label.toLowerCase().includes('duration') ||
    label.toLowerCase().includes('time') ||
    label.toLowerCase().includes('minutes')
  ) {
    return getStringTime(value, skipSeconds);
  } else {
    return Math.round(value * 100) / 100;
  }
}

export const checkDataReady = (data) => {
  if (!data) {
    return false;
  }
  let isData = false;
  data?.forEach((item) => {
    if (item.value !== 0) {
      isData = true;
    }
  });
  return isData;
};

export const getTimeFromNow = (date) => {
  const value = parseDateTimeStringFromServer(date).local();
  return value.fromNow();
};

const labelsForPublicSpace = {
  participants: 'Unique visitors',
  visits: 'Total visits',
  visitDuration: 'Total visit duration',
  sessionsPerUserAvg: 'Average visit duration',
};

export const getSummaryVisits = memoizeOne((summary) => {
  if (!summary?.length) {
    return [];
  }
  const newSummary = [];
  summary.forEach((item) => {
    const id = item.id;
    if (labelsForPublicSpace[id])
      newSummary.push({
        ...item,
        label: labelsForPublicSpace[id],
      });
  });
  return newSummary;
});

const labelsResource = [
  {
    id: '3',
    label: 'Total view count',
  },
  {
    id: '4',
    label: 'Total view time',
  },
  {
    id: '9',
    label: 'Total downloads',
    break: true,
  },
  {
    id: '11',
    label: 'Average view count per visit',
    message: '= Total clicks on all resources / Total visits',
  },
  {
    id: '6',
    label: 'Average view time',
    message: '= Total view time for all resources / Total resources that have been opened',
  },
  {
    id: '12',
    label: 'Average view time per visit',
    message: '= Total view time for all resources / Total visits',
  },
  {
    id: '13',
    label: 'Average downloads per visit',
    message: '= Total downloads of all resources / Total visits',
  },
];

export const getSummaryResource = memoizeOne((summary, isPublicSpace) => {
  if (!summary?.length) {
    return [];
  }
  if (!isPublicSpace) {
    const newSummary = summary.slice(0, 10);
    return newSummary;
  }
  const newSummary = [];
  let foundSummary = null;
  labelsResource.forEach((labelResourceItem) => {
    foundSummary = _find(summary, (summaryItem) => summaryItem.id === labelResourceItem.id);
    if (foundSummary) {
      newSummary.push({
        ...foundSummary,
        ...labelResourceItem,
      });
    }
  });

  return newSummary;
});
