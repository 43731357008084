import React from 'react';
import memoizeOne from 'memoize-one';
import { SpaceUserRoleNames, PrivacySpace } from '../app/appConstants';
import { SubscriptionType } from '../commons/Subscription';

export const getSpaceContext = memoizeOne(
  (
    isLoading,
    space,
    signalRConnection,
    theme,
    sidebar,
    spaceUserSession,
    code,
    isMobile,
    isMobilePortrait,
    getSpaceAccessTokenQuery,
    newNotifications,
    showVaamRecorder,
    activeChannel,
    vaamTargetChannel,
    isSendingVaam,
    isVaamFromChat,
    isSpaceUserLoggedIn,
    notiContext,
    isVisitor,
    currentTabInParticipant,
    isSpaceDeleted,
    selectedTargetChannel,
    isPreview,
    openedP2PChannels,
    pagesBlock,
    currentPage,
    blockId
  ) => {
    const data = {
      isLoading,
      space,
      signalRConnection,
      theme,
      sidebar,
      spaceUserSession,
      code,
      pagesBlock,
      currentPage,
      isMobile,
      isMobilePortrait,
      isFetchingToken: getSpaceAccessTokenQuery.isFetching,
      newNotifications,
      showVaamRecorder,
      activeChannel,
      vaamTargetChannel,
      isSendingVaam,
      isVaamFromChat,
      isSpaceUserLoggedIn,
      notiContext,
      isVisitor,
      currentTabInParticipant,
      isSpaceDeleted,
      selectedTargetChannel,
      isPreview,
      openedP2PChannels,
      blockId,
    };
    data.isHostOfSpace = spaceUserSession?.role === SpaceUserRoleNames.Host;
    data.isGlobalResourcesEnabled =
      spaceUserSession?.subscriptionTypeId === SubscriptionType.enterprise;
    data.isPublicSpace = space?.spacePrivacy === PrivacySpace.PUBLIC;
    data.isPrivateSpace = space?.spacePrivacy === PrivacySpace.PRIVATE;
    data.isTemplate = space.isTemplate;
    return data;
  }
);
export const SpaceContext = React.createContext({
  space: null,
  theme: { logoImageSrc: null },
});
SpaceContext.displayName = 'SpaceContext';

export const useSpaceContext = () => React.useContext(SpaceContext);

// the implementation code is in SpaceContextProvider.js file.
