import _cloneDeep from 'lodash/cloneDeep';
import memoizeOne from 'memoize-one';

import {
  CMS_COMPONENT_STYLE,
  CMS_COMPONENT_TYPE,
  CMS_DEFAULT_DESIGN_SETTING,
  CONTENT_COMPONENT,
} from '../../cmsConstants';

export const getImageAlign = memoizeOne((type, firstItem) => {
  if (type === CMS_COMPONENT_TYPE.CAROUSEL) {
    return null;
  }
  let imageAlign = CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT;
  switch (firstItem.textPosition) {
    case CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT:
      imageAlign = CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT;
      break;
    case CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT:
      imageAlign = CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT;
      break;
    default:
      break;
  }
  return imageAlign;
});

export const isMultipleAlignment = memoizeOne((textPosition) => {
  const alignment = [
    CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_LEFT,
    CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_LEFT,
    CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_LEFT,
    CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_CENTER,
    CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER,
    CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_CENTER,
    CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_RIGHT,
    CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_RIGHT,
    CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_RIGHT,
  ];

  return alignment.includes(textPosition);
});

export const getWidgetBackgroundImageRatio = memoizeOne((columns, isMobile = false) => {
  let imageRatio = '';
  let imageRatioClass = '';
  if (isMobile) {
    imageRatio = `1:${columns}`;
    imageRatioClass = `paddingB-ratio${imageRatio.split(':').join('')}`;
    return { imageRatio, imageRatioClass };
  }
  // is not mobile
  if (columns <= 2) {
    imageRatio = '2:1';
    imageRatioClass = 'paddingB-ratio21';
  } else if (columns <= 4) {
    imageRatio = '3:2';
    imageRatioClass = 'paddingB-ratio32';
  }
  return { imageRatio, imageRatioClass };
});

export const getImageRatio = memoizeOne(
  (columns, textPosition, initialImageRatio, isMobile, size, isTablet, isDesktop) => {
    let imageRatio = initialImageRatio || '16:9';
    let imageRatioClass = `ratio${imageRatio.split(':').join('')}`;

    // Text and image columns
    if (textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM) {
      if (columns === 4) {
        imageRatio = '1:1';
        imageRatioClass = 'paddingB-ratio11';
      } else if (columns === 2 || columns === 3) {
        imageRatio = '3:2';
        imageRatioClass = 'paddingB-ratio32';
      }
    }

    // Image and Text Overlay
    if (isMultipleAlignment(textPosition)) {
      if (columns === 1) {
        if (isMobile) {
          imageRatio = '9:16';
          imageRatioClass = 'paddingB-ratio916';
        } else if (size === 'banner') {
          imageRatio = '4:1';
          imageRatioClass = 'paddingB-ratio41';
          if (isDesktop || isTablet) {
            imageRatio = '2:1';
            imageRatioClass = 'paddingB-ratio21';
          }
        } else {
          imageRatio = '16:9';
          imageRatioClass = 'paddingB-ratio169';
        }
      } else if (columns === 2) {
        if (isTablet) {
          imageRatio = '1:1';
          imageRatioClass = 'paddingB-ratio12';
        } else {
          imageRatio = '3:2';
          imageRatioClass = 'paddingB-ratio32';
        }
      } else if (columns === 3) {
        if (isDesktop) {
          imageRatio = '9:16';
          imageRatioClass = 'paddingB-ratio916';
        } else if (isTablet) {
          imageRatio = '9:16';
          imageRatioClass = 'paddingB-ratio222';
        } else {
          imageRatio = '3:2';
          imageRatioClass = 'paddingB-ratio32';
        }
      } else if (columns === 4) {
        imageRatio = '1:1';
        imageRatioClass = 'paddingB-ratio11';
      }
    }

    return { imageRatio, imageRatioClass };
  }
);

export const getDefaultBlockSizeByComponentType = memoizeOne((componentType) => {
  let size;
  switch (componentType) {
    case CMS_COMPONENT_TYPE.VIDEO:
    case CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT:
    case CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY:
    case CMS_COMPONENT_TYPE.WEB_CONTENT:
      size = CMS_COMPONENT_STYLE.SIZE.FIXEDWIDTH;
      break;
    default:
      break;
  }
  return size;
});

export function getLayout(selectedBlock, isMobile, index = 0) {
  // console.log('### getLayout', selectedBlock);
  if (!selectedBlock) return null;

  const type = selectedBlock.items?.[0]?.type;

  if (!type) return null;

  // let items = selectedBlock.items;
  let itemSelected = selectedBlock.items?.[index];

  const isWidget = type === CMS_COMPONENT_TYPE.WIDGET;
  const isCarousel = type === CMS_COMPONENT_TYPE.CAROUSEL;
  if (isCarousel) {
    // items = selectedBlock.items?.[0]?.items;
    itemSelected = selectedBlock.items?.[0]?.items?.[index];
  }
  if (selectedBlock.isPagesBlock) {
    itemSelected = selectedBlock.items?.[0];
  }

  if (!itemSelected) return null;

  let { imageRatio } = getImageRatio(
    selectedBlock.columns,
    itemSelected.textPosition,
    itemSelected.imageRatio,
    isMobile,
    selectedBlock.size
  );

  if (type === CMS_COMPONENT_TYPE.WIDGET) {
    // console.log('### widget 1', imageRatio);
    const res = getWidgetBackgroundImageRatio(selectedBlock.items?.length, isMobile);
    imageRatio = res.imageRatio;
    console.log('### widget 2', imageRatio);
  }

  let showBackgroundImage = false;
  if (
    selectedBlock.showBackgroundImage !== undefined &&
    selectedBlock.showBackgroundImage !== null
  ) {
    showBackgroundImage = !!selectedBlock.showBackgroundImage;
  } else {
    showBackgroundImage = !!selectedBlock.backgroundImageUrl;
  }

  let autoplay = false; // spc-3161 -> spc-3589
  if (itemSelected.autoplay !== null && itemSelected.autoplay !== undefined) {
    autoplay = itemSelected.autoplay;
  }

  const defaultSize = getDefaultBlockSizeByComponentType(type);
  const defaultLayout =
    type === CMS_COMPONENT_TYPE.VIDEO ? CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY : null;

  const layout = {
    id: selectedBlock.id,
    columns: isCarousel ? selectedBlock.items[0].items.length : selectedBlock.columns,
    showHeading: itemSelected.heading !== null && itemSelected.heading !== undefined,
    showDescription: itemSelected.description !== null && itemSelected.description !== undefined,
    showButton: itemSelected.buttonLabel !== null && itemSelected.buttonLabel !== undefined,
    showSecondaryButton:
      itemSelected.secondaryButtonLabel !== null && itemSelected.secondaryButtonLabel !== undefined,
    size: selectedBlock.size || selectedBlock.items[0].size || defaultSize,
    opacity: itemSelected.opacity,
    textAlign: itemSelected.textAlign,
    imageRatio: isCarousel ? itemSelected.imageRatio : imageRatio,
    imageAlign: getImageAlign(type, itemSelected),
    numberOfWidgets: isWidget ? selectedBlock.items.length : 0,
    numberOfSlides: isCarousel ? selectedBlock.items[0].items.length : 0,
    textPosition: itemSelected.textPosition,
    showBackgroundImage,
    autoplay,
    layout: selectedBlock.layout || defaultLayout,
  };
  if (selectedBlock.isPagesBlock) {
    layout.showHeading = true;
    layout.showSharePage = true;
  }
  return layout;
}

function updateBlockItems(items, key, value, index) {
  if (index !== undefined) {
    const newBlockItems = [...items];
    newBlockItems[index][key] = value;
    return newBlockItems;
  }

  return items.map((blockItem) => {
    const newBlockItem = { ...blockItem };
    newBlockItem[key] = value;
    return newBlockItem;
  });
}

export function updateBlockProp(block, key, value, index) {
  const newBlock = { ...block };
  if (newBlock.items[0].type === CMS_COMPONENT_TYPE.CAROUSEL) {
    newBlock.items[0].items = updateBlockItems(newBlock.items[0].items, key, value, index);
  } else {
    newBlock.items = updateBlockItems(newBlock.items, key, value, index);
  }
  return newBlock;
}

export const getDefaultDesignSettingsByTypeAndTextSize = memoizeOne(
  (theme, componentType, textSize, videoLayout = null, includesBlockColor = false) => {
    // org settings > default settings.

    const overlayLargeHeadingColor =
      theme?.overlayLargeHeadingColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_LARGE_HEADING_COLOR;
    const overlayLargeHeadingFontSize =
      theme?.overlayLargeHeadingFontSize ||
      CMS_DEFAULT_DESIGN_SETTING.OVERLAY_LARGE_HEADING_FONT_SIZE;
    const overlayLargeHeadingFontFamily =
      theme?.overlayLargeHeadingFontFamily ||
      CMS_DEFAULT_DESIGN_SETTING.OVERLAY_LARGE_HEADING_FONT_FAMILY;

    const overlayMediumHeadingColor =
      theme?.overlayMediumHeadingColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_MEDIUM_HEADING_COLOR;
    const overlayMediumHeadingFontSize =
      theme?.overlayMediumHeadingFontSize ||
      CMS_DEFAULT_DESIGN_SETTING.OVERLAY_MEDIUM_HEADING_FONT_SIZE;
    const overlayMediumHeadingFontFamily =
      theme?.overlayMediumHeadingFontFamily ||
      CMS_DEFAULT_DESIGN_SETTING.OVERLAY_MEDIUM_HEADING_FONT_FAMILY;

    const overlaySmallHeadingColor =
      theme?.overlaySmallHeadingColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_SMALL_HEADING_COLOR;
    const overlaySmallHeadingFontSize =
      theme?.overlaySmallHeadingFontSize ||
      CMS_DEFAULT_DESIGN_SETTING.OVERLAY_SMALL_HEADING_FONT_SIZE;
    const overlaySmallHeadingFontFamily =
      theme?.overlaySmallHeadingFontFamily ||
      CMS_DEFAULT_DESIGN_SETTING.OVERLAY_SMALL_HEADING_FONT_FAMILY;

    const largeHeadingColor =
      theme?.largeHeadingColor || CMS_DEFAULT_DESIGN_SETTING.LARGE_HEADING_COLOR;
    const largeHeadingFontSize =
      theme?.largeHeadingFontSize || CMS_DEFAULT_DESIGN_SETTING.LARGE_HEADING_FONT_SIZE;
    const largeHeadingFontFamily =
      theme?.largeHeadingFontFamily || CMS_DEFAULT_DESIGN_SETTING.LARGE_HEADING_FONT_FAMILY;

    const mediumHeadingColor =
      theme?.mediumHeadingColor || CMS_DEFAULT_DESIGN_SETTING.MEDIUM_HEADING_COLOR;
    const mediumHeadingFontSize =
      theme?.mediumHeadingFontSize || CMS_DEFAULT_DESIGN_SETTING.MEDIUM_HEADING_FONT_SIZE;
    const mediumHeadingFontFamily =
      theme?.mediumHeadingFontFamily || CMS_DEFAULT_DESIGN_SETTING.MEDIUM_HEADING_FONT_FAMILY;

    const smallHeadingColor =
      theme?.smallHeadingColor || CMS_DEFAULT_DESIGN_SETTING.SMALL_HEADING_COLOR;
    const smallHeadingFontSize =
      theme?.smallHeadingFontSize || CMS_DEFAULT_DESIGN_SETTING.SMALL_HEADING_FONT_SIZE;
    const smallHeadingFontFamily =
      theme?.smallHeadingFontFamily || CMS_DEFAULT_DESIGN_SETTING.SMALL_HEADING_FONT_FAMILY;

    const buttonLabelColor =
      theme?.buttonLabelColor || CMS_DEFAULT_DESIGN_SETTING.BUTTON_LABEL_COLOR;
    const buttonLabelFontSize =
      theme?.buttonLabelFontSize || CMS_DEFAULT_DESIGN_SETTING.BUTTON_LABEL_FONT_SIZE;
    const buttonLabelFontFamily =
      theme?.buttonLabelFontFamily || CMS_DEFAULT_DESIGN_SETTING.BUTTON_LABEL_FONT_FAMILY;
    const buttonBackgroundColor =
      theme?.buttonBackgroundColor || CMS_DEFAULT_DESIGN_SETTING.BUTTON_BACKGROUND_COLOR;
    const secondaryButtonLabelColor =
      theme?.secondaryButtonLabelColor || CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_LABEL_COLOR;
    const secondaryButtonLabelFontSize =
      theme?.secondaryButtonLabelFontSize ||
      CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_LABEL_FONT_SIZE;
    const secondaryButtonLabelFontFamily =
      theme?.secondaryButtonLabelFontFamily ||
      CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_LABEL_FONT_FAMILY;
    const secondaryButtonBackgroundColor =
      theme?.secondaryButtonBackgroundColor ||
      CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_BACKGROUND_COLOR;

    const blockBackgroundColor =
      theme?.blockBackgroundColor || CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_COLOR;

    const overlayDescriptionColor =
      theme?.overlayDescriptionColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_DESCRIPTION_COLOR;
    const overlayDescriptionFontSize =
      theme?.overlayDescriptionFontSize || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_DESCRIPTION_FONT_SIZE;
    const overlayDescriptionFontFamily =
      theme?.overlayDescriptionFontFamily ||
      CMS_DEFAULT_DESIGN_SETTING.OVERLAY_DESCRIPTION_FONT_FAMILY;

    const descriptionColor =
      theme?.descriptionColor || CMS_DEFAULT_DESIGN_SETTING.DESCRIPTION_COLOR;
    const descriptionFontSize =
      theme?.descriptionFontSize || CMS_DEFAULT_DESIGN_SETTING.DESCRIPTION_FONT_SIZE;
    const descriptionFontFamily =
      theme?.descriptionFontFamily || CMS_DEFAULT_DESIGN_SETTING.DESCRIPTION_FONT_FAMILY;

    const topPaddingSize = theme?.topPaddingSize || CMS_DEFAULT_DESIGN_SETTING.TOP_PADDING_SIZE;
    const bottomPaddingSize =
      theme?.bottomPaddingSize || CMS_DEFAULT_DESIGN_SETTING.BOTTOM_PADDING_SIZE;
    const leftPaddingSize = theme?.leftPaddingSize || CMS_DEFAULT_DESIGN_SETTING.LEFT_PADDING_SIZE;
    const rightPaddingSize =
      theme?.rightPaddingSize || CMS_DEFAULT_DESIGN_SETTING.RIGHT_PADDING_SIZE;

    // only for video layout - video text position
    const isOverlay = !videoLayout || videoLayout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY;

    let headingColor;
    let headingFontSize;
    let headingFontFamily;

    if (
      [CMS_COMPONENT_TYPE.CAROUSEL, CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY].includes(
        componentType
      ) ||
      (componentType === CMS_COMPONENT_TYPE.VIDEO && isOverlay)
    ) {
      switch (textSize) {
        case CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE:
          headingColor = overlayLargeHeadingColor;
          headingFontSize = overlayLargeHeadingFontSize;
          headingFontFamily = overlayLargeHeadingFontFamily;
          break;
        case CMS_COMPONENT_STYLE.TEXT_SIZE.MEDIUM:
          headingColor = overlayMediumHeadingColor;
          headingFontSize = overlayMediumHeadingFontSize;
          headingFontFamily = overlayMediumHeadingFontFamily;
          break;
        case CMS_COMPONENT_STYLE.TEXT_SIZE.SMALL:
          headingColor = overlaySmallHeadingColor;
          headingFontSize = overlaySmallHeadingFontSize;
          headingFontFamily = overlaySmallHeadingFontFamily;
          break;
        default:
          break;
      }
    } else {
      switch (textSize) {
        case CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE:
          headingColor = largeHeadingColor;
          headingFontSize = largeHeadingFontSize;
          headingFontFamily = largeHeadingFontFamily;
          break;
        case CMS_COMPONENT_STYLE.TEXT_SIZE.MEDIUM:
          headingColor = mediumHeadingColor;
          headingFontSize = mediumHeadingFontSize;
          headingFontFamily = mediumHeadingFontFamily;
          break;
        case CMS_COMPONENT_STYLE.TEXT_SIZE.SMALL:
          headingColor = smallHeadingColor;
          headingFontSize = smallHeadingFontSize;
          headingFontFamily = smallHeadingFontFamily;
          break;
        default:
          break;
      }
    }

    let designSettings = {};
    switch (componentType) {
      case CMS_COMPONENT_TYPE.VIDEO: {
        if (isOverlay) {
          designSettings = {
            headingColor,
            headingFontSize,
            headingFontFamily,
            descriptionColor: overlayDescriptionColor,
            descriptionFontSize: overlayDescriptionFontSize,
            descriptionFontFamily: overlayDescriptionFontFamily,
            topPaddingSize,
            bottomPaddingSize,
            leftPaddingSize,
            rightPaddingSize,
          };
        } else {
          designSettings = {
            headingColor,
            headingFontSize,
            headingFontFamily,
            descriptionColor: descriptionColor,
            descriptionFontSize: descriptionFontSize,
            descriptionFontFamily: descriptionFontFamily,
            buttonLabelColor,
            buttonLabelFontSize,
            buttonLabelFontFamily,
            buttonBackgroundColor,
            secondaryButtonLabelColor,
            secondaryButtonLabelFontSize,
            secondaryButtonLabelFontFamily,
            secondaryButtonBackgroundColor,
            topPaddingSize,
            bottomPaddingSize,
            leftPaddingSize,
            rightPaddingSize,
          };
        }
        break;
      }
      case CMS_COMPONENT_TYPE.CARD_TEXT:
      case CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT:
      case CMS_COMPONENT_TYPE.CARD_HERO:
        designSettings = {
          headingColor,
          headingFontSize,
          headingFontFamily,
          descriptionColor,
          descriptionFontSize,
          descriptionFontFamily,
          buttonLabelColor,
          buttonLabelFontSize,
          buttonLabelFontFamily,
          buttonBackgroundColor,
          secondaryButtonLabelColor,
          secondaryButtonLabelFontSize,
          secondaryButtonLabelFontFamily,
          secondaryButtonBackgroundColor,
          topPaddingSize,
          bottomPaddingSize,
          leftPaddingSize,
          rightPaddingSize,
        };
        break;
      case CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY:
      case CMS_COMPONENT_TYPE.CAROUSEL:
        designSettings = {
          headingColor,
          headingFontSize,
          headingFontFamily,
          descriptionColor: overlayDescriptionColor,
          descriptionFontSize: overlayDescriptionFontSize,
          descriptionFontFamily: overlayDescriptionFontFamily,
          buttonLabelColor,
          buttonLabelFontSize,
          buttonLabelFontFamily,
          buttonBackgroundColor,
          secondaryButtonLabelColor,
          secondaryButtonLabelFontSize,
          secondaryButtonLabelFontFamily,
          secondaryButtonBackgroundColor,
          topPaddingSize,
          bottomPaddingSize,
          leftPaddingSize,
          rightPaddingSize,
        };
        break;
      case CMS_COMPONENT_TYPE.WIDGET:
      case CMS_COMPONENT_TYPE.WEB_CONTENT:
      case CMS_COMPONENT_TYPE.HTML_CONTENT:
      case CMS_COMPONENT_TYPE.SCRIPT:
        designSettings = {
          topPaddingSize,
          bottomPaddingSize,
          leftPaddingSize,
          rightPaddingSize,
        };
        break;
      case CMS_COMPONENT_TYPE.PAGES:
        designSettings = {
          headingColor: CMS_DEFAULT_DESIGN_SETTING.HEADING_STATE_COLOR,
          headingFontSize: CMS_DEFAULT_DESIGN_SETTING.HEADING_STATE_FONT_SIZE,
          headingFontFamily: CMS_DEFAULT_DESIGN_SETTING.HEADING_STATE_FONT_FAMILY,
          underlineColor: CMS_DEFAULT_DESIGN_SETTING.UNDERLINE_COLOR,
          headingSelectedColor: CMS_DEFAULT_DESIGN_SETTING.HEADING_STATE_COLOR,
          headingSelectedFontSize: CMS_DEFAULT_DESIGN_SETTING.HEADING_STATE_FONT_SIZE,
          headingSelectedFontFamily: CMS_DEFAULT_DESIGN_SETTING.HEADING_SELECTED_FONT_FAMILY,
          backgroundColor: blockBackgroundColor,
          topPaddingSize,
          bottomPaddingSize,
          leftPaddingSize,
          rightPaddingSize,
        };
        break;
      default:
        break;
    }
    if (includesBlockColor) designSettings.blockBackgroundColor = blockBackgroundColor;
    return designSettings;
  }
);

export const getDefaultDesignSettings = memoizeOne((block, theme, includesBlockColor = false) => {
  if (!block) return null;
  let firstItem = block.items?.[0] || block;

  if (!firstItem) return null;

  const componentType = firstItem.type;

  if (componentType === CMS_COMPONENT_TYPE.CAROUSEL) {
    firstItem = firstItem.items?.[0];
  }

  if (!firstItem) return null;

  const textSize = firstItem.textSize;
  return getDefaultDesignSettingsByTypeAndTextSize(
    theme,
    componentType,
    textSize,
    block.layout,
    includesBlockColor
  );
});

export function updateHeadingFontSize(theme, block, isGreater) {
  const items = block?.items || [];
  if (items.length === 0) return block;
  const newDesignSettings = getDefaultDesignSettings(block, theme);
  const currentHeadingFontSize = items[0].headingFontSize; // not for carousel.
  const currentHeadingFontSizeValue = currentHeadingFontSize?.replace('px', '');
  const defaultHeadingFontSizeValue = newDesignSettings?.headingFontSize?.replace('px', '');
  // console.log(
  //   '### updateHeadingFontSize',
  //   newDesignSettings,
  //   currentHeadingFontSizeValue,
  //   defaultHeadingFontSizeValue
  // );
  let newBlock = block;
  if (
    !currentHeadingFontSize ||
    (isGreater && currentHeadingFontSizeValue > defaultHeadingFontSizeValue) ||
    (!isGreater && currentHeadingFontSizeValue < defaultHeadingFontSizeValue)
  ) {
    newBlock = updateBlockProp(block, 'headingFontSize', newDesignSettings.headingFontSize);
  }
  return newBlock;
}

export function updateHeadingFontColorAndFamily(theme, block, newTextSize) {
  const items = block?.items || [];
  if (items.length === 0) return block;

  const firstItem = items[0]; // not for carousel

  const componentType = firstItem.type;
  const oldTextSize = firstItem.textSize;

  const oldDesignSettings = getDefaultDesignSettingsByTypeAndTextSize(
    theme,
    componentType,
    oldTextSize,
    block.layout
  );
  const designSettings = getDefaultDesignSettingsByTypeAndTextSize(
    theme,
    componentType,
    newTextSize,
    block.layout
  );

  const newBlock = _cloneDeep(block);

  newBlock.items = newBlock.items.map((blockItem) => {
    const newBlockItem = _cloneDeep(blockItem);

    // heading
    if (
      !newBlockItem.headingColor ||
      newBlockItem.headingColor === oldDesignSettings.headingColor
    ) {
      newBlockItem.headingColor = designSettings.headingColor;
    }

    if (
      !newBlockItem.headingFontFamily ||
      newBlockItem.headingFontFamily === oldDesignSettings.headingFontFamily
    ) {
      newBlockItem.headingFontFamily = designSettings.headingFontFamily;
    }

    return newBlockItem;
  });

  return newBlock;
}

export const getResourceFieldName = memoizeOne((contentType) => {
  switch (contentType) {
    case CONTENT_COMPONENT.VIDEO_UPLOAD:
      return 'videoUrl';
    case CONTENT_COMPONENT.FILE_UPLOAD:
    case CONTENT_COMPONENT.IMAGE_UPLOAD:
      return 'backgroundImageUrl';
    default:
      return '';
  }
});

export const getPaddingByTextPositionAndImageRatio = memoizeOne(
  (textPosition, imageRatio, isMobile, isTablet, isDesktop) => {
    let maxWidthClass;
    let paddingClass;
    if (
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT ||
      textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT
    ) {
      // hero
      maxWidthClass = isMobile ? '' : 'maxWidth50p';
      if (isMobile) {
        paddingClass = '';
      } else if (isTablet) {
        paddingClass = imageRatio === '3:2' ? '' : 'paddingLR50';
      } else if (isDesktop) {
        paddingClass = imageRatio === '3:2' ? '' : 'paddingLR70';
      } else {
        paddingClass = imageRatio === '3:2' ? '' : 'paddingLR100';
      }
    }
    return { maxWidthClass, paddingClass };
  }
);
