import React, { Fragment, Suspense, lazy, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useDebouncedCallback } from 'use-debounce';
import _findIndex from 'lodash/findIndex';
import i18n from '../../../i18n';
import { CONTENT_COMPONENT, CONTENT_FORM_COMPONENT, CMS_COMPONENT_TYPE } from '../../cmsConstants';
import WidgetSelect from '../WidgetSelect';
import CmsFileUploader from '../../commons/CmsFileUploader';
import { ResourceType } from '../../../app/appConstants';
import {
  extractContent,
  isUrlsEqual,
  newGuid,
  trimSentence,
  trimString,
  trimText,
} from '../../../commons/utils';
import { useSpaceContext } from '../../../spaces/SpaceContext';
import ScriptInput from '../ScriptInput';
import { requestCheckEmbedableUrl } from '../../commons/useCheckLinkInIframe';
import useCmsDirectory from '../useCmsDirectory';
import Icons from '../../../components/Icons';
import Loading from '../../../components/Loading';
import { getVideoContentFormByTextPosition } from '../../cmsUtils';
import { findDeletedLinks, findNewLinks } from '../../commons/DescriptionLinkUtils';
import ShareLinkCmsBlock from '../ShareLinkCmsBlock';
import PageNameInput from '../PageNameInput';

const FormatCmsContent = lazy(() => import('../../commons/FormatCmsContent'));

function getResourceFieldName(contentType) {
  switch (contentType) {
    case CONTENT_COMPONENT.VIDEO_UPLOAD:
      return 'videoUrl';
    case CONTENT_COMPONENT.FILE_UPLOAD:
    case CONTENT_COMPONENT.IMAGE_UPLOAD:
      return 'backgroundImageUrl';
    default:
      return '';
  }
}

function hasImageExtension(fileName) {
  const extension = fileName.split('.').pop();
  if (extension === 'jpg' || extension === 'jpeg' || extension === 'gif' || extension === 'png') {
    return true;
  }
  return false;
}

function ContentForm(props) {
  const { type, content, onChange, layout, spaceId, isAdmin } = props;
  console.log('ContentForm content: ');
  // console.log('ContentForm layout: ', layout);
  // console.log('ContentForm type: ', type);
  const {
    showHeading,
    showDescription,
    showButton,
    showSecondaryButton,
    showBackgroundImage,
    textPosition,
  } = layout;
  const {
    heading,
    headingLink,
    description,
    descriptionLink,
    buttonLabel,
    buttonLink,
    secondaryButtonLabel,
    secondaryButtonLink,
  } = content;
  const { isPublicSpace, isShowroom } = useSpaceContext();
  let formComponents = CONTENT_FORM_COMPONENT[type];
  if (type === CMS_COMPONENT_TYPE.VIDEO) {
    formComponents = getVideoContentFormByTextPosition(textPosition);
  }
  const [cmsDirectory] = useCmsDirectory(spaceId);

  function getResourceName(fieldName, value, maxwords = 5) {
    const trimValue = trimSentence(value, maxwords);
    switch (fieldName) {
      case 'heading':
      case 'headingLink':
        return `${trimValue} (${i18n.t('Heading')})`;
      case 'description':
        return `${trimValue} (${i18n.t('Description')})`;
      case 'descriptionLink':
        return trimText(value, 200, ` ...(${i18n.t('Description')})`);
      case 'buttonLabel':
      case 'buttonLink':
        return `${trimValue} (${i18n.t('Primary button')})`;
      case 'secondaryButtonLabel':
      case 'secondaryButtonLink':
        return `${trimValue} (${i18n.t('Secondary button')})`;
      case 'videoUrl':
        return `${trimValue} (${i18n.t('Video url')})`;
      case 'backgroundImageUrl':
        return `${trimValue} (${i18n.t('Background image url')})`;
      default:
        return 'Untitled';
    }
  }

  const getUrlFieldName = (fieldName) => {
    switch (fieldName) {
      case 'heading':
        return 'headingLink';
      case 'description':
        return 'descriptionLink';
      case 'buttonLabel':
        return 'buttonLink';
      case 'secondaryButtonLabel':
        return 'secondaryButtonLink';
      case 'videoUrl':
      case 'backgroundImageUrl':
        return fieldName;
      default:
        return '';
    }
  };

  const getParentValue = (urlLabel, urlValue) => {
    switch (urlLabel) {
      case 'headingLink':
        return heading;
      case 'descriptionLink':
        return extractContent(description);
      case 'buttonLink':
        return buttonLabel;
      case 'secondaryButtonLink':
        return secondaryButtonLabel;
      case 'videoUrl':
      case 'backgroundImageUrl':
        return urlValue;
      default:
        return '';
    }
  };

  const getRelatedLinkValue = (parentLabel) => {
    switch (parentLabel) {
      case 'heading':
        return headingLink;
      case 'description':
        return descriptionLink;
      case 'button':
        return buttonLink;
      case 'secondaryButton':
        return secondaryButtonLink;
      case 'videoUrl':
      case 'backgroundImageUrl':
        return parentLabel;
      default:
        return '';
    }
  };

  const handleUrlValueChange = (name, value) => {
    const foundParentValue = getParentValue(name, value);
    if (!foundParentValue) {
      return [];
    }
    let resources = content.resources;
    if (!resources) {
      resources = [];
    }
    const currentResourceId = content[`${name}ResourceId`];
    const action = currentResourceId ? 'update' : 'add';
    const resourceName = getResourceName(name, foundParentValue);
    const newResource = {
      uniqueId: newGuid(),
      data: {
        resourceId: currentResourceId,
        fileName: resourceName,
        fileDescription: getResourceName(name, foundParentValue, 20),
        src: value,
        type: ResourceType.externalLink,
        order: 0,
        isExternal: true,
      },
      fieldName: name,
      action: action,
    };
    if (action === 'add') {
      resources.push(newResource);
    } else if (action === 'update') {
      const foundUpdatingResouceIndex = _findIndex(
        resources,
        (item) => item.data?.resourceId === currentResourceId && item.action === action
      );
      if (foundUpdatingResouceIndex !== -1) {
        resources[foundUpdatingResouceIndex].data.fileName = resourceName;
        resources[foundUpdatingResouceIndex].data.fileDescription = foundParentValue;
      } else {
        resources.push(newResource);
      }
    }
    return resources;
  };

  const handleParentValueChange = (name, value) => {
    console.log('value: ', value);
    let resources = content.resources;
    if (!resources) {
      resources = [];
    }
    const action = 'update';
    const urlFieldName = getUrlFieldName(name);
    const resourceId = content[`${urlFieldName}ResourceId`];
    const materialId = content[`${urlFieldName}MaterialId`];
    const resourceName = getResourceName(name, value);
    if (resourceId) {
      const foundResourceIndex = _findIndex(
        resources,
        (item) => item.data?.resourceId === resourceId
      );
      if (foundResourceIndex !== -1) {
        resources[foundResourceIndex].data.fileName = resourceName;
        resources[foundResourceIndex].data.fileDescription = value;
      } else {
        const newResource = {
          uniqueId: newGuid(),
          fieldName: urlFieldName,
          action: action,
          data: {
            resourceId: resourceId,
            fileName: resourceName,
            fileDescription: getResourceName(name, value, 20),
          },
        };
        resources.push(newResource);
      }
    } else if (materialId) {
      const foundUpdatingMaterialIndex = _findIndex(
        resources,
        (item) => item.data?.materialId === materialId
      );
      if (foundUpdatingMaterialIndex !== -1) {
        resources[foundUpdatingMaterialIndex].data.fileName = resourceName;
        resources[foundUpdatingMaterialIndex].data.fileDescription = value;
      } else {
        const newMaterial = {
          uniqueId: newGuid(),
          fieldName: urlFieldName,
          action: action,
          data: {
            materialId: materialId,
            fileName: resourceName,
            fileDescription: value,
          },
        };
        resources.push(newMaterial);
      }
    } else {
      const foundRelatedLinkValue = getRelatedLinkValue(name);
      if (foundRelatedLinkValue) {
        const newResource = {
          uniqueId: newGuid(),
          data: {
            fileName: resourceName,
            fileDescription: value,
            src: value,
            type: ResourceType.externalLink,
            order: 0,
            isExternal: true,
          },
          fieldName: name,
          action: 'add',
        };
        resources.push(newResource);
      }
    }
    return resources;
  };

  const handleTextResource = (name, value) => {
    console.log('### handleTextResource', content, name, value);
    let resources = content.resources;
    if (!resources) {
      resources = [];
    }
    let action = null;
    if (value) {
      if (name.indexOf('Link') !== -1) {
        resources = handleUrlValueChange(name, value);
      } else {
        resources = handleParentValueChange(name, value);
      }
    } else {
      action = 'delete';
      // delete material if any
      resources.push({
        uniqueId: newGuid(),
        fieldName: name,
        action: action,
      });
    }
    // console.log('onChange resources', resources);
    onChange([name, 'resources'], [value, resources], true);
  };

  // spc-5431 should not debounce here, coz this function is used for many text fields, it will skip the previous change if do changes immediately
  const handleTextFieldChangeWithResources = (event) => {
    // notice that this function is only used for changes related to resources.
    const name = event.target.name;
    const value = event.target.value;
    handleTextResource(name, value);
  };

  const handleHeadingChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleHeadingLinkChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleButtonLabelChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleButtonLinkChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleSecondaryButtonLabelChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleSecondaryButtonLinkChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleDescriptionLinksChange = useCallback(
    (event) => {
      console.log('### handleDescriptionLinksChange');
      const name = event.target.name;
      const value = event.target.value;
      const { content: descriptionContent, links } = value;
      const resources = [];
      const descriptionLinks = content.descriptionLinks || [];
      const newLinks = findNewLinks(links, descriptionLinks);
      const deletedLinks = findDeletedLinks(links, descriptionLinks);

      deletedLinks.forEach((link) => {
        const foundLinkIndex = _findIndex(
          descriptionLinks,
          (item) => isUrlsEqual(item.url, link.url) && item.text === link.text
        );
        if (foundLinkIndex !== -1) {
          resources.push({
            uniqueId: newGuid(),
            link: link,
            resourceId: descriptionLinks[foundLinkIndex].resourceId,
            materialId: descriptionLinks[foundLinkIndex].materialId,
            fieldName: 'descriptionLinks',
            action: 'delete',
          });
          descriptionLinks.splice(foundLinkIndex, 1);
        }
      });

      newLinks.forEach((link) => {
        const foundLinkIndex = _findIndex(
          descriptionLinks,
          (item) => item.url === link.url && item.text === link.text
        );
        if (foundLinkIndex === -1) {
          const uniqueId = newGuid();
          descriptionLinks.push({ ...link, uniqueId });

          const newResource = {
            uniqueId: uniqueId,
            data: {
              resourceId: undefined,
              fileName: link.url,
              fileDescription: link.text,
              src: link.url,
              type: ResourceType.externalLink,
              order: 0,
              isExternal: true,
            },
            link: link,
            fieldName: 'descriptionLinks',
            action: 'add',
          };

          resources.push(newResource);
        }
      });

      console.log('descriptionLinks', descriptionLinks);
      console.log('resources', resources);

      onChange(
        [name, 'descriptionLinks', 'resources'],
        [descriptionContent, descriptionLinks, resources],
        true
      );
    },
    [content.descriptionLinks, onChange]
  );

  const handleTextFieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    onChange(name, value);
  };

  const handleHTMLContentChange = useDebouncedCallback(
    (event) => handleTextFieldChange(event),
    400
  );

  const handleWebContentLinkChange = useDebouncedCallback(async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const embeddable = await requestCheckEmbedableUrl(value);
    onChange([name, 'embeddable'], [value, embeddable]);
  }, 400);

  const handleOnChangeWidget = useDebouncedCallback((value) => {
    // console.log('value', value);
    onChange('widget', value);
  }, 300);

  const handleOnChangeScriptInput = useDebouncedCallback((name, value) => {
    // console.log('value', value);
    onChange(name, value);
  }, 300);

  const handleResourceChange = (resource, contentType) => {
    let resources = content.resources;
    let fieldsUpdate = [];
    let valuesUpdate = [];
    if (!resources) {
      resources = [];
    }

    let fieldName = getResourceFieldName(contentType);
    const croppedFiledName = `${fieldName}Cropped`;

    if (resource) {
      const { fileName, fileDescription, resourceType, src, isExternal, settings, orgSrc } =
        resource;
      let tempFileName = fileName;
      if (!fileName || fileName === 'Untitled' || !hasImageExtension(fileName)) {
        tempFileName = 'Untitled';
        if (fieldName === 'videoUrl') {
          tempFileName = `${trimString(src, 23)} (Video url)`;
        } else if (fieldName === 'backgroundImageUrl') {
          tempFileName = `${trimString(src, 23)} (Background image url)`;
        }
      }
      // console.log('heading', heading);
      console.log('resource fileName', fileName);
      console.log('resource tempFileName', tempFileName);

      let action = null;
      if (!resource.resourceId) {
        const currentResourceId = content[`${fieldName}ResourceId`];
        action = currentResourceId ? 'update' : 'add';
        const newResource = {
          uniqueId: newGuid(),
          fieldName: fieldName,
          action: action,
          data: {
            fileName: tempFileName,
            fileDescription: fileDescription || '',
            src,
            type: resourceType,
            order: 0,
            isExternal,
            settings,
            orgSrc,
          },
          isFileUploader: true,
        };
        if (action === 'add') {
          resources.push(newResource);
        } else if (action === 'update') {
          const foundResourceIndex = _findIndex(
            resources,
            (item) => item.data?.resourceId === currentResourceId && item.action === action
          );
          if (foundResourceIndex !== -1) {
            resources[foundResourceIndex].data.fileName = tempFileName;
            resources[foundResourceIndex].data.fileDescription = fileDescription || '';
          } else {
            // If url link is deleting and continuously add new link
            const isDeletingItem = _findIndex(
              resources,
              (item) => item.fieldName === fieldName && item.action === 'delete'
            );
            // For case update url by paste new link to replace exit link
            const isUpdatingUrl = resource.src !== resource.fileName || resource.updateNewUrl;

            // Add new resource when replace or delete and add new
            if (isDeletingItem !== -1 || isUpdatingUrl) {
              newResource.action = 'add';
            }

            if (isUpdatingUrl) {
              resources.push({
                uniqueId: newGuid(),
                fieldName: fieldName,
                action: 'delete',
                isFileUploader: true,
              });
            }
            resources.push(newResource);
          }
        }
      } else {
        // backgroundImageUrlCropped
        if (resource.isCropped) {
          fieldName = `${fieldName}Cropped`;
          fieldsUpdate.push('cropperData');
          fieldsUpdate.push('backgroundImageCropped');
          valuesUpdate.push(resource.cropperData);
          valuesUpdate.push(resource.backgroundImageCropped);
          // Remove old cropped image
          resources.push({
            uniqueId: newGuid(),
            action: 'delete',
            isFileUploader: true,
            fieldName: fieldName,
          });
        }

        resources.push({
          uniqueId: newGuid(),
          fieldName: fieldName,
          action: 'add',
          data: {
            resourceId: resource.resourceId,
            fileName: tempFileName,
            fileDescription: fileDescription || '',
            type: resourceType,
            canvasBackgroundColor: resource?.backgroundImageCropped,
            isCropped: resource.isCropped || false,
            materialId: resource.materialId,
          },
          isFileUploader: true,
        });
      }
    } else {
      resources.push({
        uniqueId: newGuid(),
        fieldName: fieldName,
        action: 'delete',
        isFileUploader: true,
      });
      // For case remove cropped images also;
      if (content[`${fieldName}Cropped`]) {
        resources.push({
          uniqueId: newGuid(),
          fieldName: `${fieldName}Cropped`,
          action: 'delete',
          isFileUploader: true,
        });
        fieldsUpdate = [croppedFiledName, 'cropperData'];
        valuesUpdate = [undefined, undefined, undefined];
      }
    }
    fieldsUpdate = [...fieldsUpdate, fieldName, 'resources'];
    valuesUpdate = [...valuesUpdate, resource?.resourceId, resources];
    onChange(fieldsUpdate, valuesUpdate, true);
  };

  const handleVideoChange = useDebouncedCallback(
    (resource, contentType) => handleResourceChange(resource, contentType),
    400
  );

  const handleImageChange = useDebouncedCallback(
    (resource, contentType) => handleResourceChange(resource, contentType),
    400
  );

  const handleFileChange = useDebouncedCallback(
    (resource, contentType) => handleResourceChange(resource, contentType),
    400
  );

  const onChangePageName = (name, value) => {
    onChange(name, value);
  };

  const handlePageNameChange = useDebouncedCallback((name, value) => {
    onChangePageName(name, value);
  }, 400);

  function renderComponent(component, index) {
    let element = null;
    const showDivider =
      showDescription || showHeading || showButton || showSecondaryButton || showBackgroundImage;
    switch (component.component) {
      case CONTENT_COMPONENT.VIDEO_UPLOAD:
        element = (
          <>
            <CmsFileUploader
              key={index}
              contentType={component.component}
              componentType={type}
              onChange={handleVideoChange}
              resourceId={content.videoUrl || content.videoUrlResourceId}
              spaceId={spaceId}
              currentTab={props.currentTab}
              content={content}
              folderId={cmsDirectory?.id}
              isAdmin={isAdmin}
            />
            <div className="divider"></div>
          </>
        );
        break;
      case CONTENT_COMPONENT.IMAGE_UPLOAD: {
        if (type !== CMS_COMPONENT_TYPE.WIDGET || !!showBackgroundImage) {
          element = (
            <>
              <CmsFileUploader
                key={index}
                contentType={component.component}
                componentType={type}
                onChange={handleImageChange}
                resourceId={content.backgroundImageUrl}
                spaceId={spaceId}
                ratio={layout?.imageRatio}
                currentTab={props.currentTab}
                content={content}
                folderId={cmsDirectory?.id}
              />
              {showDivider && <div className="divider"></div>}
            </>
          );
        }
        break;
      }
      case CONTENT_COMPONENT.FILE_UPLOAD:
        element = (
          <>
            <CmsFileUploader
              key={index}
              contentType={component.component}
              componentType={type}
              onChange={handleFileChange}
              resourceId={content.backgroundImageUrl}
              spaceId={spaceId}
              ratio={layout?.imageRatio}
              currentTab={props.currentTab}
              content={content}
              folderId={cmsDirectory?.id}
            />
            {showDivider && <div className="divider"></div>}
          </>
        );
        break;
      case CONTENT_COMPONENT.HEADING:
        element = showHeading && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Heading')}</label>
            <TextField
              autoComplete="off"
              fullWidth
              multiline
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder=""
              defaultValue={heading}
              name="heading"
              onChange={handleHeadingChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.HEADING_LINK:
        element = showHeading && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Heading link')}</label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter link')}
              defaultValue={headingLink}
              name="headingLink"
              onChange={handleHeadingLinkChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.DESCRIPTION:
        element = showDescription && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Description')}</label>
            <Suspense fallback={<Loading />}>
              <FormatCmsContent
                key={index}
                id={`cms-description-${content.id}-${props.currentTab}`}
                defaultValue={description}
                name="description"
                onChange={handleDescriptionLinksChange}
              />
            </Suspense>
          </div>
        );
        break;
      case CONTENT_COMPONENT.BUTTON_LABEL:
        element = showButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Primary button text')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder=""
              defaultValue={buttonLabel}
              name="buttonLabel"
              onChange={handleButtonLabelChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.BUTTON_LINK:
        element = showButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Primary button link')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter link')}
              defaultValue={buttonLink}
              name="buttonLink"
              onChange={handleButtonLinkChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL:
        element = showSecondaryButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Secondary button text')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder=""
              defaultValue={secondaryButtonLabel}
              name="secondaryButtonLabel"
              onChange={handleSecondaryButtonLabelChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.SECONDARY_BUTTON_LINK:
        element = showSecondaryButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Secondary button link')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter link')}
              defaultValue={secondaryButtonLink}
              name="secondaryButtonLink"
              onChange={handleSecondaryButtonLinkChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.WIDGET: {
        element = (
          <WidgetSelect
            onChange={handleOnChangeWidget}
            defaultValue={content}
            key={index}
            contentType={component.component}
            isPublicSpace={isPublicSpace}
            isShowroom={isShowroom}
          />
        );
        break;
      }
      case CONTENT_COMPONENT.URL_LINK:
        element = (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Website link')}</label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder="www.google.com"
              defaultValue={content.webUrl}
              name="webUrl"
              onChange={handleWebContentLinkChange}
            />
            <div className="alert-info" style={{ marginTop: 20 }}>
              <Icons name="icon-new-info" className="icon" />
              <span>
                {i18n.t(
                  "This component is an iframe. Please note that some websites don't allow to be opened in an iframe."
                )}
              </span>
            </div>
          </div>
        );
        break;
      case CONTENT_COMPONENT.SCRIPT:
        element = (
          <ScriptInput
            key={index}
            onChange={handleOnChangeScriptInput}
            defaultValue={content.script}
            componentSettings={content}
          />
        );
        break;
      case CONTENT_COMPONENT.HTML_CONTENT:
        element = (
          <div className="cms-component-panel-control cms-html-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('HTML/CSS content')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              multiline
              rows={10}
              className="cms-component-panel-control-input cms-html-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter your code here')}
              defaultValue={content.htmlContent}
              name="htmlContent"
              onChange={handleHTMLContentChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.PAGE_HEADING:
        element = showHeading && (
          <PageNameInput key={index} defaultValue={content.name} onChange={handlePageNameChange} />
        );
        break;
      case CONTENT_COMPONENT.PAGE_SHARE_LINK:
        element = <ShareLinkCmsBlock key={index} pageSlug={content.slug} />;
        break;
      default:
        break;
    }
    return <Fragment key={index}>{element}</Fragment>;
  }

  return formComponents.map((component, index) => renderComponent(component, index));
}

ContentForm.propTypes = {
  type: PropTypes.string,
  content: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  layout: PropTypes.shape({}),
  spaceId: PropTypes.string,
  currentTab: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default ContentForm;
