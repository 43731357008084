import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import PropTypes from 'prop-types';
import { CacheKeys } from '../../app/queryCache';
import SearchBar from '../SearchBar';
import Loading from '../Loading';
import i18n from '../../i18n';
import { getUnsplashPhotos } from '../../createSpace/services';
import './SearchUnsplash.scss';
import OfficialButton from '../OfficialButtons';
import { Ids } from '../../commons/pendoTaggings';

function Photo(props) {
  const { photo, onClickPhoto, selectedPhoto } = props;
  const [loaded, setLoaded] = useState(false);
  const isSelected = selectedPhoto && selectedPhoto.urls.full === photo.urls.full;

  return (
    <div className="card" onClick={() => onClickPhoto(photo)}>
      <img
        style={{ display: loaded ? 'flex' : 'none' }}
        className={`card-image ${isSelected ? 'selected' : ''}`}
        alt={photo.alt_Description}
        src={photo.urls.regular}
        width="50%"
        height="50%"
        onLoad={() => setLoaded(true)}
      ></img>
    </div>
  );
}

const SearchUnsplash = (props) => {
  const [images, setImages] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const keyword = searchValue || 'Meeting Room';

  const fetchUnsplashPhotos = useQuery({
    queryKey: [CacheKeys.getUnsplashPhotos, keyword],
    queryFn: async () => {
      const resp = await getUnsplashPhotos(keyword);
      return resp;
    },
    retry: 0,
    retryDelay: () => 5000,
    enabled: keyword.length > 0,
  });

  useEffect(() => {
    const data = fetchUnsplashPhotos.data;
    if (data) {
      setImages(data);
    }
  }, [fetchUnsplashPhotos]);

  function handleSearchPhotos(value) {
    const newValue = value.toLowerCase().trim();
    setSearchValue(newValue);
  }

  function handleOnClickImage(value) {
    setSelectedImage(value);
  }

  async function handleSelectImage() {
    if (props.onSelectPhoto) {
      setIsProcessing(true);
      await props.onSelectPhoto(selectedImage);
      setIsProcessing(false);
    }
  }

  function renderImages() {
    if (fetchUnsplashPhotos.isLoading) {
      return <Loading className="loading" />;
    }

    return (
      <div className="search-unsplash-card-list">
        {images.map((image, index) => {
          return (
            <Photo
              key={index}
              photo={image}
              onClickPhoto={handleOnClickImage}
              selectedPhoto={selectedImage}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="search-unsplash">
      <div className="images-container">
        <SearchBar autoFocus={false} defaultValue={searchValue} onChange={handleSearchPhotos} />
        <div className="images">{renderImages()}</div>
      </div>
      <div className="footer">
        <div className="btn-action">
          <OfficialButton
            disabled={!selectedImage}
            variant="regular-primary"
            onClick={handleSelectImage}
            isProcessing={isProcessing}
            dataId={Ids.selectUnsplashImage}
            label={i18n.t('Select')}
          />
        </div>
      </div>
    </div>
  );
};

SearchUnsplash.propTypes = {
  onSelectPhoto: PropTypes.func,
};

Photo.propTypes = {
  photo: PropTypes.instanceOf(Object),
  selectedPhoto: PropTypes.instanceOf(Object),
  onClickPhoto: PropTypes.func,
};

export default SearchUnsplash;
