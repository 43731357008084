import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  Suspense,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import { FeatureFlagsType, ResourceState, ResourceType } from '../app/appConstants';
import queryCache, { CacheKeys, clearCache } from '../app/queryCache';
import ConfirmService from '../components/ConfirmService';
import { formatResources, GLOBAL_RESOURCE_TYPE } from './utils';
import i18n from '../i18n';
import { formatDateTime } from '../commons/DateTimeUtils';
import NoDaTaBox from '../components/NoDataBox';
import useArrayState from '../commons/useArrayState';
import { useGlobalResourcesContext } from './context/GlobalResourcesContextProvider';
import ActionsMenu from './ActionsMenu';
import AddButton from './AddButton';
import useUploadGlobalResources from './useUploadGlobalResources';
import useGlobalResourcesForm from './useGlobalResourcesForm';
import { useSignalRClientMethod } from '../commons/useSignalRClientMethod';
import { useAppContext } from '../components/AppProvider';
import useGlobalResourcesQuery from './useGlobalResourcesQuery';
import { getGROrderBy } from './ResourcesListView/utils';
import GlobalResourcesLoading from './GlobalResourcesLoading';
import useDownloadResource from '../resources/Download/useDownloadResource';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import ResourcesDisplayView from './ResourcesDisplayView/ResourcesDisplayView';
import ResourcesViewControls from '../components/ResourcesView/ResourcesViewControls';
import { useResourcesViewContext } from '../components/ResourcesView/ResourcesViewContextProvider';
import ActionButtons from './ActionButtons';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import { useBreadcrumbContext } from './context/BreadcrumbsContextProvider';
import ResourceSignalRHandler from '../components/ResourceSignalRHandler';
import OfficialButton from '../components/OfficialButtons';

const GlobalResourcesSpceTable = forwardRef((props, ref) => {
  const {
    isGlobalResourcesEnabled,
    previewItemInPopup,
    multiple,
    accept,
    disabledFolderIds,
    isMovingResources,
    viewingResourceRef,
  } = props;

  const [resourceHubConnection, setResourceHubConnection] = useState(null);

  const { resources, selectedResources, setResources, clearSelectedResources } =
    useGlobalResourcesContext();

  const {
    breadcrumbs,
    currentBreadcrumbItem: selectedFolder,
    goToBreadcrumbItem,
    updateBreadcrumbItem,
    goToRootItem,
  } = useBreadcrumbContext();

  const [rowCount, setRowCount] = useState(0);

  const {
    orderBy,
    order,
    page,
    pageSize,
    setPage,
    searchText,
    setSearchText,
    isPicker,
    // setSortData,
  } = useResourcesViewContext();

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);
  const { showUploadResourceDialog } = useUploadGlobalResources();
  const { showGlobalResourcesForm } = useGlobalResourcesForm();

  const toClearCache = useRef(false);
  const isEditing = useRef(false);
  const formData = useRef(null);
  const setFormData = useCallback((newFormData) => {
    formData.current = newFormData;
  }, []);

  const needToClearCacheFoldersPages = useRef(null);
  const setNeedToClearCacheFoldersPages = useCallback((newData) => {
    needToClearCacheFoldersPages.current = newData;
  }, []);

  const { userInfo } = useAppContext();

  const {
    items: processingResources,
    addItem: addProcessingResource,
    removeItem: removeProcessingResource,
  } = useArrayState();

  const { handleClickDownloadGlobalDirectory } = useDownloadResource();

  useImperativeHandle(ref, () => ({
    navigate(clickingFolder, previousFolder) {
      if (toClearCache.current) {
        toClearCache.current = false;
        const queryKey = [CacheKeys.getGlobalResourcesMngt, previousFolder?.id];
        queryCache.removeQueries({
          queryKey: queryKey,
          exact: false,
        });
      }
    },
  }));

  // useUnmountEffect(() => {
  //   queryCache.removeQueries([CacheKeys.getGlobalResourcesMngt], { exact: false });
  //   // sessionStorage.removeItem('gr-currentSpceFolderId');
  // });

  function clearFolderCache(folderId, exact = false) {
    if (!folderId) return;
    queryCache.removeQueries({ queryKey: [CacheKeys.getGlobalResourcesMngt, folderId], exact });
  }

  function clearFolderCacheAtPage(folderId, noPage = 0, exact = false) {
    if (!folderId) return;
    queryCache.removeQueries({
      queryKey: [CacheKeys.getGlobalResourcesMngt, folderId, noPage],
      exact,
    });
  }

  function clearFolderCacheFromPage(folderId, fromPage = 0, toPage = 0, exact = false) {
    if (!folderId) return;
    for (let noPage = fromPage; noPage <= toPage; noPage += 1) {
      queryCache.removeQueries({
        queryKey: [CacheKeys.getGlobalResourcesMngt, folderId, noPage],
        exact,
      });
    }
  }

  const clearFoldersCaches = useCallback(() => {
    console.log('### clearFoldersCaches', needToClearCacheFoldersPages.current);
    if (!needToClearCacheFoldersPages.current) return;
    const keys = Object.keys(needToClearCacheFoldersPages.current);
    keys.forEach((k) => {
      const folderId = +k;
      let pageNumbers = needToClearCacheFoldersPages.current[k] || [];
      pageNumbers = [...new Set(pageNumbers)]; // disctinct
      pageNumbers.forEach((noPage) => {
        clearFolderCacheAtPage(folderId, noPage);
      });
    });
    setNeedToClearCacheFoldersPages(null);
  }, [setNeedToClearCacheFoldersPages]);

  useEffect(() => {
    clearFoldersCaches();
  }, [page, clearFoldersCaches]);

  const updateNeedToClearCacheFoldersPages = useCallback(
    (parentId, pageNumber = null) => {
      console.log('### updateNeedToClearCacheFoldersPages', parentId, pageNumber);
      if (parentId === null || parentId === undefined) return;
      // if (pageNumber === null || pageNumber === undefined) return;
      const pNumber = pageNumber || page;
      const newData = needToClearCacheFoldersPages.current || {};
      const newArr = newData[parentId] || [];
      newArr.push(pNumber);
      newData[parentId] = newArr;
      setNeedToClearCacheFoldersPages(newData);
    },
    [page, setNeedToClearCacheFoldersPages]
  );

  const sortBy = getGROrderBy(orderBy, order);
  // console.log('### GlobalResourcesSpceTable sortBy', sortBy);
  const {
    data,
    isLoading: isLoadingResources,
    refetch,
    isError,
  } = useGlobalResourcesQuery(
    selectedFolder,
    page,
    pageSize,
    searchText,
    sortBy,
    CacheKeys.getGlobalResourcesMngt,
    isGlobalResourcesEnabled,
    props.isPortal
  );

  const isLoading = resources === null || isLoadingResources;

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);

  useEffect(() => {
    // console.log('### globalResourcesQuery data', data);
    if (data) {
      const totalItems = data.totalItems;
      setRowCount(totalItems);

      const formattedData = formatResources(data.items);
      setResources(formattedData);

      if (isEnabledMaterialSlider) {
        const viewableMaterials = formattedData?.filter(
          (m) =>
            m.state !== ResourceState.Transcoding &&
            m.state !== ResourceState.Converting &&
            m.state !== ResourceState.TranscodingFailed &&
            m.state !== ResourceState.ConversionFailed
        );
        sessionStorage.setItem(
          'selectedFolderMaterials',
          JSON.stringify({
            spaceFolderId: -1,
            materials: viewableMaterials,
          })
        );
      }
    }
  }, [data, isEnabledMaterialSlider, setResources]);

  useEffect(() => {
    if (isError) {
      setResources([]);
    }
  }, [isError, setResources]);

  const handleFolderSelected = useCallback(
    (folder) => {
      const shouldClearCache = folder?.id !== selectedFolder?.id && toClearCache.current;
      const toBeClearedCacheFolderId = selectedFolder?.id;
      setSearchText('');

      // setCurrentFolderId(folder?.id);
      if (shouldClearCache) {
        toClearCache.current = false;
        clearFolderCache(toBeClearedCacheFolderId);
      }
      goToBreadcrumbItem(folder);
    },
    [selectedFolder?.id, setSearchText, goToBreadcrumbItem]
  );

  const handleResourceDoubleClicked = useCallback(
    (resource) => {
      if (previewItemInPopup) {
        previewItemInPopup(resource, resourceHubConnection);
      }
    },
    [previewItemInPopup, resourceHubConnection]
  );

  const handleOnAddedOrUpdatedResource = useCallback(
    async (shouldRefetch = true) => {
      if (shouldRefetch) {
        await refetch();
      }
      // clear caches
      if (selectedFolder?.parentId > 0) {
        queryCache.removeQueries({
          queryKey: [CacheKeys.getGlobalResourcesMngt, selectedFolder.parentId],
          exact: false,
        });
      }
    },
    [refetch, selectedFolder?.parentId]
  );

  const handleOnFolderAdded = async () => {
    const shouldRefetch = true;
    await handleOnAddedOrUpdatedResource(shouldRefetch);
    if (selectedFolder?.id > 0) {
      const totalPages = Math.ceil(rowCount / pageSize);
      const lastPage = totalPages - 1;
      clearFolderCacheFromPage(selectedFolder.id, page + 1, lastPage);
    }
  };

  async function handleRenameBtnClick() {
    isEditing.current = true;
    const result = await showGlobalResourcesForm(selectedFolder, formData.current, true);
    if (result) {
      const shouldRefetch = false; // because now we broadcast messages to org, all admins receive, no need self refetch
      await handleOnAddedOrUpdatedResource(shouldRefetch);
    }
    setFormData(null);
  }

  const handleOnFileOrFolderDeleted = useCallback(() => {
    // delete from 3-dots button click.
    if (!formData.current) {
      return;
    }
    setFormData(null);
  }, [setFormData]);

  const handleOnFilesAddedOrUpdated = async (newResources) => {
    // console.log('### handleOnFilesAddedOrUpdated', newResources);
    if (!newResources?.length) return;
    // clear caches
    const shouldRefetch = true;
    await handleOnAddedOrUpdatedResource(shouldRefetch);
  };

  const handleUploadNewVersionBtnClick = async () => {
    if (!formData.current) {
      return;
    }
    let resourceFolder = selectedFolder;
    if (selectedFolder?.id !== formData.current.resourceFolderId) {
      resourceFolder = {
        id: formData.current.resourceFolderId,
      };
    }
    const newResources = await showUploadResourceDialog(resourceFolder, formData.current);
    if (newResources.length > 0) {
      handleOnFilesAddedOrUpdated(newResources);
    }
    setFormData(null);
  };

  const informResourceIsUpdated = useCallback(
    async (detail) => {
      const isViewingResource = viewingResourceRef.current?.resourceId === detail.resourceId;
      if (isViewingResource) {
        eventBus.publish(EVENT_BUS.ClosePreviewResourceDialog);
      }
      const isEditingResource =
        !!isEditing.current && formData.current?.resourceId === detail.resourceId;
      if (isViewingResource || isEditingResource) {
        let rName = '';
        if (isViewingResource) {
          rName =
            viewingResourceRef.current.resourceDisplayName ||
            viewingResourceRef.current.resourceName ||
            viewingResourceRef.current.name;
          queryCache.removeQueries({
            queryKey: [CacheKeys.getResourceForViewer, detail.resourceId],
            exact: false,
          });
        }
        if (isEditingResource) {
          rName = formData.current?.name;
          isEditing.current = false;
          formData.current = null;
        }
        const msg = !rName
          ? 'This resource has been updated'
          : `The resource ${rName} has been updated`;
        await ConfirmService.show(i18n.t('RESOURCE UPDATED'), msg, i18n.t('Ok'), null, true);
      }
    },
    [viewingResourceRef]
  );

  const onTranscodingFinished = useCallback(
    (detail) => {
      setResources((oldResources) => {
        const newResourcesState = [...oldResources];

        const foundResourceIndex = newResourcesState.findIndex(
          (resource) => resource.resourceId === detail.id
        );
        if (foundResourceIndex > -1) {
          const now = formatDateTime(new Date(), 'MMM DD, YYYY');
          const foundResource = _cloneDeep(newResourcesState[foundResourceIndex]);
          foundResource.state = ResourceState.Transcoded;
          foundResource.url = detail.src;
          foundResource.lastModified = now;
          if (detail.thumbnail) {
            foundResource.thumbnail = detail.thumbnail;
          }
          newResourcesState[foundResourceIndex] = foundResource;

          return newResourcesState;
        }

        return oldResources;
      });

      clearCache([CacheKeys.getResourceForViewer], false);

      toClearCache.current = true;
    },
    [setResources]
  );

  const onConversionStarted = useCallback(
    (detail) => {
      addProcessingResource(detail.resourceId);
    },
    [addProcessingResource]
  );

  const onConversionEnd = useCallback(
    (detail) => {
      clearCache([CacheKeys.getResourceForViewer, detail.resourceId], false);

      removeProcessingResource(detail.resourceId);

      setResources((oldResources) => {
        if (oldResources?.length) {
          const newResourcesState = [...oldResources];

          const foundResourceIndex = newResourcesState.findIndex(
            (resource) => resource.resourceId === detail.resourceId
          );
          if (foundResourceIndex > -1) {
            const foundResource = _cloneDeep(newResourcesState[foundResourceIndex]);
            foundResource.state = detail.state;
            newResourcesState[foundResourceIndex] = foundResource;
          }

          return newResourcesState;
        }

        return oldResources;
      });
      toClearCache.current = true;
    },
    [removeProcessingResource, setResources]
  );

  const onResourceFolderDeleted = useCallback(
    (detail) => {
      console.log('### onResourceFolderDeleted', detail);
      // NOTE: for other admins & trigger, because dont have triggeredUserInfoId in 'detail'
      if (!detail?.id) return;
      const folderId = detail.id; // deleted folder
      if (detail.parentId > 0) {
        setResources((oldResources) => {
          const currentFolderId = selectedFolder?.id;
          const newResources = [...oldResources];
          const foundFolderIndex = newResources.findIndex((x) => x.id === folderId);

          if (detail.parentId === currentFolderId || foundFolderIndex >= 0) {
            // in the same/current level.
            const removedItems = _remove(newResources, (x) => x.id === folderId);
            if (removedItems?.length > 0) {
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
              clearFolderCache(selectedFolder?.parentId);
              const totalPages = Math.ceil(rowCount / pageSize);
              const lastPage = totalPages - 1;
              if (totalPages > 1 && page !== lastPage) {
                clearFolderCacheFromPage(detail.parentId, page, lastPage);
              } else {
                // case 1: totalPages > 1 && page === lastPage
                // case 2: totalPages === 1
                const newRowCount = rowCount - 1;
                setRowCount(newRowCount);
                if (totalPages > 1) {
                  updateNeedToClearCacheFoldersPages(detail.parentId);
                }
              }
            } else {
              // same parent, different page.
              clearFolderCache(detail.parentId);
            }
            if (!newResources?.length && page > 0) {
              const previousPage = page - 1;
              clearFolderCacheAtPage(detail.parentId, previousPage); // before setPage
              setPage(previousPage);
            }
          } else {
            clearFolderCache(detail.parentId);
            // update the itemsCount
            const parentFolderId = detail.parentId;
            const foundIndex = oldResources.findIndex((x) => x.id === parentFolderId);
            if (foundIndex >= 0) {
              const foundFolder = _cloneDeep(oldResources[foundIndex]);
              foundFolder.itemsCount -= 1;
              newResources[foundIndex] = foundFolder;
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
            }
            // in case of admins are in the grandchildren folder of the deleted folder.
            const positionOfFolder = breadcrumbs.findIndex((x) => x.id === folderId);
            if (positionOfFolder >= 1) {
              ConfirmService.show(
                i18n.t('FOLDER DELETED'),
                `The folder ${detail.name} has been deleted.`,
                i18n.t('Ok'),
                null,
                true
              )
                .then((isConfirmed) => {
                  if (isConfirmed) {
                    goToBreadcrumbItem(breadcrumbs[positionOfFolder - 1]);
                  }
                })
                .catch((error) => {
                  console.error('### onResourceFolderDeleted', error);
                });
            }
          }
          return newResources;
        });
      }
    },
    [
      breadcrumbs,
      page,
      pageSize,
      rowCount,
      selectedFolder?.id,
      selectedFolder?.parentId,
      setPage,
      setResources,
      goToBreadcrumbItem,
      updateNeedToClearCacheFoldersPages,
    ]
  );

  const onResourceFolderAdded = useCallback(
    (detail) => {
      // console.log('### onResourceFolderAdded', detail);
      // NOTE: for other admins, not creator
      if (!detail?.id) return;
      if (detail.createdByUserInfoId === userInfo?.id) return; // ignore the event triggered by the current user.
      if (detail.parentId > 0) {
        setResources((currentResources) => {
          const newResources = [...currentResources];
          if (detail.parentId === selectedFolder?.id) {
            // in the same/current level.
            const folderId = detail.id;
            const foundFolder = currentResources.find((x) => x.id === folderId);
            if (!foundFolder) {
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
              clearFolderCache(selectedFolder?.parentId);

              // scenario 1: at page 1, add a folder which will be added to page 1 because of its name in sort order
              // scenario 2: at page 1, add a folder which will be added to page 2 or N because of its name in sort order
              // scenario 3: at page 2 or 3 or N, add a folder which will be added to page 1 because of its name in sort order
              // dont know how many pages need to clear cache -> clear all by folder parent.
              clearFolderCache(detail.parentId);
            }
          } else {
            // not in the same/current level.
            clearFolderCache(detail.parentId);
            // update the itemsCount
            const folderId = detail.parentId;
            const foundIndex = currentResources.findIndex((x) => x.id === folderId);
            if (foundIndex >= 0) {
              const foundFolder = _cloneDeep(currentResources[foundIndex]);
              foundFolder.itemsCount += 1;
              newResources[foundIndex] = foundFolder;
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
            }
          }
          return newResources;
        });
      }
    },
    [selectedFolder?.id, selectedFolder?.parentId, setResources, userInfo?.id]
  );

  const onResourceFolderUpdated = useCallback(
    (detail) => {
      console.log('### onResourceFolderUpdated', detail);
      // NOTE: for other admins & trigger, because dont have triggeredUserInfoId in 'detail'
      if (!detail?.id) return;
      const currentFolderId = selectedFolder?.id;
      if (detail.parentId > 0) {
        setResources((oldResources) => {
          const folderId = detail.id;
          if (detail.parentId === currentFolderId) {
            // in the same/current level.
            const foundIndex = oldResources.findIndex((x) => x.id === folderId);
            if (foundIndex >= 0) {
              let updatedFolder = { ...detail };
              const updatedFolders = formatResources([updatedFolder]);
              updatedFolder = updatedFolders[0];
              const newResources = [...oldResources];
              const foundFolder = _cloneDeep(newResources[foundIndex]);
              foundFolder.name = updatedFolder.name;
              foundFolder.description = updatedFolder.description;
              foundFolder.lastModified = updatedFolder.lastModified;
              foundFolder.displayDateTime = updatedFolder.displayDateTime;
              newResources[foundIndex] = foundFolder;
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
              clearFolderCache(selectedFolder?.parentId);
              updateNeedToClearCacheFoldersPages(detail.parentId);
              return newResources;
            } else {
              // same parent, different page.
              clearFolderCache(detail.parentId);
            }
          } else {
            // not in the same/current level.
            clearFolderCache(detail.parentId);
            // TODO: in case of admins are in the grandchildren folder of the updated folder. -> refetch navigations
            const foundFolderInBreadcrumbs = breadcrumbs.findIndex((x) => x.id === folderId);
            if (foundFolderInBreadcrumbs) {
              updateBreadcrumbItem({ name: detail.name, id: detail.id });
            }
          }
          return oldResources;
        });
      }
    },
    [
      breadcrumbs,
      selectedFolder?.id,
      selectedFolder?.parentId,
      setResources,
      updateBreadcrumbItem,
      updateNeedToClearCacheFoldersPages,
    ]
  );

  const onResourceAddedToFolder = useCallback(
    async (detail) => {
      // console.log('### onResourceAddedToFolder', detail);
      // NOTE: for other admins, except updator
      if (!detail?.resourceId) return;
      if (detail.triggeredUserInfoId === userInfo?.id) return; // ignore the event triggered by the current user.
      if (detail.resourceFolderId > 0) {
        if (
          detail.resourcetype === ResourceType.cad &&
          detail.resourceFolderId === selectedFolder?.id
        ) {
          await refetch();
          return;
        }

        setResources((oldResources) => {
          let newResourcesState = [...oldResources];
          if (detail.resourceFolderId === selectedFolder?.id) {
            // in the same/current level.
            const foundIndex = oldResources.findIndex((x) => x.resourceId === detail.resourceId);
            if (foundIndex < 0) {
              // add
              const newResource = { ...detail };
              const newResources = formatResources([newResource]);
              newResourcesState = [...newResourcesState, ...newResources];
              // clearFolderCache(detail.resourceFolderId);
            } else {
              informResourceIsUpdated(detail);
              // update
              let updatedResource = { ...detail };
              const updatedResources = formatResources([updatedResource]);
              updatedResource = updatedResources[0];
              newResourcesState[foundIndex] = updatedResource;
            }
            toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
            clearFolderCache(selectedFolder?.parentId);
          } else {
            // not in the same/current level.
            clearFolderCache(detail.resourceFolderId);
            // update the itemsCount
            const folderId = detail.resourceFolderId;
            const foundIndex = oldResources.findIndex((x) => x.id === folderId);
            if (foundIndex >= 0) {
              const foundFolder = _cloneDeep(oldResources[foundIndex]);
              foundFolder.itemsCount += 1;
              newResourcesState[foundIndex] = foundFolder;
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
            }
          }

          return newResourcesState;
        });
      }
    },
    [
      userInfo?.id,
      selectedFolder?.id,
      selectedFolder?.parentId,
      setResources,
      refetch,
      informResourceIsUpdated,
    ]
  );

  const onResourceDeleted = useCallback(
    async (detail) => {
      // NOTE: for both admins and trigger
      console.log('### onResourceDeleted', detail);
      if (!detail?.resourceId) return;
      if (detail.resourceFolderId > 0) {
        const isCurrentFolder = detail.resourceFolderId === selectedFolder?.id;
        setResources((oldResources) => {
          const newResources = [...oldResources];
          if (isCurrentFolder) {
            // delete
            const removedItems = _remove(newResources, (x) => x.resourceId === detail.resourceId);
            if (removedItems.length > 0) {
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
              clearFolderCache(selectedFolder?.parentId);

              const totalPages = Math.ceil(rowCount / pageSize);
              const lastPage = totalPages - 1;
              if (totalPages > 1 && page !== lastPage) {
                clearFolderCacheFromPage(detail.resourceFolderId, page, lastPage);
              } else {
                // case 1: totalPages > 1 && page === lastPage
                // case 2: totalPages === 1
                const newRowCount = rowCount - 1;
                setRowCount(newRowCount);
                if (totalPages > 1) {
                  updateNeedToClearCacheFoldersPages(detail.resourceFolderId);
                }
              }
            } else {
              // same parent, different page.
              clearFolderCache(detail.resourceFolderId);
            }
            if (!newResources?.length && page > 0) {
              const previousPage = page - 1;
              clearFolderCacheAtPage(detail.resourceFolderId, previousPage); // before setPage
              setPage(previousPage);
            }
          } else {
            clearFolderCache(detail.resourceFolderId);
            // update the itemsCount
            const parentFolderId = detail.resourceFolderId;
            const foundIndex = oldResources.findIndex((x) => x.id === parentFolderId);
            if (foundIndex >= 0) {
              const foundFolder = _cloneDeep(oldResources[foundIndex]);
              foundFolder.itemsCount -= 1;
              newResources[foundIndex] = foundFolder;
              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
            }
          }

          return newResources;
        });
        if (isCurrentFolder) {
          // in the same/current level.
          // in case of admins are viewing / editing the deleted resource.
          const isViewingResource = viewingResourceRef.current?.resourceId === detail.resourceId;
          if (isViewingResource) {
            eventBus.publish(EVENT_BUS.ClosePreviewResourceDialog);
          }
          const isEditingResource =
            !!isEditing && formData.current?.resourceId === detail.resourceId;
          // console.log('#### openResourceViewer ', openResourceViewer);
          if (isViewingResource || isEditingResource) {
            let rName = '';
            if (isViewingResource) {
              rName =
                viewingResourceRef.current.resourceDisplayName ||
                viewingResourceRef.current.resourceName ||
                viewingResourceRef.current.name;
            }
            if (isEditingResource) {
              rName = formData.current?.name;
              isEditing.current = false;
              formData.current = null;
            }
            const msg = !rName
              ? 'This resource has been deleted'
              : `The resource ${rName} has been deleted`;
            await ConfirmService.show(i18n.t('RESOURCE DELETED'), msg, i18n.t('Ok'), null, true);
          }
        }
      }
    },
    [
      selectedFolder?.id,
      selectedFolder?.parentId,
      setResources,
      page,
      rowCount,
      pageSize,
      updateNeedToClearCacheFoldersPages,
      setPage,
      viewingResourceRef,
    ]
  );

  const onResourceUpdated = useCallback(
    async (detail) => {
      console.log('### onResourceUpdated', detail);
      // NOTE: for both other admins and trigger
      if (!detail?.resourceId) return;
      if (detail.resourceFolderId > 0) {
        if (detail.resourceFolderId === selectedFolder?.id) {
          // in the same/current level.
          // in case of admins are viewing / editing the updated resource.
          await informResourceIsUpdated(detail);

          setResources((oldResources) => {
            const foundIndex = oldResources.findIndex((x) => x.resourceId === detail.resourceId);
            if (foundIndex >= 0) {
              let updatedResource = { ...detail };
              const updatedResources = formatResources([updatedResource]);
              updatedResource = updatedResources[0];
              const newResources = [...oldResources];
              newResources[foundIndex] = updatedResource;

              toClearCache.current = true; // to clear cache the current folder after gone out, then go back again.
              clearFolderCache(selectedFolder?.parentId);

              updateNeedToClearCacheFoldersPages(detail.resourceFolderId);
              return newResources;
            } else {
              // same parent, different page.
              clearFolderCache(detail.resourceFolderId);
            }

            return oldResources;
          });
        } else {
          // not in the same/current level.
          clearFolderCache(detail.resourceFolderId);
        }
      }
    },
    [
      informResourceIsUpdated,
      selectedFolder?.id,
      selectedFolder?.parentId,
      setResources,
      updateNeedToClearCacheFoldersPages,
    ]
  );

  const handleOnClickMoreButton = useCallback(
    (event, rowData) => {
      setFormData(rowData);
      setActionsMenuAnchorEl(event.target);
    },
    [setFormData]
  );

  const handleOnResourceView = () => {
    handleResourceDoubleClicked(formData.current);
  };

  const handleOnClickRow = useCallback(
    (rowData) => {
      if (rowData.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
        handleFolderSelected(rowData);
      }
    },
    [handleFolderSelected]
  );

  const handleOnDoubleClickRow = useCallback(
    (rowData) => {
      if (rowData.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
        handleFolderSelected(rowData);
      } else if (previewItemInPopup) {
        previewItemInPopup(rowData);
      }
    },
    [handleFolderSelected, previewItemInPopup]
  );

  // Listen messages for file converting
  useSignalRClientMethod(resourceHubConnection, 'ConversionStarted', onConversionStarted);
  useSignalRClientMethod(resourceHubConnection, 'ConversionFinished', onConversionEnd);
  useSignalRClientMethod(resourceHubConnection, 'ConversionFailed', onConversionEnd);
  useSignalRClientMethod(resourceHubConnection, 'TranscodingFinished', onTranscodingFinished);
  // Listen messages for resource folder
  useSignalRClientMethod(resourceHubConnection, 'ResourceFolderDeleted', onResourceFolderDeleted);
  useSignalRClientMethod(resourceHubConnection, 'ResourceFolderAdded', onResourceFolderAdded);
  useSignalRClientMethod(resourceHubConnection, 'ResourceFolderUpdated', onResourceFolderUpdated);
  useSignalRClientMethod(resourceHubConnection, 'ResourceAddedToFolder', onResourceAddedToFolder);
  useSignalRClientMethod(resourceHubConnection, 'ResourceDeleted', onResourceDeleted);
  useSignalRClientMethod(resourceHubConnection, 'ResourceUpdated', onResourceUpdated);

  if (!isGlobalResourcesEnabled) {
    return (
      <div className="global-resources-table global-resources-table-no-data">
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('Enable Global Resources')}
          description={i18n.t(
            'Use global resources for your organization to simply organizing all content in one place'
          )}
        />
      </div>
    );
  }

  function reloadPage() {
    window.location.reload();
  }

  const handleOnSearchChange = (value) => {
    // console.log('### changed keyword', value);
    const newKeyword = value?.toLowerCase()?.trim();
    goToRootItem();
    setSearchText(newKeyword);
  };
  const handleOnUploadedFolder = async () => {
    clearFolderCache(selectedFolder?.parentId);
    await refetch();
  };

  function renderHeader() {
    return (
      <div className="control-wrapper">
        <ResourcesViewControls
          searchText={searchText}
          onSearchChange={handleOnSearchChange}
          canChangeView={!isMovingResources}
        />

        {!isPicker && (
          <AddButton
            onResourceFolderAdded={handleOnFolderAdded}
            onResourceUploaded={handleOnFilesAddedOrUpdated}
            onUploadFolder={handleOnUploadedFolder}
            selectedFolder={selectedFolder}
          />
        )}
      </div>
    );
  }

  function renderNotFound() {
    if (searchText) {
      return (
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('No files or folders found')}
          description={i18n.t('Choose another keyword and try again.')}
        />
      );
    }
    if (isError || data?.totalItems > 0) {
      return (
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('No files or folders to display')}
          buttonComponent={
            <OfficialButton variant="regular-primary" label="Reload" onClick={reloadPage} />
          }
        />
      );
    }
    if (props.isMovingResources || !isPicker) {
      return (
        <NoDaTaBox
          icon={<span className="icon-resources" />}
          title={i18n.t('No files or folders to display')}
          description='Click the "Add" button to upload global resources for your organization.'
          buttonComponent={
            <AddButton
              addFileDisabled={props.isMovingResources}
              onResourceFolderAdded={handleOnFolderAdded}
              onUploadFolder={handleOnUploadedFolder}
              onResourceUploaded={handleOnFilesAddedOrUpdated}
              selectedFolder={selectedFolder}
            />
          }
        />
      );
    }
    return (
      <div className="global-resources-grid">
        <div className="global-resources-table global-resources-table-no-data">
          <NoDaTaBox
            icon={<span className="icon-resources" />}
            title={i18n.t('No files or folders to display')}
            description="Your organization has not uploaded any global resources yet."
          />
        </div>
      </div>
    );
  }

  const renderActionsMenu = () => {
    if (!actionsMenuAnchorEl) {
      return null;
    }
    return (
      <ActionsMenu
        anchorEl={actionsMenuAnchorEl}
        onClose={() => setActionsMenuAnchorEl(null)}
        selectedResource={formData.current}
        currentFolderId={selectedFolder?.id}
        onRenameButtonClick={handleRenameBtnClick}
        onResourceView={handleOnResourceView}
        onUploadNewVersionButtonClick={handleUploadNewVersionBtnClick}
        onDeleteButtonClick={handleOnFileOrFolderDeleted}
        onDownloadFolderClick={handleClickDownloadGlobalDirectory}
      />
    );
  };

  function renderData() {
    if (isLoading) {
      return <GlobalResourcesLoading />;
    }
    return (
      <Suspense fallback={<GlobalResourcesLoading />}>
        <ResourcesDisplayView
          resources={resources}
          processingResources={processingResources}
          onClickMore={isPicker ? null : handleOnClickMoreButton}
          // className={`${noData ? 'global-resources-table-no-data' : ''}`}
          onClickRow={handleOnClickRow}
          onDoubleClickRow={isPicker ? null : handleOnDoubleClickRow}
          multiple={multiple}
          selectType={props.selectType}
          rowCount={rowCount}
          isPortal={props.isPortal}
          accept={accept}
          disabledFolderIds={disabledFolderIds}
        />
      </Suspense>
    );
  }

  function renderActionButtons() {
    console.log('### renderActionButtons', selectedResources);
    if (selectedResources.length === 0 || isPicker) {
      return null;
    }
    return (
      <div className="global-resources-body-left-header">
        <ActionButtons
          onClickCancel={clearSelectedResources}
          onMoveSuccess={clearSelectedResources}
          onDeleteSuccess={clearSelectedResources}
          selectedFolder={selectedFolder}
        />
      </div>
    );
  }

  function renderContent() {
    const noData = !isLoading && resources.length === 0;
    if (noData) {
      return (
        <>
          {searchText && renderHeader()}
          <div className="global-resources-table global-resources-table-no-data">
            {renderNotFound()}
          </div>
        </>
      );
    }
    return (
      <>
        {renderActionButtons()}
        {renderHeader()}
        {renderData()}
        {renderActionsMenu()}
      </>
    );
  }

  return (
    <>
      {renderContent()}
      <ResourceSignalRHandler isPortal onConnected={setResourceHubConnection} />
    </>
  );
});

GlobalResourcesSpceTable.propTypes = {
  isGlobalResourcesEnabled: PropTypes.bool,
  previewItemInPopup: PropTypes.func,
  isPortal: PropTypes.bool,
  multiple: PropTypes.bool,
  selectType: PropTypes.string,
  accept: PropTypes.string,
  disabledFolderIds: PropTypes.instanceOf(Array),
  isMovingResources: PropTypes.bool,
  viewingResourceRef: PropTypes.instanceOf(Object),
};

GlobalResourcesSpceTable.defaultProps = {
  keySpceTable: null,
  multiple: true,
  isMovingResources: false,
};

GlobalResourcesSpceTable.displayName = 'GlobalResourcesSpceTable';

export default GlobalResourcesSpceTable;
