import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import i18n from '../../i18n';
import './ConfirmRemoveBlockDialog.scss';
import OfficialButton from '../../components/OfficialButtons';
import { Ids } from '../../commons/pendoTaggings';

let resolve;
let containerElement;
let rootEl;

class ConfirmRemoveBlockDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      doNotShow: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleHistoryStateChanged = this.handleHistoryStateChanged.bind(this);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount ConfirmRemoveBlockDialog');
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (rootEl) {
      rootEl.unmount(); // Use the root object to unmount the component
      // document.body.removeChild(containerElement); // error when delete a block in cms
    }
    resolve(retVal);
  }

  static show(title, message, showCheckBox, labelCheckBox) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ConfirmRemoveBlockDialog
        title={title}
        message={message}
        showCheckBox={showCheckBox}
        labelCheckBox={labelCheckBox}
        handleCancel={this.handleCancel}
        handleConfirm={this.handleConfirm}
      />
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleCancel() {
    this.setState({ isOpen: false }, () => {
      ConfirmRemoveBlockDialog.destroy();
    });
  }

  handleHistoryStateChanged() {
    this.setState({ isOpen: false }, () => {
      ConfirmRemoveBlockDialog.destroy();
    });
  }

  handleConfirm() {
    this.setState({ isOpen: false }, () => {
      ConfirmRemoveBlockDialog.destroy({
        hasConfirmed: true,
        doNotShow: this.state.doNotShow,
      });
    });
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className="white confirm-dialog remove-block-dialog"
        onClose={this.handleCancel}
      >
        <div className="content">
          {this.props.title && <h1 className="title">{this.props.title}</h1>}
          <span className="message">{this.props.message}</span>
          {this.props.showCheckBox && (
            <div className="option">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      this.setState({ doNotShow: event.target.checked });
                    }}
                    name="checklist"
                  />
                }
                label={this.props.labelCheckBox}
              />
            </div>
          )}
        </div>
        <div className="buttons">
          <OfficialButton
            onClick={this.handleCancel}
            label={i18n.t('cancel')}
            variant="large-secondary"
            dataId={Ids.CancelRemoveBlockCms}
          />
          <OfficialButton
            onClick={this.handleConfirm}
            label={i18n.t('Remove')}
            variant="large-primary"
            dataId={Ids.ConfirmRemoveBlockCms}
          />
        </div>
      </ResponsiveDialog>
    );
  }
}

ConfirmRemoveBlockDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  showCheckBox: PropTypes.bool,
  labelCheckBox: PropTypes.string,
};

export default ConfirmRemoveBlockDialog;
