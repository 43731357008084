import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { goToHomePage, sendNotification } from '../commons/utils';
import DefaultLogo from '../images/spce-logo.png';
import DefaultBackgroundJoinMeeting from '../images/Default_Background_JoinMeeting.jpg';
import queryCache, { CacheKeys, clearCache } from '../app/queryCache';
import { PrivacySpace } from '../app/appConstants';
import Loading from '../components/Loading';
import ResourceSignalRHandler from '../components/ResourceSignalRHandler';
import { getSpaceContext, SpaceContext } from './SpaceContext';
import {
  getSpaceAccessToken,
  // joinSpace,
  // getJoinedSpaceUsers,
  getPublicSpaceAccessToken,
} from './spaceAccessServices';
import spaceUser from './spaceUser';
import SpaceAccessPinDialog from './SpaceAccessPinDialog';
import SpaceSignalRHandler from './SpaceSignalRHandler';
import SpaceNotificationSignalRHandler from './SpaceNotificationSignalRHandler';
import { newDate, parseDateTimeStringFromServer } from '../commons/DateTimeUtils';
import i18n from '../i18n';
import ErrorMessage from '../components/ErrorMessage';
import ButtonEnhanced from '../components/ButtonEnhanced';
// import { useUnmountEffect } from '../commons/CustomHooks';
import useBrowserUtils from '../commons/useBrowserUtils';
import getSpaceFeatures from '../homepage/getSpaceFeatures';
import localUserInfo from '../commons/LocalUserInfo';
import useSpaceNotifications from './useSpaceNotifications';
import { findAndScrollToBlock, mapPagesBlockToBlock } from '../cms/cmsUtils';
import SpaceEmailVerificationDialog from './SpaceEmailVerificationDialog';
import { useAppContext } from '../components/AppProvider';

const SpaceContextProvider = (props) => {
  const {
    children,
    code,
    checksum,
    space,
    connectSignalR,
    connectToResource,
    onParticipantVerified,
    keySectionSignal,
    setKeySectionSignal,
    notiContext,
    visitorId,
    isSpaceDeleted,
    isPreview,
  } = props;
  const { isAuthenticated, isMobile, isMobilePortrait } = useAppContext();
  const navigate = useNavigate();

  const [signalRConnection, setSignalRConnection] = useState(null);
  const [resourceHubConnection, setResourceHubConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(() => {
    if (spaceUser.isSupervisor()) {
      return false;
    }
    const oldAccessToken = queryCache.getQueryData([CacheKeys.getSpaceAccessToken, space.id, code]);
    return !oldAccessToken;
  });
  const [isSpaceUserLoggedIn, setIsSpaceUserLoggedIn] = useState(false);
  const [sidebar, setSidebar] = useState();
  const [theme, setTheme] = useState({
    name: space.name,
    logoImageSrc: space.theme?.logoImageSrc || DefaultLogo,
    backgroundImageSrc: space.theme?.backgroundImageSrc || DefaultBackgroundJoinMeeting,
    primaryColor: space.theme?.mainColor || '#eeeeee',
    secondaryColor: space.theme?.secondaryColor || '#dddddd',
    vibrantColor: space.theme?.vibrantColor || '#cccccc',
    themeColor: space.theme?.themeColor || '#cccccc',
    backgroundImages: space.theme?.backgroundImages || [],
    logos: space.theme?.logos || [],
  }); // Space's theme
  // const [customTheme, setCustomTheme] = useState(space.customTheme); // Space's custom theme
  const [isOpenAddParticipantForm, setIsOpenAddParticipantForm] = useState(false);
  const { newNotifications, setNotifications } = useSpaceNotifications(space.id);
  const [showVaamRecorder, setShowVaamRecorder] = useState(false);
  const [activeChannel, setActiveChannel] = useState(null);
  const [vaamTargetChannel, setVaamTargetChannel] = useState(null);
  const [selectedTargetChannel, setSelectedTargetChannel] = useState(null);
  const [isSendingVaam, setIsSendingVaam] = useState(false);
  const [isVaamFromChat, setIsVaamFromChat] = useState(false);
  const [currentTabInParticipant, setCurrentTabInParticipant] = useState(0);
  const [openedP2PChannels, setOpenedP2PChannels] = useState({});
  const [currentPage, setCurrentPage] = useState(null);
  const [pagesBlock, setPagesBlock] = useState(null);
  const [blockId, setBlockId] = useState(null);

  const { removeURLParams } = useBrowserUtils();
  const spaceId = space.id;
  const isPublicSpace = space.spacePrivacy === PrivacySpace.PUBLIC;
  const isVisitor = spaceUser.isVisitor();

  useEffect(() => {
    let initialBlocks;
    if (space.pagesBlock) {
      setPagesBlock(mapPagesBlockToBlock(space.pagesBlock));
      const firstPage = space.pagesBlock.pages[0];
      initialBlocks = firstPage.blocks ?? [];
      setCurrentPage({
        id: firstPage.id,
        blocks: initialBlocks,
        slug: firstPage.slug,
      });
    } else {
      setPagesBlock(null);
      initialBlocks = space.customTheme?.items;
      setCurrentPage({
        id: null,
        blocks: initialBlocks,
        slug: 'home',
      });
    }
  }, [space.pagesBlock, space.customTheme]);

  useEffect(() => {
    if (currentPage) {
      setPagesBlock((prev) => {
        if (!prev) {
          return null;
        }
        const newPagesBlock = cloneDeep(prev);
        const index = newPagesBlock.items[0].pages.findIndex((page) => page.id === currentPage.id);
        if (index >= 0) {
          const initialBlocks = currentPage.blocks ?? [];
          newPagesBlock.items[0].pages[index].blocks = initialBlocks;
        }
        return newPagesBlock;
      });
    }
  }, [currentPage]);

  const onChangeNavigation = useCallback(
    (activePageIndex) => {
      if (pagesBlock?.items[0].pages) {
        setCurrentPage(pagesBlock?.items?.[0]?.pages[activePageIndex]);
      }
    },
    [pagesBlock?.items]
  );

  const getSpaceAccessTokenQuery = useQuery({
    queryKey: [CacheKeys.getSpaceAccessToken, spaceId, code, checksum],
    queryFn: async () => {
      let resp;
      if (isPublicSpace && !code) {
        resp = await getPublicSpaceAccessToken(spaceId, visitorId);
      } else {
        const localInfo = localUserInfo.getUserInfo();
        resp = await getSpaceAccessToken(
          space.organizationId,
          spaceId,
          code,
          checksum,
          localInfo?.firstName,
          localInfo?.lastName
        );
        if (resp?.hasPinProtection === true) {
          resp = await SpaceAccessPinDialog.show(spaceId, code, space.organizationId);
        }
      }

      return resp;
    },

    retry: 0,
    enabled:
      !spaceUser.isSupervisor() || (spaceUser.isSupervisor() && spaceUser.getSpaceId() !== spaceId),
  });

  useEffect(() => {
    if (!getSpaceAccessTokenQuery.data) {
      return;
    }
    if (getSpaceAccessTokenQuery.isSuccess) {
      const {
        spaceAccessToken,
        spaceAccessSession,
        isValid,
        shareSpaceCode,
        // hasPinProtection,
        isMagicLinkSent,
        isExpired,
      } = getSpaceAccessTokenQuery.data;
      if (isExpired) {
        // const removeParams = ['invc', 'cs', 'drId', 'noti-context'];
        // removeURLParams(removeParams);
        // setIsLoading(false);
        spaceUser.reset();
        setTimeout(() => {
          sendNotification(i18n.t('This link is already used or expired.'), {
            type: 'error',
          });
        }, 1500);
        navigate(location.pathname);
        return;
      }

      if (isMagicLinkSent === true) {
        SpaceEmailVerificationDialog.show(getSpaceAccessTokenQuery?.data?.email);
        return;
      }
      if (!isValid) {
        setIsLoading(false);
        spaceUser.reset();
        return;
      }

      spaceUser.login(spaceAccessToken, spaceAccessSession, code, checksum, shareSpaceCode);
      const removeParams = ['invc', 'cs', 'noti-context'];

      removeURLParams(removeParams);

      setIsLoading(false);
      if (spaceUser.hasLoggedIn()) {
        // joinSpace(spaceUser.getSpaceId());
        setIsSpaceUserLoggedIn(true);
      }
    } else if (getSpaceAccessTokenQuery.isError) {
      spaceUser.reset();
    }
  }, [
    getSpaceAccessTokenQuery.data,
    getSpaceAccessTokenQuery.isSuccess,
    getSpaceAccessTokenQuery.isError,
    code,
    checksum,
    removeURLParams,
    navigate,
  ]);

  const handleResourceHubConnected = useCallback((connection) => {
    setResourceHubConnection(connection);
  }, []);

  const handleOnSpaceHubConnectionReady = useCallback(
    (connection) => {
      console.log('handleSpaceHub ConnectionReady', connection);
      // console.log('handleSpaceHub ConnectionReady', connection?.connectionId);
      if (onParticipantVerified) {
        connection.on('ParticipantVerified', onParticipantVerified);
      }
      function onSpaceStateChanged(message) {
        // console.log('### onSpaceStateChanged', space, message);
        if (message.id === spaceId) {
          if (message.newState === 'InActive' && isAuthenticated) {
            goToHomePage();
            return;
          }
          clearCache([CacheKeys.fetchSpaceAcessInfo], false);
          if (message.newState === 'Open') {
            location.reload();
          }
        }
      }

      connection.on('SpaceStateChanged', onSpaceStateChanged);
    },
    [isAuthenticated, onParticipantVerified, spaceId]
  );

  const handleSpaceHubConnected = useCallback(
    (connection) => {
      console.log('handleSpaceHub Connected', connection);
      // before signalR connected, connectionId is null, but chat need connectionId to join channel
      // so we instead of setting connection on ConnectionReady, we set it on Connectionconnected event
      setSignalRConnection(connection);
      handleOnSpaceHubConnectionReady(connection);
    },
    [handleOnSpaceHubConnectionReady]
  );

  const getFeatureSpace = useCallback(() => {
    const featuresMap = getSpaceFeatures(space?.features);
    // if (featuresMap?.Participants === true) {
    //   // Not show participants for showroom with user role is participant
    //   if (isPortal || (!isPortal && !spaceUser.isHost())) {
    //     featuresMap.Participants = false;
    //   }
    // }
    return featuresMap;
  }, [space?.features]);
  // const updatePagesBlock = useCallback(
  //   (newBlock) => {
  //     if (!pagesBlock) {
  //       return;
  //     }
  //     let newCurrentPage = null;

  //     if (newBlock.isPagesBlock) {
  //       setPagesBlock(newBlock);
  //       setCurrentPage((prev) => {
  //         const foundCurrentPage = newBlock.items[0].pages.find((page) => page.id === prev.id);
  //         console.log('foundCurrentPage: ', foundCurrentPage);
  //         if (foundCurrentPage) {
  //           if (!isEqual(foundCurrentPage, prev)) {
  //             return foundCurrentPage;
  //           }
  //           return prev;
  //         } else {
  //           return newBlock.items[0].pages[0];
  //         }
  //       });
  //     } else {
  //       setCurrentPage((prev) => {
  //         const newBlocks = cloneDeep(prev.blocks);
  //         const findBlockIndex = newBlocks.findIndex((block) => block.id === newBlock.id);
  //         newBlocks[findBlockIndex] = newBlock;
  //         newCurrentPage = { ...prev, blocks: newBlocks };
  //         return newCurrentPage;
  //       });

  //       setPagesBlock((prev) => {
  //         const newPagesBlock = cloneDeep(prev);
  //         const findPageIndex = newPagesBlock.items[0].pages.findIndex(
  //           (page) => page.id === newCurrentPage.id
  //         );

  //         newPagesBlock.items[0].pages[findPageIndex] = newCurrentPage;
  //         return newPagesBlock;
  //       });
  //     }
  //   },
  //   [pagesBlock]
  // );

  const context = getSpaceContext(
    isLoading,
    space,
    signalRConnection,
    theme,
    sidebar,
    spaceUser.getSpaceAccessSession(),
    code,
    isMobile,
    isMobilePortrait,
    getSpaceAccessTokenQuery,
    newNotifications,
    showVaamRecorder,
    activeChannel,
    vaamTargetChannel,
    isSendingVaam,
    isVaamFromChat,
    isSpaceUserLoggedIn,
    notiContext,
    isVisitor,
    currentTabInParticipant,
    isSpaceDeleted,
    selectedTargetChannel,
    isPreview,
    openedP2PChannels,
    pagesBlock,
    currentPage,
    blockId
  );
  context.setSelectedTargetChannel = setSelectedTargetChannel;
  context.onChangeNavigation = onChangeNavigation;
  context.setIsVaamFromChat = setIsVaamFromChat;
  context.setIsSendingVaam = setIsSendingVaam;
  context.setVaamTargetChannel = setVaamTargetChannel;
  context.setActiveChannel = setActiveChannel;
  context.setCurrentTabInParticipant = setCurrentTabInParticipant;
  context.setNotifications = setNotifications;
  context.isOpenAddParticipantForm = isOpenAddParticipantForm;
  context.setIsOpenAddParticipantForm = setIsOpenAddParticipantForm;
  context.setShowVaamRecorder = setShowVaamRecorder;
  context.setTheme = (data) => {
    setTheme({ ...theme, ...data });
  };
  // context.updatePagesBlock = updatePagesBlock;
  // context.setCustomTheme = (data) => {
  //   setCustomTheme({ ...customTheme, ...data });
  // };
  context.setCurrentPage = setCurrentPage;
  context.setPagesBlock = setPagesBlock;
  context.setSidebar = setSidebar;

  context.resourceHubConnection = resourceHubConnection;
  context.goToChat = () => {
    setIsSendingVaam(true);
  };

  context.hasSpaceJustCreated = useCallback(() => {
    if (!space) {
      return false;
    }
    const spaceCreatedDate = parseDateTimeStringFromServer(space.created);
    // console.log('diff space created', newDate().diff(spaceCreatedDate, 'minutes'));
    return newDate().diff(spaceCreatedDate, 'minutes') <= 3;
  }, [space]);

  context.getFeatureSpace = getFeatureSpace;
  context.spaceFeatures = getFeatureSpace();

  context.setOpenedP2PChannels = (channelId, openedTimes) => {
    setOpenedP2PChannels({ ...openedP2PChannels, [channelId]: openedTimes });
  };

  context.setBlockId = setBlockId;

  context.scrollToBlockId = useCallback(
    (dataIds, isEnabledCMSPages) => {
      findAndScrollToBlock(
        dataIds,
        isEnabledCMSPages ? pagesBlock : null,
        currentPage,
        onChangeNavigation,
        setBlockId,
        props.isPortal
      );
    },
    [pagesBlock, currentPage, onChangeNavigation, props.isPortal]
  );

  if (isLoading || !currentPage) {
    return (
      <div className="flex-container">
        <Loading />
      </div>
    );
  }

  const action = () => (
    <ButtonEnhanced fullWidth onClick={() => location.reload()}>
      {i18n.t('Reload')}
    </ButtonEnhanced>
  );

  if (getSpaceAccessTokenQuery.error) {
    return (
      <div className="flex-container">
        <ErrorMessage
          message="Sorry, something went wrong. Please try again!"
          renderActions={action}
        />
      </div>
    );
  }

  // const isOpen = context.space?.state === SpaceState.Open;

  const renderConnectionHub = () => {
    const accessToken = spaceUser.getAccessToken();

    if (!(spaceId && accessToken) || props.isPortal) {
      return null;
    }

    return (
      <>
        {connectSignalR && (
          <>
            <SpaceSignalRHandler
              isAuthenticated={isAuthenticated}
              onConnected={handleSpaceHubConnected}
              key={`space${keySectionSignal}`}
              setKeySectionSignal={setKeySectionSignal}
              spaceUserInfoId={spaceUser?.getUserInfoId()}
            />
            {!isVisitor && <SpaceNotificationSignalRHandler accessToken={accessToken} />}
          </>
        )}
        {connectToResource && (
          <ResourceSignalRHandler
            key={`resources${keySectionSignal}`}
            isPortal={false}
            onConnected={handleResourceHubConnected}
          />
        )}
      </>
    );
  };

  return (
    <SpaceContext.Provider value={context}>
      {children}
      {renderConnectionHub()}
    </SpaceContext.Provider>
  );
};

SpaceContextProvider.propTypes = {
  isPortal: PropTypes.bool,
  connectSignalR: PropTypes.bool,
  connectToResource: PropTypes.bool,
  code: PropTypes.string,
  checksum: PropTypes.string,
  notiContext: PropTypes.string,
  space: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
  onParticipantVerified: PropTypes.func,
  keySectionSignal: PropTypes.string,
  setKeySectionSignal: PropTypes.func,
  visitorId: PropTypes.string,
  isSpaceDeleted: PropTypes.bool,
  isPreview: PropTypes.bool,
};

SpaceContextProvider.defaultProps = {
  isPortal: false,
  connectSignalR: true,
  connectToResource: true,
  space: null,
  onParticipantVerified: null,
};

export default SpaceContextProvider;
