import memoizeOne from 'memoize-one';
import { createFilterOptions } from '@mui/material';
import { ParticipantRoleNames } from '../../app/appConstants';
import { timeUnitsFromNow } from '../../commons/DateTimeUtils';
import i18n from '../../i18n';

const moment = require('moment-timezone');

const currentLocale = moment.locale();
// console.log('### old global locale', currentLocale, typeof currentLocale);
moment.defineLocale('spce-en', {
  parentLocale: 'en',
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    w: '1w',
    ww: '%dw',
    M: '1m',
    MM: '%dm',
    y: '1y',
    yy: '%dy',
  },
});
// console.log('### global locale', moment.locale());
moment.locale(currentLocale); // Reset default moment locale after using `defineLocale`
// console.log('### global locale', moment.locale());

export function getTimeActive(time, isOnline = false, hasActive = true, locale = 'spce-en') {
  if (isOnline) {
    return 'Active now';
  }
  if (!time) {
    return undefined;
  }

  const { date, asDays, days, hours, minutes } = timeUnitsFromNow(time);

  if (asDays > 7) {
    return `${hasActive ? 'Last active' : ''} 7d+ ago`;
  }

  if (minutes === 0 && hours === 0 && days === 0) {
    if (hasActive) {
      return `Last active 1 minute ago`;
    } else {
      return `1 minute ago`;
    }
  }

  date.locale(locale);

  const timeActive = date.fromNow();

  return `${hasActive ? 'Last active' : ''} ${timeActive}`;
}

export function getRoleName(data) {
  if (!data) return '';
  if (data.isAdmin) {
    return i18n.t('Host');
  }

  const role = data.lastRole || data.role;
  switch (role) {
    case ParticipantRoleNames.Host:
      return i18n.t('Host');
    case ParticipantRoleNames.CoHost:
      return i18n.t('CoHost');
    default:
      return '';
  }
}

export function getSortParticipants(participants, order = 'desc', sortBy = 'lastActive') {
  if (!participants) {
    return [];
  }
  const orderMultiplier = order === 'desc' ? -1 : 1;

  return [...participants].sort((a, b) => {
    // Prioritize 'Me'
    if (a.isCurrentParticipant) return -1;
    if (b.isCurrentParticipant) return 1;

    // // Prioritize host
    // const AIsHost = getRoleName(a) === ParticipantRoleNames.Host;
    // const BIsHost = getRoleName(b) === ParticipantRoleNames.Host;
    // if (AIsHost && !BIsHost) return -1;
    // if (BIsHost && !AIsHost) return 1;
    // if (a.isExternal && !b.isExternal) return 1;
    // if (b.isExternal && !a.isExternal) return -1;

    // Sort by 'lastActive' or 'name'
    if (sortBy === 'lastActive') {
      return orderMultiplier * (new Date(a.lastActive) - new Date(b.lastActive));
    } else if (sortBy === 'fullName') {
      return orderMultiplier * b.fullName.localeCompare(a.fullName);
    }

    return 0;
  });
}

export const filter = createFilterOptions({
  matchFrom: 'any',
  limit: 20,
  stringify: (option) => `${option.fullName} ${option.email}`,
});

export const getFilteredItems = memoizeOne((participants, keyword) => {
  if (!keyword) {
    return participants;
  }
  return filter(participants, { inputValue: keyword });
});
