import { CacheKeys, clearCache } from '../app/queryCache';
import Api from '../commons/api';
import { getCoreApi } from '../commons/utils';
// import queryCache from '../app/queryCache';
// import MockApi from '../commons/mockApi';

export async function getLogosFromDomain(domain) {
  console.log('getLogosFromDomain', domain);
  const resp = await Api.get(`${getCoreApi()}/photos/${domain}/logos`);
  // const resp = await MockApi.get(
  //   'https://raw.githubusercontent.com/nclong87/nclong87.github.io/master/mockwebsettings.json'
  // );
  return resp;
}

export async function updateSpace(id, data, shouldClearCache = true) {
  // console.log('### updateSpace before');
  const resp = await Api.put(`${getCoreApi()}/spaces/${id}`, data);
  // console.log('### updateSpace after');
  if (shouldClearCache) {
    clearCache([CacheKeys.getSpaceRuntime], false);
    clearCache([CacheKeys.fetchSpaceInvitation, id], false);
  }
  return resp;
}

export function getSpaceById(spaceId, includedPagesBlocks = false) {
  let url = `${getCoreApi()}/spaces/${spaceId}`;
  if (includedPagesBlocks) {
    url += '?includedPagesBlocks=true';
  }
  return Api.get(url);
}

export function fetchSpacesList(
  keywords,
  sortBy,
  limit = 10,
  offset = 0,
  states = null,
  userInfoIds = null,
  tagIds = null
) {
  let url = `/spaces?limit=${limit}&offset=${offset}`;
  if (keywords) {
    url += `&keywords=${keywords}`;
  }
  if (sortBy) {
    url += `&order=${sortBy}`;
  }
  if (states?.length > 0) {
    states.forEach((s) => {
      url = `${url}&states=${s}`;
    });
  }
  if (userInfoIds?.length > 0) {
    userInfoIds.forEach((u) => {
      url = `${url}&userInfoIds=${u}`;
    });
  }

  if (tagIds?.length > 0) {
    tagIds.forEach((u) => {
      url = `${url}&tagIds=${u}`;
    });
  }
  return Api.get(`${getCoreApi()}${url}`);
}

export function deleteSpace(id) {
  return Api.delete(`${getCoreApi()}/spaces/${id}`);
}
