import React from 'react';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import './style.scss';
import { ISpaceUser } from '../../types/User';
import Tabs, { useTabs } from '../../components/Tabs';
import i18n from '../../i18n';
import SpaceUserAvatar from '../components/SpaceUserAvatar';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import ChatsByUser from './ChatsByUser';
import EngagementByUser from './EngagementUser';
import ParticipantTrafficByUser from './ParticipantTrafficByUser';
import ResourcesByUser from './ResourcesByUser';
import { SPACE_FEATURES } from '../../homepage/getSpaceFeatures';

export const SpaceUserDetailsTab = {
  ENGAGEMENT: 0,
  TRAFFIC: 1,
  RESOURCES: 2,
  CHATS: 3,
  VIDEOMESSAGES: 4,
};

type SpaceUserDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  userInfo: ISpaceUser;
  defaultTab: (typeof SpaceUserDetailsTab)[keyof typeof SpaceUserDetailsTab];
};

function SpaceUserDetailsModal(props: SpaceUserDetailsModalProps) {
  const { users, space } = useSpaceAnalyticsContext();
  const { open, onClose, userInfo, defaultTab } = props;

  const { tabs, changeTab, activeTab } = useTabs(
    [i18n.t('Engagement'), i18n.t('Traffic'), i18n.t('Resources'), i18n.t('Chats')],
    defaultTab
  );

  const isChatEnabled =
    space?.features &&
    space?.features?.findIndex(
      (x: { id: string; enabled: boolean }) => x.id === SPACE_FEATURES.CHAT && x.enabled
    ) > -1;

  if (!isChatEnabled) {
    tabs.splice(3, 1);
  }

  function handleOnChangeTab(value: string) {
    changeTab(value);
  }

  function renderHeader() {
    let isOnline = false;
    let lastActive = null;
    const foundUserInfo = users.find((x) => x.userInfoId === userInfo.userInfoId);
    if (foundUserInfo) {
      isOnline = foundUserInfo.isOnline || false;
      lastActive = foundUserInfo.lastActive;
    }
    if (!lastActive) {
      lastActive = userInfo.lastActive;
    }
    userInfo.isOnline = isOnline;
    userInfo.lastActive = lastActive;

    return (
      <div className="header">
        <SpaceUserAvatar userInfo={userInfo} showActiveStatus />
        <Tabs tabs={tabs} onChange={handleOnChangeTab} variant="tabs-level-2" />
      </div>
    );
  }

  function renderActiveTab() {
    if (activeTab === SpaceUserDetailsTab.ENGAGEMENT) {
      return <EngagementByUser userInfo={userInfo} />;
    }

    if (activeTab === SpaceUserDetailsTab.TRAFFIC) {
      return <ParticipantTrafficByUser userInfo={userInfo} />;
    }

    if (activeTab === SpaceUserDetailsTab.RESOURCES) {
      return <ResourcesByUser userInfo={userInfo} />;
    }

    if (activeTab === SpaceUserDetailsTab.CHATS) {
      return <ChatsByUser userInfo={userInfo} />;
    }

    return null;
  }

  if (!open) {
    return null;
  }

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className="white confirm-dialog space-users-details-modal"
      onClose={onClose}
    >
      {renderHeader()}
      <div className="tab-content">{renderActiveTab()}</div>
    </ResponsiveDialog>
  );
}

export default React.memo(SpaceUserDetailsModal);
