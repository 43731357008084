import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import FilePicker from '../FilePicker';
import {
  getResourceFileType,
  getExternalUrlInfo,
  checkingSkipTranscode,
} from '../../commons/ResourceUtils';
import { sendNotification, newGuid } from '../../commons/utils';
import { Provider, ResourceType, UploadingFileState } from '../../app/appConstants';
import { cancelSessionsId, getResourceInfo } from '../../resources/ResourceServices';
import i18n from '../../i18n';
import './DndFileUploader.scss';
import { DefaultAccept, RetryTimes, validateInput, getWarningMessage, getProgress } from './utils';
import { createUploader, getNewErrorsAndWarnings } from './DndFileUploaderUtils';
import ExternalUrlInput from './ExternalUrlInput';
import FilenameInput from './FilenameInput';
import DndFileUploaderControl from './DndFileUploaderControl';

class DndFileUploader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      progresses: null,
      files: [], // picked files, only valid files
      resources: [], // saved files
      invalidResources: [], // for displaying only
      url: '',
      name: '', // file name
      errors: null, // validation errors
      uploadingState: null,
      retryCounts: null, // a dictionary {} for retrying three times per file.
      warnings: null,
      resource: null,
      validPickedFilesCount: 0,
      hasChangedName: false,
    };
    this.filePickerRef = createRef();
    this.inputResourceNameRef = {};

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRetry = this.onRetry.bind(this);
    this.pickFiles = this.pickFiles.bind(this);
    this.handleResourcesChanged = this.handleResourcesChanged.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputValidation = this.handleInputValidation.bind(this);
    this.handleOnUploadResourceError = this.handleOnUploadResourceError.bind(this);
    this.handleOnFileProgress = this.handleOnFileProgress.bind(this);
    this.handleOnFileSuccess = this.handleOnFileSuccess.bind(this);
    this.handleOnFileError = this.handleOnFileError.bind(this);
    this.handleCancelEditResourceNameClick = this.handleCancelEditResourceNameClick.bind(this);
    this.renderWarningMessage = this.renderWarningMessage.bind(this);
    this.updateErrorsAndWarnings = this.updateErrorsAndWarnings.bind(this);
    this.handleDeleteInvalidResourceClick = this.handleDeleteInvalidResourceClick.bind(this);
    this.handleErrorCases = this.handleErrorCases.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  async handleFetchResource(resourceId) {
    try {
      const foundResource = await getResourceInfo(resourceId, this.props.isPortal);
      if (foundResource) {
        const finalResourceName =
          this.props.overwrittenResourceName || foundResource.displayName || foundResource.name;

        const computedResource = {
          ...foundResource,
          resourceId: foundResource.id,
          fileName: foundResource.name,
          resourceType: foundResource.type,
          name: finalResourceName,
        };
        if (
          foundResource?.isExternal &&
          foundResource?.externalDocumentSettings?.provider !== Provider.Vaam
        ) {
          this.setState({ url: foundResource.src, name: finalResourceName });
        } else {
          this.setState({ resources: [computedResource], name: finalResourceName });
        }
        if (this.props.callOnChangeAfterFetchingResource && this.props.onChange) {
          this.props.onChange([computedResource]);
        }
        this.setState({ resource: foundResource });
      }
    } catch (e) {
      console.log('ERROR', e);
      sendNotification(e.message, { type: 'error' });
    }
  }

  async componentDidMount() {
    const resourceId = this.props.resourceId;
    if (resourceId > 0) {
      await this.handleFetchResource(resourceId);
    }
  }

  async componentDidUpdate(prevProps) {
    const { progresses, uploadingState, files } = this.state;
    const { onUploadingStateChange } = this.props;

    if (uploadingState === UploadingFileState.Started) {
      // console.log('### 113 progresses:', Object.keys(progresses).length, ' - ', files.length);
      const progress = getProgress(progresses, this.state.validPickedFilesCount);
      // console.log('### 113 progress:', progress, progresses, files);
      if (progress === 100) {
        // set timeout to see the "complete" message.
        setTimeout(() => {
          this.setState({
            uploadingState: UploadingFileState.Completed,
            files: [],
            retryCounts: null,
          });
          if (onUploadingStateChange) onUploadingStateChange(UploadingFileState.Completed);
        }, 1800);
      }
    } else if (uploadingState === UploadingFileState.Failed) {
      // do nothing
    } else {
      if (files.length > 0) {
        this.setState({ progresses: null, uploadingState: UploadingFileState.Started });
        if (onUploadingStateChange) onUploadingStateChange(UploadingFileState.Started);
        await this.handleUploadFiles(files);
      }
    }

    if (!prevProps.resourceId && this.props.resourceId > 0 && !this.state.resource) {
      console.log('### componentDidUpdate: ', prevProps.resourceId, this.props.resourceId);
      await this.handleFetchResource(this.props.resourceId);
    }
  }

  componentWillUnmount() {
    if (!this.state.progresses) return;
    const sessionIds = this.state.progresses && Object.keys(this.state.progresses);
    if (sessionIds && this.props.multiple) {
      cancelSessionsId(sessionIds);
    }
  }

  onReset() {
    this.setState({ progresses: null });
  }

  onCancel() {
    console.log('### onCancel', this.state.files, this.uploaders);
    if (this.uploaders) {
      for (let i = 0; i < this.uploaders.length; i += 1) {
        const uploader = this.uploaders[i];
        uploader.cancel();
      }
      const sessionIds = Object.keys(this.state.progresses);
      if (sessionIds.length > 0) {
        cancelSessionsId(sessionIds);
      }
      this.uploaders = null;
      this.setState({
        files: [],
        progresses: null,
        uploadingState: UploadingFileState.Canceled,
        retryCounts: null,
      });
      if (this.props.onUploadingStateChange) {
        this.props.onUploadingStateChange(UploadingFileState.Canceled);
      }
    }
  }

  onRetry() {
    if (!this.state.files?.length) return;

    let newFiles = _cloneDeep(this.state.files);
    // get ids of uploading files which are failed.
    const ids = this.state.files.map((x) => x.uniqueIdentifier);
    const retryCounts = this.state.retryCounts ? _cloneDeep(this.state.retryCounts) : {};

    const needToRetryFiles = this.uploaders.reduce((acc, u) => {
      if (!u.files?.length) return acc;
      // because for now, one uploader only contains one file.
      const f = u.files[0];
      if (ids.includes(f.uniqueIdentifier)) {
        acc.push({
          sessionId: u.opts.sessionId,
          file: f,
          uniqueIdentifier: f.uniqueIdentifier,
        });
      }
      return acc;
    }, []);

    needToRetryFiles.forEach((x) => {
      const id = x.uniqueIdentifier;
      // const sessionId = x.sessionId;
      let count = retryCounts[id];
      if (count >= RetryTimes) {
        // .abort() Abort uploading the file.
        // .cancel() Abort uploading the file and delete it from the list of files to upload.
        x.file.cancel();
        // clear
        delete retryCounts[id];
        newFiles = newFiles.filter((y) => y.uniqueIdentifier !== id); // clear from "files" list.
      } else {
        if (!count) count = 0;
        count += 1;
        retryCounts[id] = count;
      }
    });

    this.uploaders = []; // reset uploaders list

    this.setState({
      retryCounts: retryCounts,
      files: newFiles,
      progresses: null,
      uploadingState: UploadingFileState.NotStarted, // to restart uploading
    });
  }

  handleResourcesChanged(newResources, callback = null) {
    this.setState({ resources: newResources }, () => {
      if (callback) callback();
    });
    if (this.props.onChange) this.props.onChange(newResources);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    const isInsertFromPaste = event.nativeEvent?.inputType === 'insertFromPaste';
    this.setState({ [name]: value }); // be careful, pls make sure have the state named [name]
    const isValid = this.handleInputValidation(event);

    // error
    if (!isValid) {
      if (name === 'name') {
        this.setState({ hasChangedName: !!value?.length });
      }
      if (this.props.onChangeName) {
        this.props.onChangeName();
      }
      return;
    }

    if (this.props.onChange) {
      if (name === 'url') {
        const urlResource = getExternalUrlInfo(value, false);
        let fileName = this.state.name || 'untitled';
        if (urlResource) {
          if (!this.state.name || !this.state.hasChangedName) {
            // Get value of url set for fileName
            fileName = value;
            this.setState({ name: value });
          }
          urlResource.fileName = fileName;
          urlResource.fileDescription = '';
          urlResource.updateNewUrl = !!this.state?.resource?.id && isInsertFromPaste;
        } else {
          if (!this.state.hasChangedName) {
            this.setState({ name: '' });
          }
        }
        if (this.props.onChangeName) {
          this.props.onChangeName();
        }
        this.props.onChange(urlResource ? [urlResource] : []);
      }
      if (name === 'name') {
        this.setState({ hasChangedName: !!value?.length });
        if (this.state.url) {
          const urlResource = getExternalUrlInfo(this.state.url, false);
          if (urlResource) {
            urlResource.fileName = value || 'untitled';
            urlResource.fileDescription = '';
          }
          if (this.props.onChangeName) {
            this.props.onChangeName();
          }
          this.props.onChange([urlResource]);
        } else if (this.state.resources) {
          const newResources = _cloneDeep(this.state.resources);
          const foundResource = newResources.find((r) => r.id === this.props.resourceId);
          if (foundResource) {
            // only update the name of one resource at time.
            const initialName = foundResource.name || foundResource.fileName;
            foundResource.name = value || initialName;
            this.handleResourcesChanged(newResources);
            if (this.props.onChangeName) {
              this.props.onChangeName();
            }
          }
        }
      }
    }
  }

  handleDeleteInvalidResourceClick(removingResource) {
    let newInvalidResources = _cloneDeep(this.state.invalidResources);
    newInvalidResources = newInvalidResources.filter((r) => r.id !== removingResource.id);
    this.setState({ invalidResources: newInvalidResources });
  }

  handleCancelEditResourceNameClick(resource) {
    const newResources = _cloneDeep(this.state.resources);
    const foundIndex = newResources.findIndex(
      (r) =>
        (r.resourceId && r.resourceId === resource.resourceId) ||
        (r.externalId && r.externalId === resource.externalId)
    );
    if (foundIndex >= 0) {
      newResources[foundIndex].isEditing = false;
      // revert the change.
      const ref =
        this.inputResourceNameRef?.[resource.resourceId] ||
        this.inputResourceNameRef?.[resource.externalId];
      if (ref) {
        ref.value = newResources[foundIndex].name;
        ref.blur();
      }
      this.setState({ resources: newResources });
    }
  }

  updateErrorsAndWarnings(validationResult, name, callOnError = true) {
    const { isValid, newErrors, newWarnings } = getNewErrorsAndWarnings(
      this.state.errors,
      this.state.warnings,
      validationResult,
      name
    );

    this.setState({ errors: newErrors, warnings: newWarnings });

    if (this.props.onError && callOnError) {
      this.props.onError(newErrors);
    }

    return isValid;
  }

  handleInputValidation(event, callOnError = true) {
    const { name, value, validationResult } = event.target;
    if (name === 'file' && !!validationResult) {
      const isValid = this.updateErrorsAndWarnings(validationResult, name, callOnError);
      return isValid;
    }
    const validationMethod = this.props.validationMethod || validateInput;
    const result = validationMethod(name, value, this.props);
    const isValid = this.updateErrorsAndWarnings(result, name, callOnError);
    return isValid;
  }

  // multiple resources
  async pickFiles(data) {
    const files = data instanceof FileList ? data : [data];
    this.uploaders = []; // reset uploaders list

    const totalFiles = [...files, ...this.state.resources, ...this.state.invalidResources];
    const isValid = this.handleInputValidation({
      target: { name: 'files', value: totalFiles },
    });
    if (!isValid) return; // error

    const invalidFiles = [];
    const validFiles = [];
    const validationMethod = this.props.validationMethod || validateInput;
    Array.from(files).forEach((file) => {
      const result = validationMethod('file', file, this.props);

      const resourceType = getResourceFileType(file);
      const newFile = file;
      newFile.resourceType = resourceType;
      newFile.fileName = file.name;
      newFile.fileDescription = '';
      newFile.isGlobal = this.props.isGlobal;

      if (resourceType === ResourceType.video) {
        newFile.skipTranscode = checkingSkipTranscode(file.fileName);
      }
      if (!result) {
        validFiles.push(newFile);
      } else {
        newFile.validationResult = result;
        invalidFiles.push(newFile);
      }
    });

    this.setState({
      files: validFiles,
      uploadingState: UploadingFileState.NotStarted,
      validPickedFilesCount: validFiles.length,
    }); // will upload files in the componentDidUpdate function

    if (invalidFiles.length > 0) {
      if (invalidFiles.length === 1 && validFiles.length === 0) {
        const invalidFile = invalidFiles[0];
        this.handleInputValidation({ target: { name: 'file', value: invalidFile } }, false);
        return;
      }

      const newInvalidResources = _cloneDeep(this.state.invalidResources);
      invalidFiles.forEach((file) => {
        const validationResult = file.validationResult;
        // eslint-disable-next-line no-param-reassign
        delete file.validationResult;
        const invalidResource = {
          name: file.name,
          fileName: file.name,
          file,
          isValid: false,
          id: newGuid(),
          validationResult,
        };
        newInvalidResources.push(invalidResource);
      });

      this.setState({ invalidResources: newInvalidResources }); // for displaying only
    }
  }

  async handleUploadFiles(files) {
    if (!files || files.length === 0) return;
    const promises = files.map((f) => this.handleUploadFile(f));
    await Promise.all(promises);
  }

  handleErrorCases(uploadingFile, validationResult, sessionId) {
    if (!uploadingFile || !validationResult) return;

    let newFiles = _cloneDeep(this.state.files);
    newFiles = newFiles.filter((x) => x.uniqueIdentifier !== uploadingFile.uniqueIdentifier); // clear from "files" list.

    const retryCounts = this.state.retryCounts ? _cloneDeep(this.state.retryCounts) : {};
    delete retryCounts[uploadingFile.uniqueIdentifier];

    // case 1: multiple / single, select 1 file, get BE error
    const flag =
      newFiles.length === 0 &&
      this.state.invalidResources.length === 0 &&
      this.state.resources.length === 0;
    if (flag) {
      // console.log('### fileError scenario 1');
      this.updateErrorsAndWarnings(validationResult, 'file');
      this.setState({
        progresses: null,
        files: newFiles,
        uploadingState: UploadingFileState.Canceled,
        retryCounts,
      });
      if (this.props.onUploadingStateChange) {
        this.props.onUploadingStateChange(UploadingFileState.Canceled);
      }
      return;
    }

    // case 1: multiple, select 2 files, 1 file no error, 1 file BE error
    // case 2: multiple, select 2 files, 1 file FE error, 1 file BE error
    // case 3: multiple, select 2/n files, 2/n files BE error

    // push to invalidResources list
    const newInvalidResources = _cloneDeep(this.state.invalidResources);
    const invalidResource = {
      name: uploadingFile.name,
      fileName: uploadingFile.name,
      file: uploadingFile,
      isValid: false,
      id: newGuid(),
      validationResult,
    };
    const foundItem = newInvalidResources.find(
      (x) => x.file.uniqueIdentifier === uploadingFile.uniqueIdentifier
    );
    if (!foundItem) {
      newInvalidResources.push(invalidResource);
    }

    // clear error files from "progresses" list
    let newProgresses = null;
    if (this.state.progresses) {
      newProgresses = _cloneDeep(this.state.progresses);
      if (sessionId) {
        delete newProgresses[sessionId];
      }
    }

    let uploadingState = this.state.uploadingState;
    if (newFiles.length === 0 || this.state.resources.length === 0) {
      uploadingState = UploadingFileState.Canceled; // to show upload btn
    }

    this.setState({
      progresses: newProgresses,
      invalidResources: newInvalidResources,
      files: newFiles,
      uploadingState,
      retryCounts,
    });
    if (this.props.onUploadingStateChange) {
      this.props.onUploadingStateChange(uploadingState);
    }
  }

  handleOnUploadResourceError(error, uploadingFile, sessionId) {
    // upload session error
    if (error && typeof error === 'object') {
      const validationResult = { type: 'common' };
      this.handleErrorCases(uploadingFile, validationResult, sessionId);
    }
  }

  handleOnFileProgress(f, sessionId) {
    const currentProgress = Math.round(f.progress() * 100);
    if (sessionId) {
      this.setState((prev) => {
        const newProgresses = { ...prev.progresses };
        newProgresses[sessionId] = currentProgress;
        return { progresses: newProgresses };
      });
    }
  }

  handleOnFileSuccess(f, message, sessionId, file) {
    const messageObj = JSON.parse(message);
    messageObj.sessionId = sessionId;
    messageObj.name = file.name || messageObj.fileName; //  the original file name || sanitized file name
    // update state
    let newResources;

    this.setState((prev) => {
      if (this.props.multiple) {
        newResources = _cloneDeep(prev.resources);
        newResources.push(messageObj);
      } else {
        newResources = [messageObj];
      }

      let remainingFiles = _cloneDeep(prev.files);
      remainingFiles = remainingFiles.filter((x) => x.uniqueIdentifier !== f.uniqueIdentifier);
      const retryCounts = prev.retryCounts ? _cloneDeep(prev.retryCounts) : {};
      delete retryCounts[f.uniqueIdentifier];
      // console.log('### fileSuccess', this.state.files, f, remainingFiles);
      if (this.props.onChange) this.props.onChange(newResources);
      return { resources: newResources, files: remainingFiles, retryCounts: retryCounts };
    });
  }

  handleOnFileError(f, message, sessionId) {
    console.log('### fileError', f, message);

    let displayError = message || '';
    try {
      // error was returnned from server
      const error = JSON.parse(message);
      if (error) {
        displayError = error.message || error.error;
        if (error.vars === 'FileValidation' || error.data?.category === 'FileValidation') {
          const subCategory = error.data?.subCategory;

          let validationResult = null;
          if (subCategory === 'FileSignature') {
            // check magic number from the server
            validationResult = { type: 'signature', message: displayError };
          } else if (subCategory === 'FileExtension') {
            validationResult = { type: 'unaccepted' };
          } else if (subCategory === 'FileMediaType') {
            validationResult = { type: 'unsupported' };
          }

          this.handleErrorCases(f?.file, validationResult, sessionId);
          return;
        }
      }
    } catch (e) {
      // console.log('### 257 JSON.parse error', e);
    }

    if (displayError) {
      sendNotification(displayError, { type: 'error' });
    }

    this.setState({ uploadingState: UploadingFileState.Failed });
    if (this.props.onUploadingStateChange)
      this.props.onUploadingStateChange(UploadingFileState.Failed);
  }

  async handleUploadFile(file) {
    // check if processing the same file.
    if (this.uploaders?.length > 0) {
      const resumableFiles = this.uploaders.reduce((acc, u) => {
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, ...u.files];
        return acc;
      }, []);
      const foundItem = resumableFiles.find((x) => x.uniqueIdentifier === file.uniqueIdentifier);
      if (foundItem) return; // the file is uploading.
    }
    const { spaceId, isPublicResource, oldResourceId, spacePrivacy, folderId } = this.props;
    const uploader = await createUploader(
      file,
      this.handleOnUploadResourceError,
      this.handleOnFileProgress,
      this.handleOnFileSuccess,
      this.handleOnFileError,
      { spaceId, isPublicResource, oldResourceId, spacePrivacy, folderId }
    );

    // keep uploaders
    if (!this.uploaders) {
      this.uploaders = [];
    }
    this.uploaders.push(uploader);
  }

  renderWarningMessage(field) {
    if (!this.state.warnings) {
      return null;
    }
    return (this.props.renderWarningMessageMethod || getWarningMessage)(
      this.state.warnings,
      field,
      this.props
    );
  }

  renderExternalUrlInput() {
    const { url, name, files, resources, errors, warnings } = this.state;
    const disableUrlInput = files.length > 0 || resources.length > 0;
    return (
      <ExternalUrlInput
        url={url}
        fileName={name}
        errors={errors}
        warnings={warnings}
        canPreview={this.props.canPreview}
        canEditNameSameStep={this.props.canEditNameSameStep}
        urlInputPlaceHolder={this.props.urlInputPlaceHolder}
        fileNameInputPlaceHolder={this.props.fileNameInputPlaceHolder}
        disableUrlInput={disableUrlInput}
        fileNameInputLabel={this.props.fileNameInputLabel}
        urlInputLabel={this.props.urlInputLabel}
        urlInputInfo={this.props.urlInputInfo}
        cropImageElement={this.props.cropImageElement}
        handleInputChange={this.handleInputChange}
        renderWarningMessage={this.renderWarningMessage}
      />
    );
  }

  renderDndFileUploaderControl() {
    return (
      <DndFileUploaderControl
        progresses={this.state.progresses}
        files={this.state.files}
        pickFiles={this.pickFiles}
        spaceId={this.props.spaceId}
        folderId={this.props.folderId}
        errors={this.state.errors}
        uploadingState={this.state.uploadingState}
        resources={this.state.resources}
        invalidResources={this.state.invalidResources}
        url={this.state.url}
        fileInputLabel={this.props.fileInputLabel}
        fileInputInfo={this.props.fileInputInfo}
        uploadBtnLabel={this.props.uploadBtnLabel}
        canPreview={this.props.canPreview}
        canEditNameSameStep={this.props.canEditNameSameStep}
        allowEditNameInput={this.props.allowEditNameInput}
        accept={this.props.accept}
        multiple={this.props.multiple}
        isPortal={this.props.isPortal}
        isGlobalResourcesEnabled={this.props.isGlobalResourcesEnabled}
        hideIntegrationGlobalResource={this.props.hideIntegrationGlobalResource}
        cropImageElement={this.props.cropImageElement}
        unsplashElement={this.props.unsplashElement}
        filePickerRef={this.filePickerRef}
        inputResourceNameRef={this.inputResourceNameRef}
        onGlobalResourcesDialogOpened={this.props.onGlobalResourcesDialogOpened}
        onGlobalResourcesDialogClosed={this.props.onGlobalResourcesDialogClosed}
        handleResourcesChanged={this.handleResourcesChanged}
        handleDeleteInvalidResourceClick={this.handleDeleteInvalidResourceClick}
        handleCancelEditResourceNameClick={this.handleCancelEditResourceNameClick}
        onRetry={this.onRetry}
        onCancel={this.onCancel}
        validPickedFilesCount={this.state.validPickedFilesCount}
        isAdmin={this.props.isAdmin}
        disabledUploadExternalFiles={this.props.disabledUploadExternalFiles}
      />
    );
  }

  render() {
    const { hideUrlInput, hideFileInput, multiple } = this.props;

    if (this.props.resourceId > 0 && !this.props.canEdit) {
      // in case of updating: change file name only
      return (
        <div className="upload-container">
          <div className="upload-area">
            <div className="dnd-file-uploader-control">
              <FilenameInput
                value={this.state.name}
                multiple={multiple}
                errors={this.state.errors}
                fileNameInputPlaceHolder={this.props.fileNameInputPlaceHolder}
                fileNameInputLabel={this.props.fileNameInputLabel}
                handleInputChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="upload-container">
        <FilePicker
          accept={this.props.accept}
          onChange={this.pickFiles}
          ref={this.filePickerRef}
          multiple={multiple}
          validatePowerPointFile={false}
        />
        <div className="upload-area">
          {!hideUrlInput && this.renderExternalUrlInput()}
          {!hideUrlInput && !hideFileInput && (
            <div className="or-divider">
              <hr />
              <span className="or-divider-text">OR</span>
              <hr />
            </div>
          )}
          {!hideFileInput && this.renderDndFileUploaderControl()}
        </div>
      </div>
    );
  }
}

DndFileUploader.propTypes = {
  spaceId: PropTypes.string,
  accept: PropTypes.string,
  hideFileInput: PropTypes.bool,
  hideUrlInput: PropTypes.bool,
  urlInputLabel: PropTypes.string,
  urlInputPlaceHolder: PropTypes.string,
  urlInputInfo: PropTypes.node,
  fileInputLabel: PropTypes.string,
  fileInputInfo: PropTypes.node,
  validationMethod: PropTypes.func,
  renderWarningMessageMethod: PropTypes.func,
  resourceId: PropTypes.number,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  isPublicResource: PropTypes.bool,
  fileNameInputLabel: PropTypes.string,
  fileNameInputPlaceHolder: PropTypes.string,
  isPortal: PropTypes.bool,
  callOnChangeAfterFetchingResource: PropTypes.bool,
  canPreview: PropTypes.bool,
  onUploadingStateChange: PropTypes.func,
  uploadBtnLabel: PropTypes.string,
  cropImageElement: PropTypes.element,
  unsplashElement: PropTypes.element,
  multiple: PropTypes.bool,
  isGlobalResourcesEnabled: PropTypes.bool, // to select global resources.
  oldResourceId: PropTypes.number, // to upload a new version of the resource.
  isGlobal: PropTypes.bool, // to upload global resources.
  canEdit: PropTypes.bool, // to be able to change the resource. By default, only can change the name.
  overwrittenResourceName: PropTypes.string,
  spacePrivacy: PropTypes.string,
  canEditNameSameStep: PropTypes.bool,
  allowEditNameInput: PropTypes.bool,
  onChangeName: PropTypes.func,
  folderId: PropTypes.number,
  onGlobalResourcesDialogOpened: PropTypes.func,
  onGlobalResourcesDialogClosed: PropTypes.func,
  hideIntegrationGlobalResource: PropTypes.bool,
  isAdmin: PropTypes.bool,
  disabledUploadExternalFiles: PropTypes.bool,
};

DndFileUploader.defaultProps = {
  accept: DefaultAccept,
  hideFileInput: false,
  hideUrlInput: false,
  urlInputLabel: i18n.t('Website link'),
  urlInputPlaceHolder: 'www.google.com',
  fileInputLabel: i18n.t('Add file'),
  isPublicResource: false,
  fileNameInputLabel: i18n.t('File name'),
  fileNameInputPlaceHolder: 'www.google.com',
  allowEditNameInput: false,
  isPortal: false,
  callOnChangeAfterFetchingResource: true,
  canPreview: false,
  uploadBtnLabel: i18n.t('browse your device'),
  multiple: false,
  isGlobalResourcesEnabled: false,
  isGlobal: false,
  canEdit: false,
  canEditNameSameStep: true,
  hideIntegrationGlobalResource: false,
  isAdmin: false,
  disabledUploadExternalFiles: false,
};

export default DndFileUploader;
