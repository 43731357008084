/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, useCallback, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
import { lowerCase } from 'lodash';

// import _cloneDeep from 'lodash/cloneDeep';
import { Container, Grid } from '@mui/material';
import { useSpaceContext } from '../spaces/SpaceContext';
import CardPreview from './components/CardPreview';
import VideoPreview from './components/v2/VideoPreview';
import WebContentPreview from './components/WebContentPreview';
import {
  CMS_COMPONENT_TYPE,
  CMS_DEFAULT_DESIGN_SETTING,
  CMS_COMPONENT_STYLE,
} from './cmsConstants';
import BlockUI from '../components/BlockUI';
import ResourceWidget from './components/ResourceWidget';
import './ThemePreview.scss';
import './cmsStyle.scss';
import { getWidthCssClass } from './cmsUtils';
import { isIntegrationResourceDeleted } from '../integrations/integrationResourceUtils';
import ImagePlaceholderSrc from '../images/image-placeholder.png';
import DefaultBackgroundImage from '../images/cms/default-preview.jpeg';
import ScriptPreview from './components/ScriptPreview';
import MenuActionsCms from './components/MenuActionsCms';
import HTMLPreview from './components/HTMLPreview';
import { Tag } from '../components/Tags';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../app/appConstants';
import { getDefaultDesignSettingsByTypeAndTextSize } from './components/designer/functions';
import useWaypoint from '../commons/useWaypoint';
import { getExtensionFromUrl } from '../commons/ResourceUtils';
import { getTheFirstPageInTiffImage } from '../commons/TiffUtils';
import CarouselPreview from './components/CarouselPreview';
import { useAppContext } from '../components/AppProvider';
import { CMS_WIDGET_SUBTYPE } from './cmsConstantsForWidget';
import PagesNavigation from './components/PagesNavigation';

const RowContent = React.memo((props) => {
  const { isTablet, isDesktop } = useAppContext();
  const { visible: shouldDisplay, onEnter } = useWaypoint();
  const {
    selectedBlock,
    onSelectBlock,
    onSelectColumn,
    spaceId,
    row,
    isMobile,
    isPreviewMode,
    onAddButtonOnFloatingMenuClick,
    resources,
    fixed,
  } = props;

  const { space, isPublicSpace, isSpaceUserLoggedIn, currentPage, blockId, setBlockId } =
    useSpaceContext();
  const urlSlug = space?.urlSlug;
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledScriptCms, isEnabledHTMLCms, isEnabledCMSPages] = checkEnabledFeatures([
    FeatureFlagsType.SCRIPT,
    FeatureFlagsType.HTML_CSS,
    FeatureFlagsType.SCRIPT,
  ]);

  const resourceId = row.backgroundImageUrlCropped || row.backgroundImageUrl;
  const materialId = row.backgroundImageUrlCroppedMaterialId || row.backgroundImageUrlMaterialId;
  const hasResource = resourceId > 0 || materialId > 0;
  let isShowingBGImage = false;
  if (row.showBackgroundImage !== undefined && row.showBackgroundImage !== null) {
    isShowingBGImage = !!row.showBackgroundImage;
  } else {
    isShowingBGImage = hasResource;
  }
  const isTheFirstRow = props.rowIndex === 0;
  const isPages = props.rowIndex === -1;
  const backgroundImage = resources?.[resourceId];
  const rowRef = React.useRef(null);

  const [imgSrc, setImgSrc] = React.useState(DefaultBackgroundImage);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await fetch(backgroundImage.url);
        // console.log('### 512 resp: ', resp);
        if (!resp?.ok) {
          console.log('ERROR: Could not fetch tiff image');
        }
        const arrayBuffer = await resp.arrayBuffer();
        const canvas = getTheFirstPageInTiffImage(arrayBuffer);
        if (canvas) {
          const base64PNGUrl = canvas.toDataURL(); // PNG is the default; or "image/jpeg"
          setImgSrc(base64PNGUrl);
        }
      } catch (err) {
        console.log('RowContent getData ERROR: ', err);
      }
    };

    let finalSrc = DefaultBackgroundImage;
    if (isShowingBGImage && backgroundImage) {
      finalSrc = backgroundImage.url;
      if (isIntegrationResourceDeleted(finalSrc)) {
        finalSrc = ImagePlaceholderSrc;
        setImgSrc(finalSrc);
      } else {
        const mExtension = getExtensionFromUrl(backgroundImage.url, backgroundImage.extension);
        const isTiff = mExtension === 'tiff' || mExtension === 'tif';
        if (isTiff) {
          getData();
        } else {
          setImgSrc(finalSrc);
        }
      }
    } else {
      setImgSrc(finalSrc);
    }
  }, [backgroundImage, isShowingBGImage]);

  useEffect(() => {
    if (blockId === row.id) {
      if (rowRef.current) {
        rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setBlockId(null);
    }
  }, [blockId, row.id, setBlockId]);

  const handleOnClickColumn = useCallback(
    (index) => {
      // event.stopPropagation();
      if (selectedBlock && row.id === selectedBlock.id) {
        if (onSelectColumn) {
          onSelectColumn(row, index);
        }
      } else if (onSelectBlock) {
        onSelectBlock(row);
      }
    },
    [row, selectedBlock, onSelectColumn, onSelectBlock]
  );

  const getGridSize = useCallback(
    (items, type) => {
      if (type === CMS_COMPONENT_TYPE.WIDGET && isTablet && items > 1) {
        return 12;
      }
      if ((isTablet || isDesktop) && items === 4) {
        return 6;
      }
      return isMobile ? 12 : 12 / items;
    },
    [isDesktop, isMobile, isTablet]
  );

  function renderWidget(component) {
    let element = null;
    switch (component.settings?.subType) {
      case CMS_WIDGET_SUBTYPE.RESOURCES:
        element = (
          <ResourceWidget
            {...component}
            spaceId={spaceId}
            rowId={row.id}
            canPin={props.canPinResource}
            isMobile={isMobile}
            isPortal={props.isPortal}
            isEdit={props.isEdit}
          />
        );
        break;
      default:
        break;
    }
    return (
      <Container maxWidth={getWidthCssClass(component.size)} disableGutters={!!component.size}>
        {element}
      </Container>
    );
  }

  function renderComponent(component, index) {
    if (!component) {
      return null;
    }

    if (component.type === CMS_COMPONENT_TYPE.SCRIPT && !isEnabledScriptCms) {
      return null;
    }

    if (component.type === CMS_COMPONENT_TYPE.HTML_CONTENT && !isEnabledHTMLCms) {
      return null;
    }

    const textSize =
      (component.type === CMS_COMPONENT_TYPE.CAROUSEL
        ? component.items?.[0]?.textSize
        : component.textSize) || CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE;
    const designSettings = getDefaultDesignSettingsByTypeAndTextSize(
      space?.theme,
      component.type,
      textSize,
      row.layout
    );

    let modifiedComponent = { ...component };
    if (component.type === CMS_COMPONENT_TYPE.CAROUSEL) {
      modifiedComponent.items = (modifiedComponent.items || []).map((x) => {
        return { ...designSettings, ...x };
      });
    } else {
      modifiedComponent = { ...designSettings, ...component };
    }
    if (component.type === CMS_COMPONENT_TYPE.PAGES) {
      const blockBackgroundColor =
        space?.theme?.blockBackgroundColor || CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_COLOR;
      modifiedComponent = {
        ...modifiedComponent,
        backgroundColor: row.backgroundColor || blockBackgroundColor,
      };
    }
    modifiedComponent = {
      ...modifiedComponent,
      isEnabledCMSPages,
    };

    // padding for ScriptPreview
    const paddingTop = row.topPaddingSize || CMS_DEFAULT_DESIGN_SETTING.TOP_PADDING_SIZE;
    const paddingRight = row.rightPaddingSize || CMS_DEFAULT_DESIGN_SETTING.RIGHT_PADDING_SIZE;
    const paddingBottom = row.bottomPaddingSize || CMS_DEFAULT_DESIGN_SETTING.BOTTOM_PADDING_SIZE;
    const paddingLeft = row.leftPaddingSize || CMS_DEFAULT_DESIGN_SETTING.LEFT_PADDING_SIZE;

    const defaultPaddingStyle = {
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
    };

    const rowId = row.id;
    const isRowActive =
      selectedBlock && rowId === selectedBlock.id && selectedBlock?.items?.length > 1;
    const isColumnActive = isRowActive && props.workingData?.currentTab === index;

    let element = null;
    switch (component.type) {
      case CMS_COMPONENT_TYPE.CARD_TEXT:
      case CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT:
      case CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY:
      case CMS_COMPONENT_TYPE.CARD_HERO:
        element = (
          <CardPreview
            {...modifiedComponent}
            index={index}
            columns={row.columns}
            isMobile={isMobile}
            size={row.size}
            isPortal={props.isPortal}
            spaceId={spaceId}
            urlSlug={urlSlug}
            onClick={handleOnClickColumn}
            isRowActive={isRowActive}
            isColumnActive={isColumnActive}
            resources={resources}
            shouldDisplay={shouldDisplay}
            isEdit={props.isEdit}
            isPreviewMode={isPreviewMode}
          />
        );
        break;
      case CMS_COMPONENT_TYPE.VIDEO:
        element = (
          <VideoPreview
            {...modifiedComponent}
            index={index}
            columns={row.columns}
            size={row.size}
            isMobile={isMobile}
            isPortal={props.isPortal}
            onClick={handleOnClickColumn}
            isRowActive={isRowActive}
            isEdit={props.isEdit}
            isColumnActive={isColumnActive}
            isSpaceUserLoggedIn={isSpaceUserLoggedIn}
            spaceId={spaceId}
            urlSlug={urlSlug}
            layout={row.layout}
            resources={resources}
            shouldDisplay={shouldDisplay}
            isPreviewMode={isPreviewMode}
          />
        );
        break;
      case CMS_COMPONENT_TYPE.CAROUSEL:
        element = (
          <CarouselPreview
            {...modifiedComponent}
            isMobile={isMobile}
            isPortal={props.isPortal}
            isEdit={props.isEdit}
            spaceId={spaceId}
            urlSlug={urlSlug}
            resources={resources}
            shouldDisplay={shouldDisplay}
            isPreviewMode={isPreviewMode}
          />
        );
        break;
      case CMS_COMPONENT_TYPE.WIDGET:
        element = renderWidget(modifiedComponent);
        break;
      case CMS_COMPONENT_TYPE.WEB_CONTENT:
        element = (
          <WebContentPreview {...modifiedComponent} columns={row.columns} isMobile={isMobile} />
        );
        break;
      case CMS_COMPONENT_TYPE.SCRIPT:
        element = (
          <ScriptPreview
            id={rowId}
            key={row.key}
            useStyle={modifiedComponent.useStyle}
            script={modifiedComponent.script}
            isPortal={props.isPortal && !isPreviewMode}
            paddingStyle={defaultPaddingStyle}
          />
        );
        break;
      case CMS_COMPONENT_TYPE.HTML_CONTENT: {
        const containerId = `row-${rowId}-html-${modifiedComponent.id}`;
        // console.log('### modifiedComponent', modifiedComponent, containerId);
        element = (
          <HTMLPreview
            containerId={containerId}
            htmlContent={modifiedComponent.htmlContent}
            isPortal={props.isPortal && !isPreviewMode}
            paddingStyle={defaultPaddingStyle}
            spaceId={spaceId}
            urlSlug={urlSlug}
          />
        );
        break;
      }
      case CMS_COMPONENT_TYPE.PAGES: {
        let defaultTab;
        if (currentPage) {
          const indexPage = modifiedComponent.pages.findIndex((x) => x.id === currentPage.id);
          if (indexPage >= 0) {
            defaultTab = indexPage;
          }
        }
        element = (
          <PagesNavigation
            {...modifiedComponent}
            index={index}
            defaultTab={defaultTab}
            isMobile={isMobile}
            size={row.size}
            isPortal={props.isPortal}
            onClick={handleOnClickColumn}
            isRowActive={isRowActive}
            isColumnActive={isColumnActive}
            resources={resources}
            shouldDisplay={shouldDisplay}
          />
        );
        break;
      }
      default:
        break;
    }

    const gridSize = getGridSize(row.columns, component.type);
    // const horizontalPadding = row.columns > 1 ? { paddingLeft: '48px' } : {};
    return (
      <Grid item xs={gridSize} key={component.id}>
        {element}
      </Grid>
    );
  }

  const handleOnClickRow = useCallback(
    (event) => {
      event.stopPropagation();
      if (onSelectBlock) {
        onSelectBlock(row);
      }
    },
    [onSelectBlock, row]
  );

  const getPaddings = useCallback(() => {
    const type = row.items?.[0]?.type;

    let paddingTop = row.topPaddingSize || CMS_DEFAULT_DESIGN_SETTING.TOP_PADDING_SIZE;
    let paddingRight = row.rightPaddingSize || CMS_DEFAULT_DESIGN_SETTING.RIGHT_PADDING_SIZE;
    let paddingBottom = row.bottomPaddingSize || CMS_DEFAULT_DESIGN_SETTING.BOTTOM_PADDING_SIZE;
    let paddingLeft = row.leftPaddingSize || CMS_DEFAULT_DESIGN_SETTING.LEFT_PADDING_SIZE;
    let cssClass = '';

    if (
      type === CMS_COMPONENT_TYPE.SCRIPT ||
      type === CMS_COMPONENT_TYPE.HTML_CONTENT ||
      (isPages && isMobile)
    ) {
      paddingTop = '0px';
      paddingBottom = '0px';
    }

    if (isPages) {
      if (row.topPaddingSize === '0px') {
        cssClass += ' no-padding-top';
      }
      if (row.bottomPaddingSize === '0px') {
        cssClass += ' no-padding-bottom';
      }
    }

    // Image and text under with Large image
    const hasNoPaddingTopTextUnder =
      type === CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT &&
      row.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;

    // Image and text overlay with large image or banner
    const hasNoPaddingTopTextOverlay =
      type === CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY &&
      (row.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH ||
        row.size === CMS_COMPONENT_STYLE.SIZE.BANNER);

    // Slideshow
    const hasNoPaddingTopSlideshow = type === CMS_COMPONENT_TYPE.CAROUSEL;

    // Video with Large size
    let hasNoPaddingTopVideo = false;
    if (type === CMS_COMPONENT_TYPE.VIDEO && row.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH) {
      const videoLayout = row.layout;
      // console.log('### 143 videoLayout: ', videoLayout);
      const isOverlay = !videoLayout || videoLayout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY;
      hasNoPaddingTopVideo = isOverlay || videoLayout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER;
    }

    const hasNoPaddingTop =
      isTheFirstRow &&
      row.columns === 1 &&
      (hasNoPaddingTopTextUnder ||
        hasNoPaddingTopTextOverlay ||
        hasNoPaddingTopSlideshow ||
        hasNoPaddingTopVideo);

    if (hasNoPaddingTop) {
      paddingTop = '0px';
      cssClass = 'no-padding-top';
    }

    const isSmall = !!row.size && row.size === CMS_COMPONENT_STYLE.SIZE.MEDIUM && row.columns === 1; // has the size is Small (value is medium)

    const disableGutters =
      type === CMS_COMPONENT_TYPE.CAROUSEL ||
      (!!row.size &&
        (row.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH ||
          row.size === CMS_COMPONENT_STYLE.SIZE.BANNER) &&
        row.columns === 1);

    if (disableGutters) {
      if (!row.rightPaddingSize) {
        paddingRight = '0px';
      }
      if (!row.leftPaddingSize) {
        paddingLeft = '0px';
      }
      if (isMobile) {
        paddingLeft = '0px';
        paddingRight = '0px';
      }
    } else if (isMobile) {
      paddingLeft = '16px';
      paddingRight = '16px';
    } else if (isSmall) {
      if (!row.rightPaddingSize) {
        paddingRight = '240px';
      }
      if (!row.leftPaddingSize) {
        paddingLeft = '240px';
      }
      if (isTablet) {
        paddingRight = '127px';
        paddingLeft = '127px';
      }
    }

    const paddingStyle = {
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
    };

    return { paddingStyle, cssClass };
  }, [
    isMobile,
    isTablet,
    isPages,
    isTheFirstRow,
    row.bottomPaddingSize,
    row.columns,
    row.items,
    row.layout,
    row.leftPaddingSize,
    row.rightPaddingSize,
    row.size,
    row.topPaddingSize,
  ]);

  function renderRow() {
    const isHidden = row?.hidden === true;
    const type = row.items?.[0]?.type;
    const isWidget = type === CMS_COMPONENT_TYPE.WIDGET;
    // console.log('### ThemePreview renderRow', isWidget, row);
    const blockClassName = lowerCase(type) || 'pages';

    // Hide  Participants,  Chat for public Space
    if (
      isPublicSpace &&
      isWidget &&
      row.items?.[0]?.settings?.subType !== CMS_WIDGET_SUBTYPE.RESOURCES
    ) {
      return null;
    }

    if (isHidden && (!props.isEdit || isPreviewMode)) {
      return <div id={row.id} style={{ display: 'none' }} data-is-hidden />;
    }

    // const { imageRatio, imageRatioClass } = getWidgetBackgroundImageRatio(
    //   row.items?.length,
    //   isMobile
    // );
    // const defaultImage = imageRatio === '2:1' ? DefaultBackgroundImage21 : DefaultBackgroundImage;

    // console.log('### ThemePreview renderRow', isShowingBGImage, backgroundImage, row);
    let style = null;
    let parentStyle = null;
    let spacing = row.columns === 1 ? 0 : 6;

    if (isShowingBGImage) {
      style = {
        backgroundImage: `url('${imgSrc}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
      };
      parentStyle = {
        paddingTop: '50px',
        paddingBottom: '50px',
      };
      // Space widget with background
      if (isTheFirstRow && isWidget) {
        parentStyle.paddingTop = '0px';
      }
    } else {
      const blockBackgroundColor =
        space?.theme?.blockBackgroundColor || CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_COLOR;
      parentStyle = {
        backgroundColor: row.backgroundColor || blockBackgroundColor,
      };
    }

    const isSelecting = selectedBlock?.id === row.id;
    const selectedCssClass = isSelecting ? 'selected' : '';
    const gapCssClass = isMobile ? 'gap24' : '';
    const disableGutters =
      type === CMS_COMPONENT_TYPE.CAROUSEL ||
      (!!row.size && row.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH && row.columns === 1);

    const { paddingStyle, cssClass } = getPaddings();
    // console.log('### ', row, paddingStyle);
    style = { ...paddingStyle, ...style };

    if (!isMobile && isWidget && row.items?.length > 2) {
      spacing = 6;
    }

    const boxShadowBorderCssClassForWidget =
      !isPreviewMode && props.isEdit && isWidget && isShowingBGImage && imgSrc
        ? 'box-shadow-border'
        : '';

    const shouldDisplayMenuAction =
      !isPreviewMode && props.isEdit && selectedBlock && row.id === selectedBlock.id;

    return (
      <div
        ref={rowRef}
        className={props.isEdit ? 'row-container-margin-top' : ''}
        style={{ position: 'relative' }}
      >
        {shouldDisplayMenuAction && (
          <MenuActionsCms
            key={row.id}
            onAddButtonClick={onAddButtonOnFloatingMenuClick}
            currentBlock={row}
            canCopyPasteBlock
            isPagesNavigation={isPages}
          />
        )}

        <div
          id={row.id} // for scrolling to
          // key={row.id}
          style={parentStyle}
          className={`cms-theme-preview-row ${selectedCssClass} cms-block-${blockClassName} ${
            fixed && 'fixed'
          }`}
          onClick={handleOnClickRow}
        >
          {/* {type === CMS_COMPONENT_TYPE.WIDGET && isShowingBGImage ? (
            <div className={`positionRelative ${imageRatioClass}`}>
              <Container
                className="cms-theme-preview-row-container positionAbsolute position0"
                maxWidth="xl"
                disableGutters={disableGutters}
                style={style}
              >
                <Grid
                  className={`cms-theme-preview-row-grid ${gapCssClass}`}
                  container
                  spacing={spacing}
                >
                  <Suspense fallback={<BlockUI display />}>
                    {row.items.map((component, index) => renderComponent(component, index))}
                  </Suspense>
                </Grid>
              </Container>
            </div>
          ) : (
            <Container
              className="cms-theme-preview-row-container"
              maxWidth="xl"
              disableGutters={disableGutters}
              style={style}
            >
              <Grid
                className={`cms-theme-preview-row-grid ${gapCssClass}`}
                container
                spacing={spacing}
              >
                <Suspense fallback={<BlockUI display />}>
                  {row.items.map((component, index) => renderComponent(component, index))}
                </Suspense>
              </Grid>
            </Container>
          )} */}
          {isWidget ? (
            <div style={style} className={`widget-wrapper ${boxShadowBorderCssClassForWidget}`}>
              <Container className="cms-theme-preview-row-container" maxWidth={'xl'} disableGutters>
                <Grid
                  className={`cms-theme-preview-row-grid ${gapCssClass} ${
                    !isMobile ? 'widget-grid' : ''
                  } widget-grid-${row.items.length}`}
                  container
                  spacing={spacing}
                >
                  <Suspense fallback={<BlockUI display />}>
                    {row.items?.map((component, index) => renderComponent(component, index))}
                  </Suspense>
                </Grid>
              </Container>
            </div>
          ) : (
            <Waypoint
              onEnter={onEnter}
              fireOnRapidScroll={false}
              debug={false}
              bottomOffset={'-200px'}
            >
              <Container
                className={`cms-theme-preview-row-container ${cssClass}`}
                maxWidth={disableGutters ? false : 'xl'}
                disableGutters={disableGutters}
                style={style}
              >
                <Grid
                  className={`cms-theme-preview-row-grid ${gapCssClass}`}
                  container
                  spacing={spacing}
                >
                  <Suspense fallback={<BlockUI display />}>
                    {row.items?.map((component, index) => renderComponent(component, index))}
                  </Suspense>
                </Grid>
              </Container>
            </Waypoint>
          )}

          {isHidden && (
            <div className="cms-theme-preview-row-hidden-block">
              <Tag color="#fff" backgroundColor="#4783FF">
                Hidden Block
              </Tag>
            </div>
          )}
        </div>
      </div>
    );
  }

  return renderRow();
});

RowContent.propTypes = {
  // onSelectBlock: PropTypes.func,
  // selectedBlock: PropTypes.instanceOf(Object),
  // spaceId: PropTypes.string,
  canPinResource: PropTypes.bool,
  isPortal: PropTypes.bool,
  // row: PropTypes.instanceOf(Object),
  isEdit: PropTypes.bool,
  rowIndex: PropTypes.number,
  // onSelectColumn: PropTypes.func,
  workingData: PropTypes.instanceOf(Object),
  // isMobile: PropTypes.bool,
  // isPreviewMode: PropTypes.bool,
  // onAddButtonOnFloatingMenuClick: PropTypes.func,
  // resources: PropTypes.instanceOf(Object),
  // fixed: PropTypes.bool,
};

RowContent.displayName = 'RowContent';

export default RowContent;
