import React, { lazy, useState, useMemo, useEffect } from 'react';
// import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import queryCache, { CacheKeys } from '../app/queryCache';
import { getRuntimeTheme } from '../theme';
import ErrorMessage from '../components/ErrorMessage';
import { getUrlParameter, uuidv4, sendNotification } from '../commons/utils';
import useSpaceAcessInfoQuery from './useSpaceAcessInfoQuery';
import SpaceContainer from './SpaceContainer';
import SpaceUserSignup from './SpaceUserSignup';
import SpaceContextProvider from './SpaceContextProvider';
import spaceUser from './spaceUser';
import './SpaceAccessContainer.scss';
import useGoogleFontsQuery from '../cms/commons/FontSelect/GoogleFontsQuery';
import { SpaceState, PrivacySpace } from '../app/appConstants';
import AutoSigninProcessor from './AutoSigninProcessor';
import currentOrganization from '../commons/CurrentOrganization';
import RedirectToNewSpace from './RedirectToNewSpace';
import i18n from '../i18n';
import LoadFontsOrganization from '../components/LoadFontsOrganization';
import { SpaceContext } from './SpaceContext';
import DeletedSpaceDialog from '../homepage/DeletedSpaceDialog';
import NoAccessSpaceTemplateDialog from '../homepage/NoAccessTemplateDialog';
import authService from '../components/api-authorization/AuthorizeService';
import SpaceAnalyticsHandler from '../homepage/SpaceAnalyticsHandler';
import FeatureFlagsContextProvider from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { useAppContext } from '../components/AppProvider';
import ChatContextProvider from './Comments/ChatContextProvider';

const NotificationSetting = lazy(() => import('./notification/NotificationSetting'));

const AppRuntimeContainer = (props) => {
  const { isMobileLandscape } = useAppContext();

  const className = clsx('app app-runtime', {
    landscape: isMobileLandscape,
  });
  return <div className={className}>{props.children}</div>;
};
const notiContext = getUrlParameter('noti-context');

const SpaceAccessContainer = (props) => {
  // const [error] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { urlSlug } = useParams();
  const { isAuthenticated, userInfo } = useAppContext();
  const isAdmin = currentOrganization.isAdmin();

  let invitationCode = getUrlParameter('invc', '');
  if (!invitationCode) {
    invitationCode = spaceUser.getInvc();
  }

  let checksum = getUrlParameter('cs');
  if (!checksum) {
    checksum = spaceUser.getChecksum();
  }

  const isNotificationSetting = getUrlParameter('type', '') === 'notification';

  const isViewOnly = sessionStorage.getItem('isViewOnly') === '1';

  const [keySectionSignal, setKeySectionSignal] = useState(uuidv4());

  const { getSpaceAcessInfoQuery, visitorId } = useSpaceAcessInfoQuery(urlSlug);

  // const isPortal = false;
  const isLoading = getSpaceAcessInfoQuery.isLoading || isAuthenticated === null;
  const spaceTemplate = getSpaceAcessInfoQuery.data?.spaceTemplate;
  const spaceCreator = location?.state?.spaceCreator || '';
  const spaceTemplateId = spaceTemplate?.id;
  const templateCreatedByUserInfoId = spaceTemplate?.createdByUserInfoId;
  const currentSlug = spaceUser.getUrlSlugSpace(urlSlug);

  const space = useMemo(() => {
    if (!getSpaceAcessInfoQuery.data) {
      return null;
    }
    return {
      ...getSpaceAcessInfoQuery.data.space,
      termsAndConditions: getSpaceAcessInfoQuery.data.termsAndConditions,
      isSpaceDeleted: getSpaceAcessInfoQuery.data.space === null,
      templateRefId: spaceTemplateId,
      templateCreatedByUserInfoId: templateCreatedByUserInfoId,
    };
  }, [getSpaceAcessInfoQuery.data, spaceTemplateId, templateCreatedByUserInfoId]);

  const theme = space?.theme;

  const spaceOwner = getSpaceAcessInfoQuery.data?.owner;

  useGoogleFontsQuery();

  useEffect(() => {
    if (isViewOnly && !isLoading) {
      spaceUser.setViewOnly(1);
      sendNotification(
        <>
          {i18n.t('Please note that you enter in view-only mode')},
          <br />
          {i18n.t('since this space belongs to another user.')}
          <br />
          <span className="opacity60">{spaceCreator}</span>
        </>,
        { type: 'info', duration: 6000 }
      );
    }
    return () => {
      if (isViewOnly && !isLoading) {
        spaceUser.reset();
      }
      queryCache.removeQueries({
        queryKey: [CacheKeys.getSpaceAccessToken, spaceUser.getSpaceId(), spaceUser.getInvc()],
        exact: true,
      });
    };
  }, [isViewOnly, spaceCreator, isLoading]);

  useEffect(() => {
    async function checkOrganizationCorrectly() {
      if (
        space &&
        !!currentOrganization.getOrganizationId() &&
        currentOrganization.getOrganizationId() !== space.organizationId &&
        isAuthenticated &&
        !space?.isSpaceDeleted
      ) {
        await authService.signOut(`${window.location.pathname}${window.location.search}`);
      }
    }
    checkOrganizationCorrectly();
  }, [isAuthenticated, space]);

  const isAccessToNewSpace = !!currentSlug && currentSlug !== urlSlug;
  const hasCustomUrlAndSubDomain =
    space?.urlSlug && urlSlug !== space?.urlSlug && !isAccessToNewSpace;

  const urlSlugValue = hasCustomUrlAndSubDomain ? space?.urlSlug : urlSlug;

  if (isAccessToNewSpace || hasCustomUrlAndSubDomain) {
    return <RedirectToNewSpace urlSlug={urlSlugValue} clearCache={isAccessToNewSpace} />;
  }

  function handleChangeKeySectionSignal() {
    // Change key to reload signal connection
    const newKey = uuidv4();
    setKeySectionSignal(newKey);
  }
  const isTemplate = !!spaceTemplateId;

  const isSpaceDeleted =
    space?.isSpaceDeleted || (spaceTemplate && spaceTemplate?.isActive === false);

  if (isSpaceDeleted) {
    return (
      <AppRuntimeContainer>
        <DeletedSpaceDialog />
      </AppRuntimeContainer>
    );
  }

  if (isLoading) {
    return null;
  }

  if (isTemplate && !isAdmin) {
    return (
      <AppRuntimeContainer>
        <NoAccessSpaceTemplateDialog spaceOwner={spaceOwner?.contactEmail} />
      </AppRuntimeContainer>
    );
  }

  if (!space) {
    return (
      <AppRuntimeContainer>
        <ErrorMessage message="Sorry, We're sorry we couldn't find that space. Please contact administration!" />
      </AppRuntimeContainer>
    );
  }
  // console.log('debug123 invitationCode', invitationCode);
  // console.log('debug123 spaceUser.isSupervisor()', spaceUser.isSupervisor());
  const isPublicSpace = space.spacePrivacy === PrivacySpace.PUBLIC;
  const isSpaceClosed = space?.state === SpaceState.Closed && !space?.isTemplate;

  const isAutoSignIn =
    (!isTemplate && (!isPublicSpace || (isPublicSpace && isAuthenticated))) || isTemplate;

  if (
    isAutoSignIn &&
    !invitationCode &&
    !spaceUser.isSupervisor() &&
    isAuthenticated === true &&
    currentOrganization.getOrganizationId() === space?.organizationId &&
    !!userInfo
  ) {
    console.log(
      '@@@: AutoSigninProcessor: ',
      isAutoSignIn,
      isAuthenticated,
      invitationCode,
      spaceUser.isSupervisor()
    );

    return (
      <AppRuntimeContainer>
        <AutoSigninProcessor
          userInfo={userInfo}
          isTemplate={!!spaceTemplateId}
          space={space}
          isAdmin={isAdmin}
        />
      </AppRuntimeContainer>
    );
  }

  const isSpaceAccessible = isSpaceClosed || isSpaceDeleted;
  if (!isSpaceAccessible && location?.state?.spaceUserHasBeenDeleted) {
    let msg = i18n.t('You have been removed from the space.');
    if (space?.isPrivate) {
      msg = i18n.t('You have been removed from the space. Please request access from host.');
    }
    setTimeout(() => {
      sendNotification(msg, { type: 'info' });
      const newState = { ...location.state };
      delete newState.spaceUserHasBeenDeleted;
      navigate(location.pathname, {
        state: newState,
        replace: true,
      });
    }, 100);
  }

  const fullAccessToSpace = isAdmin && isAuthenticated;
  console.log('fullAccessToSpace: ', fullAccessToSpace, invitationCode, isAuthenticated);

  if (!invitationCode && !spaceUser.isSupervisor() && !isPublicSpace && !isAuthenticated) {
    return (
      <SpaceContextProvider
        code={space?.id === spaceUser.getSpaceId() ? spaceUser.getInvc() : invitationCode}
        space={space}
        connectSignalR={false}
        connectToResource={false}
      >
        <SpaceContext.Consumer>
          {({ isSpaceUserLoggedIn }) => (
            <FeatureFlagsContextProvider organizationId={space?.organizationId}>
              <ThemeProvider theme={getRuntimeTheme(theme)}>
                <CssBaseline />
                <LoadFontsOrganization isReady={isSpaceUserLoggedIn} includeDeletedFonts />
                <AppRuntimeContainer>
                  <SpaceContainer
                    spaceOwner={spaceOwner}
                    element={
                      !isSpaceAccessible ? (
                        <SpaceUserSignup space={space} urlSlug={urlSlug} navigate={navigate} />
                      ) : null
                    }
                  />
                </AppRuntimeContainer>
              </ThemeProvider>
            </FeatureFlagsContextProvider>
          )}
        </SpaceContext.Consumer>
      </SpaceContextProvider>
    );
  }

  function renderPages() {
    if (isNotificationSetting) {
      return <NotificationSetting space={space} />;
    }
    return (
      <ChatContextProvider spaceId={space?.id}>
        <SpaceContainer spaceOwner={spaceOwner} />
      </ChatContextProvider>
    );
  }

  return (
    <SpaceContextProvider
      space={space}
      isSpaceDeleted={isSpaceDeleted}
      isSpaceClosed={isSpaceClosed}
      code={invitationCode}
      checksum={checksum}
      key={invitationCode || space.id}
      keySectionSignal={keySectionSignal}
      setKeySectionSignal={handleChangeKeySectionSignal}
      notiContext={notiContext}
      visitorId={visitorId}
    >
      <SpaceContext.Consumer>
        {({ isSpaceUserLoggedIn }) => (
          <FeatureFlagsContextProvider organizationId={space?.organizationId}>
            <ThemeProvider theme={getRuntimeTheme(theme)}>
              <CssBaseline />
              <LoadFontsOrganization isReady={isSpaceUserLoggedIn} includeDeletedFonts />
              <AppRuntimeContainer>
                {renderPages()}
                <SpaceAnalyticsHandler spaceId={space.id} />
              </AppRuntimeContainer>
              {props.children}
            </ThemeProvider>
          </FeatureFlagsContextProvider>
        )}
      </SpaceContext.Consumer>
    </SpaceContextProvider>
  );
};

SpaceAccessContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

AppRuntimeContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Array)]),
};

export default SpaceAccessContainer;
