import React, { Suspense, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SidePanelDrawer from './SidePanelDrawer';
import ParticipantList from '../SpaceUsers/ParticipantList';
import { useSpaceContext } from '../SpaceContext';
import { createResourceInteraction } from '../../commons/CommonServices';
import { openWindow } from '../../commons/utils';
import MaterialsWidget from '../Materials/MaterialsWidget';
import {
  Provider,
  ResourceInteractionType,
  ResourceType,
  PrivacySpace,
} from '../../app/appConstants';
import spaceUser from '../spaceUser';
import { useChatContext } from '../Comments/ChatContextProvider';
import i18n from '../../i18n';
import Loading from '../../components/Loading';
import MultipleSelectResourcesContextProvider from '../../components/Material/MultipleSelectResourcesContextProvider';
import SpaceSidebarContextProvider from './SpaceSidebarContextProvider';
import currentOrganization from '../../commons/CurrentOrganization';
import { isExternalLinkNotVideo } from '../../commons/ResourceUtils';
import ResourcesViewContextProvider from '../../components/ResourcesView/ResourcesViewContextProvider';
import { ResourceSources } from '../../resources/types';

const ChatContainer = React.lazy(() => import('../Comments/ChatContainer'));

const SpaceSidebar = (props) => {
  const { onResourceView, onClose } = props;
  const {
    space,
    sidebar,
    setSidebar,
    hideParticipants,
    isMobile,
    isOpenAddParticipantForm,
    setIsOpenAddParticipantForm,
    isPublicSpace,
    isGlobalResourcesEnabled,
    isTemplate,
  } = useSpaceContext();

  const { chatPublicChannel } = useChatContext();
  const isModerator = spaceUser.isHost() || currentOrganization.isAdmin();
  const [showCloseButton, setShowCloseButton] = React.useState(true);
  const [anchor] = React.useState('left');

  const navigate = useNavigate();

  const handleOnClose = useCallback(() => {
    if (showCloseButton) {
      setSidebar(null);
      setIsOpenAddParticipantForm(false);
      if (onClose) onClose();
    }
  }, [onClose, setIsOpenAddParticipantForm, setSidebar, showCloseButton]);

  const handleOnResourceView = useCallback(
    (material) => {
      if (
        material?.resourceId &&
        material?.type !== ResourceType.video &&
        material?.type !== ResourceType.vaam
      ) {
        const payload = {
          materialId: material.id,
          interactionType: ResourceInteractionType.Click,
        };
        if (material?.externalDocumentSettings?.provider === Provider.Vaam) {
          payload.IsVideoMessageViewed = true;
          payload.SpaceName = space.name;
        }
        try {
          createResourceInteraction(material.resourceId, payload);
        } catch (error) {
          console.error('Error in createResourceInteraction', error);
        }
      }
      if (
        isExternalLinkNotVideo(material) &&
        material.externalDocumentSettings?.provider !== Provider.Vaam
      ) {
        openWindow(material.src, '_blank');
      } else {
        onResourceView(material);
      }
    },
    [onResourceView, space.name]
  );

  const onResourceDownload = useCallback((material) => {
    if (material?.resourceId) {
      createResourceInteraction(material.resourceId, {
        materialId: material.id,
        interactionType: ResourceInteractionType.Downloaded,
      });
    }
  }, []);

  function renderMaterialsTab() {
    const canHideAddButton = hideParticipants;
    const sharingFolderId = props.sharingFolderId ? parseInt(props.sharingFolderId) : null;
    return (
      <MultipleSelectResourcesContextProvider>
        <ResourcesViewContextProvider
          id="space-sidebar"
          userInfoId={spaceUser.getUserInfoId()}
          source={ResourceSources.Space}
        >
          <MaterialsWidget
            space={space}
            isPublicSpace={isPublicSpace}
            spaceId={space?.id}
            isModerator={isModerator}
            isGlobalResourcesEnabled={isGlobalResourcesEnabled}
            onResourceView={handleOnResourceView}
            onResourceDownload={onResourceDownload}
            hideAddButton={canHideAddButton}
            setShowCloseButton={setShowCloseButton}
            selectedFolderId={sharingFolderId} // a trick to open sharing folder.
            onClose={handleOnClose}
          />
        </ResourcesViewContextProvider>
      </MultipleSelectResourcesContextProvider>
    );
  }

  function renderParticipantsTab() {
    if (space) {
      const mainChannelId = chatPublicChannel?.id;
      const userInfoId = spaceUser.getUserInfoId();
      const isShowRequestUsersAccess =
        isModerator && !isTemplate && space.spacePrivacy === PrivacySpace.PRIVATE;
      return (
        <div className="participant-tab">
          <ParticipantList
            spaceId={space?.id}
            userInfoId={userInfoId}
            mainChannelId={mainChannelId}
            isModerator={isModerator}
            isTemplate={isTemplate}
            isOpenAddParticipantForm={isOpenAddParticipantForm}
            // isOpenPrivateChat={!isShowroom}
            onClose={handleOnClose}
            isShowRequestUsersAccess={isShowRequestUsersAccess}
            isMobile={isMobile}
          />
        </div>
      );
    }
    return null;
  }

  function renderChatTabHeader() {
    const hidePrivateChat = hideParticipants;
    return (
      <>
        <div className="side-panel-title  comments-tabs">
          {space?.isTemplate ? (
            <div className="side-panel-title template">
              <div className="side-panel-title-bold">Chat</div>
            </div>
          ) : (
            <div className="comments-tabs-list">
              <div className="comment-tab-item active" disabled>
                <span>{i18n.t('Public chat')}</span>
              </div>
              {!hidePrivateChat && !isPublicSpace && (
                <div className="comment-tab-item" disabled>
                  <span>{i18n.t('Private chat')}</span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="close-side-panel">
          <IconButton
            aria-label="close"
            size="small"
            color="secondary"
            className="close-icon"
            onClick={handleOnClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </>
    );
  }

  function renderChatTab() {
    if (space?.isTemplate) {
      return (
        <div className="chat-main-container">
          {renderChatTabHeader()}

          <span className="no-data-template">
            {i18n.t('In spaces created from this template there will be a chat in this view.')}
          </span>
        </div>
      );
    }

    const isViewOnlyMode = spaceUser.isViewOnly();

    if (isViewOnlyMode) {
      return (
        <div className="loading-container" style={{ position: 'absolute' }}>
          {i18n.t('The chat is not available in View-only mode.')}
        </div>
      );
    }

    if (space && chatPublicChannel) {
      const spaceId = space.id;
      return (
        <div className="chat-tab">
          <Suspense fallback={<Loading />}>
            <ChatContainer
              spaceId={spaceId}
              isTemplate={isTemplate}
              mainChannelId={chatPublicChannel.id}
              mainChannel={chatPublicChannel}
              onResourceView={handleOnResourceView}
              onClose={handleOnClose}
            />
          </Suspense>
        </div>
      );
    }

    return <Loading />;
  }

  const handleCloseSidebar = showCloseButton ? handleOnClose : null;

  return (
    <SpaceSidebarContextProvider sidebar={sidebar}>
      <SidePanelDrawer
        open={!!sidebar}
        onClose={handleCloseSidebar}
        sidebarType={sidebar}
        navigate={navigate}
        renderMaterialsTab={renderMaterialsTab}
        renderChatTab={renderChatTab}
        renderParticipantsTab={renderParticipantsTab}
        anchor={anchor}
      />
    </SpaceSidebarContextProvider>
  );
};

SpaceSidebar.propTypes = {
  onResourceView: PropTypes.func,
  sharingFolderId: PropTypes.number,
  onClose: PropTypes.func,
};

export default SpaceSidebar;
