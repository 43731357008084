import i18n from '../i18n';

export const ErrorTypes = {
  SystemException: 'SystemException',
  TranslatableException: 'TranslatableException',
  PermissionDeniedException: 'PermissionDeniedException',
  ConferenceViolationException: 'ConferenceViolationException',
  SubscriptionRetryNeeded: 'SubscriptionRetryNeeded',
};

class ApiError extends Error {
  constructor(error, httpStatus, ...params) {
    super(...params);
    this.type = ErrorTypes.SystemException;
    this.error = error;
    this.displayableError = false;
    this.fields = null;
    this.httpStatus = httpStatus;

    if (
      error &&
      (error.type === ErrorTypes.PermissionDeniedException ||
        error.type === ErrorTypes.TranslatableException ||
        error.type === ErrorTypes.ConferenceViolationException ||
        error.type === ErrorTypes.SubscriptionRetryNeeded)
    ) {
      this.fields = error.fields;
      this.type = error.type;
      this.displayableError = true;
      this.message = i18n.t(error.message, error.vars || {});
    } else if (error.message) {
      this.message = i18n.t('Could not process your request, please contact administration');
    }
  }
}

export default ApiError;
