import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, LinearProgress } from '@mui/material';
import { FeatureFlagsType, UploadingFileState } from '../../app/appConstants';
import UploadButtons from './UploadButtons';
import { getProgress } from './utils';
import i18n from '../../i18n';
import ListItem from './ListItem';
import { getCombinedResources } from './DndFileUploaderUtils';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import UploaderLabel from '../DndFolderUploader/UploaderLabel';

const DndFileUploaderControl = ({
  progresses,
  files,
  pickFiles,
  spaceId,
  folderId,
  errors,
  uploadingState,
  resources,
  invalidResources,
  url,
  fileInputLabel,
  fileInputInfo,
  uploadBtnLabel,
  canPreview,
  canEditNameSameStep,
  allowEditNameInput,
  accept,
  multiple,
  isPortal,
  isGlobalResourcesEnabled,
  hideIntegrationGlobalResource,
  cropImageElement,
  unsplashElement,
  filePickerRef,
  inputResourceNameRef,
  onGlobalResourcesDialogOpened,
  onGlobalResourcesDialogClosed,
  handleResourcesChanged,
  handleDeleteInvalidResourceClick,
  handleCancelEditResourceNameClick,
  onRetry,
  onCancel,
  validPickedFilesCount,
  isAdmin,
  disabledUploadExternalFiles,
}) => {
  const showUploadBtn =
    uploadingState !== UploadingFileState.Started &&
    uploadingState !== UploadingFileState.Failed &&
    (multiple || (!multiple && resources.length === 0));
  const totalUploadedFiles = validPickedFilesCount - files.length;
  const totalUploadingFiles = files.length; // total remaining files that are going to upload
  const finalProgress =
    totalUploadingFiles > 0 ? getProgress(progresses, validPickedFilesCount) : 0;
  const isFailed = uploadingState === UploadingFileState.Failed;
  const combinedResources = getCombinedResources(resources, invalidResources);
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);

  React.useEffect(() => {
    if (isEnabledMaterialSlider && combinedResources.length > 0) {
      sessionStorage.setItem(
        'selectedFolderMaterials',
        JSON.stringify({
          spaceFolderId: -1,
          materials: combinedResources,
        })
      );
    }
  }, [isEnabledMaterialSlider, combinedResources]);

  return (
    <div className={`dnd-file-uploader-control ${url?.length > 0 ? 'disabled' : ''}`}>
      <UploaderLabel
        label={fileInputLabel}
        tooltip={fileInputInfo}
        element={
          !multiple ? (
            <>
              {resources.length === 1 && cropImageElement}
              {combinedResources.length === 0 && unsplashElement}
            </>
          ) : null
        }
      />

      {showUploadBtn && (
        <UploadButtons
          errors={errors}
          filePickerRef={filePickerRef}
          resources={resources}
          multiple={multiple}
          isGlobalResourcesEnabled={isGlobalResourcesEnabled}
          uploadBtnLabel={uploadBtnLabel}
          accept={accept}
          isPortal={isPortal}
          hideIntegrationGlobalResource={hideIntegrationGlobalResource}
          onGlobalResourcesDialogOpened={onGlobalResourcesDialogOpened}
          onGlobalResourcesDialogClosed={onGlobalResourcesDialogClosed}
          handleResourcesChanged={handleResourcesChanged}
          pickFiles={pickFiles}
          isAdmin={isAdmin}
          disabledUploadExternalFiles={disabledUploadExternalFiles}
        />
      )}

      {totalUploadingFiles > 0 && (
        <div className="upload-progress">
          <span className="file-name">
            {multiple && validPickedFilesCount > 1
              ? `Uploading ${totalUploadedFiles} of ${validPickedFilesCount} ${
                  validPickedFilesCount > 1 ? 'files' : 'file'
                }`
              : files[0].name}
          </span>
          <LinearProgress
            variant="determinate"
            value={finalProgress}
            className={`${isFailed ? 'failed' : ''}`}
          />
          <div className="bottom-section">
            {isFailed && (
              <>
                <span className="message failed">{i18n.t('Upload failed')}</span>
                <Box display="flex" alignItems="center" gap={16}>
                  <Button className="action-button" onClick={onRetry}>
                    {i18n.t('Retry')}
                  </Button>
                  <Button className="action-button" onClick={onCancel}>
                    {i18n.t('Cancel')}
                  </Button>
                </Box>
              </>
            )}
            {uploadingState === UploadingFileState.Completed && (
              <>
                <span className="message completed">{i18n.t('Complete')}</span>
              </>
            )}
            {uploadingState <= UploadingFileState.Started && (
              <>
                <span className="message">{`${i18n.t('Uploading')} ${finalProgress}%...`}</span>
                <Button className="action-button" onClick={onCancel}>
                  {i18n.t('Cancel')}
                </Button>
              </>
            )}
          </div>
        </div>
      )}

      <ListItem
        resources={resources}
        spaceId={spaceId}
        folderId={folderId}
        isPortal={isPortal}
        combinedResources={combinedResources}
        canPreview={canPreview}
        multiple={multiple}
        canEditNameSameStep={canEditNameSameStep}
        allowEditNameInput={allowEditNameInput}
        handleResourcesChanged={handleResourcesChanged}
        handleDeleteInvalidResourceClick={handleDeleteInvalidResourceClick}
        handleCancelEditResourceNameClick={handleCancelEditResourceNameClick}
        inputResourceNameRef={inputResourceNameRef}
      />
    </div>
  );
};

DndFileUploaderControl.propTypes = {
  progresses: PropTypes.instanceOf(Object),
  files: PropTypes.instanceOf(Array),
  pickFiles: PropTypes.func,
  spaceId: PropTypes.string,
  folderId: PropTypes.number,
  errors: PropTypes.instanceOf(Object),
  uploadingState: PropTypes.number,
  resources: PropTypes.instanceOf(Array),
  invalidResources: PropTypes.instanceOf(Array),
  url: PropTypes.string,
  fileInputLabel: PropTypes.string,
  fileInputInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  uploadBtnLabel: PropTypes.string,
  canPreview: PropTypes.bool,
  canEditNameSameStep: PropTypes.bool,
  allowEditNameInput: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  isPortal: PropTypes.bool,
  isGlobalResourcesEnabled: PropTypes.bool,
  hideIntegrationGlobalResource: PropTypes.bool,
  cropImageElement: PropTypes.element,
  unsplashElement: PropTypes.element,
  filePickerRef: PropTypes.instanceOf(Object),
  inputResourceNameRef: PropTypes.instanceOf(Object),
  onGlobalResourcesDialogOpened: PropTypes.func,
  onGlobalResourcesDialogClosed: PropTypes.func,
  handleResourcesChanged: PropTypes.func,
  handleDeleteInvalidResourceClick: PropTypes.func,
  handleCancelEditResourceNameClick: PropTypes.func,
  onRetry: PropTypes.func,
  onCancel: PropTypes.func,
  validPickedFilesCount: PropTypes.number,
  isAdmin: PropTypes.bool,
  disabledUploadExternalFiles: PropTypes.bool,
};

export default DndFileUploaderControl;
