import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { SpaceUserRole, PrivacySpace } from '../app/appConstants';
import { sendNotification } from '../commons/utils';
import Loading from '../components/Loading';
import spaceUser from './spaceUser';
import { createSpaceInvitation } from './spaceAccessServices';

import './AutoSigninProcessor.scss';
import RequestAccessDialog from '../components/RequestAccessDialog';
import useBrowserUtils from '../commons/useBrowserUtils';
import useSpaceAccessibilityQuery from './queries/useSpaceAccessibilityQuery';
import i18n from '../i18n';

const AutoSigninProcessor = ({ space, isTemplate, userInfo, isAdmin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setURLParams } = useBrowserUtils();
  const spaceId = space.id;
  const shouldCreateHiddenUser = isTemplate ? false : isAdmin;
  const spaceAccessibilityQuery = useSpaceAccessibilityQuery(spaceId, shouldCreateHiddenUser);

  function goToByViewModeRole() {
    const { spaceAccessToken, spaceAccessSession } = spaceAccessibilityQuery.data;
    spaceUser.login(spaceAccessToken, spaceAccessSession, null, null);

    spaceUser.setViewOnly(1);
    setURLParams({ viewOnly: 1 });
    const url = `${location.pathname}${window.location.search}`;

    navigate(url, {
      replace: true,
      state: { ...location.state, isTemplate },
    });
  }

  async function handleAutoSignInTemplate() {
    if (!isAdmin) {
      goToByViewModeRole();
    } else {
      const resp = await createSpaceInvitation(spaceId, SpaceUserRole.Host);
      if (resp.invitation) {
        const invc = resp?.invitation?.code;
        spaceUser.reset();
        setURLParams({ invc });
        const url = `${location.pathname}${window.location.search}`;

        navigate(url, {
          replace: true,
          state: { ...location.state, isTemplate },
        });
      }
    }
  }

  async function handleAutoSignInSpace() {
    if (space?.spacePrivacy === PrivacySpace.PRIVATE) {
      const data = {
        email: userInfo?.contactEmail,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
      };
      await RequestAccessDialog.show(
        'NO ACCESS',
        <div className="message-request-dialog">
          This is a private space,
          <br />
          the host has to grant you access.
        </div>,
        data,
        space.id,
        false
      );
    } else {
      const resp = await createSpaceInvitation(space.id, SpaceUserRole.Participant);
      const invc = resp?.invitation?.code;
      if (invc) {
        spaceUser.reset();
        setURLParams({ invc });
        const url = `${location.pathname}${window.location.search}`;

        navigate(url, {
          replace: true,
          state: { ...location.state },
        });
      }
    }
  }

  useEffect(() => {
    if (!spaceAccessibilityQuery.data) {
      return;
    }
    try {
      const { invitation } = spaceAccessibilityQuery.data;

      if (invitation) {
        spaceUser.reset();
        if (invitation.isExpired) {
          setTimeout(() => {
            sendNotification(i18n.t('This link is already used or expired.'), {
              type: 'error',
            });
          }, 100);
          navigate('/');
          return;
        }
        setURLParams({ invc: invitation.code });
        const url = `${location.pathname}${window.location.search}`;

        navigate(url, {
          replace: true,
          state: { ...location.state, isTemplate },
        });
        return;
      }

      if (isTemplate) {
        handleAutoSignInTemplate();
      } else {
        if (!isAdmin) {
          handleAutoSignInSpace();
        }
      }
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceAccessibilityQuery.data]);

  return (
    <div className="loading-box">
      <Loading />
    </div>
  );
};

AutoSigninProcessor.propTypes = {
  isTemplate: PropTypes.bool,
  space: PropTypes.instanceOf(Object),
  userInfo: PropTypes.instanceOf(Object),
  isAdmin: PropTypes.bool,
};

export default AutoSigninProcessor;
