import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { CMS_COMPONENT_TYPE } from '../../cms/cmsConstants';
import { useSpaceAnalyticsContext } from '../SpaceAnalyticsContext';
import { CacheKeys } from '../../app/queryCache';

export type CmsMaterial = {
  resourceId: number;
  materialId: number;
  tag: string;
  resourceName: string;
  useCmsName?: boolean;
};

const excludeFromCms: string[] = [
  CMS_COMPONENT_TYPE.WEB_CONTENT,
  CMS_COMPONENT_TYPE.HTML_CONTENT,
  CMS_COMPONENT_TYPE.SCRIPT,
  CMS_COMPONENT_TYPE.WIDGET,
];

const useCmsMaterialsQuery = () => {
  const { space, reportEndDate } = useSpaceAnalyticsContext();
  const endDate = moment(reportEndDate).toDate().toISOString();
  const populateCmsResources = () => {
    const allCmsResources = space?.customTheme?.items
      .map((x) => x.items.map((y) => y.items || [y]).reduce((prev, curr) => [...prev, ...curr], []))
      .reduce((prev, curr) => [...prev, ...curr], [])
      .map((x) => ({ ...x, type: x.type || '' }))
      .filter((x) => !excludeFromCms.includes(x.type))
      .map((x) => {
        const resources: CmsMaterial[] = [];
        if (x.videoUrl && x.videoUrlResourceId && x.videoUrlMaterialId) {
          resources.push({
            resourceId: x.videoUrlResourceId,
            materialId: x.videoUrlMaterialId,
            resourceName: '',
            tag: 'video',
          });
        }
        if (
          typeof x.backgroundImageUrl === 'number' &&
          x.backgroundImageUrlResourceId &&
          x.backgroundImageUrlMaterialId
        ) {
          resources.push({
            resourceId: x.backgroundImageUrlResourceId,
            materialId: x.backgroundImageUrlMaterialId,
            resourceName: '',
            tag: 'file',
          });
        }
        if (x.headingLinkResourceId && x.headingLinkMaterialId) {
          resources.push({
            resourceId: x.headingLinkResourceId,
            materialId: x.headingLinkMaterialId,
            resourceName: x.heading || 'Untitled',
            tag: 'heading',
            useCmsName: true,
          });
        }
        if (x.buttonLinkResourceId && x.buttonLinkMaterialId) {
          resources.push({
            resourceId: x.buttonLinkResourceId,
            materialId: x.buttonLinkMaterialId,
            resourceName: x.buttonLabel || 'Get Started',
            tag: 'primary button',
            useCmsName: true,
          });
        }
        if (x.secondaryButtonLinkResourceId && x.secondaryButtonLinkMaterialId) {
          resources.push({
            resourceId: x.secondaryButtonLinkResourceId,
            materialId: x.secondaryButtonLinkMaterialId,
            resourceName: x.secondaryButtonLabel || 'Get Started',
            tag: 'secondary button',
            useCmsName: true,
          });
        }

        return resources;
      })
      .reduce((prev, curr) => [...prev, ...curr], []);
    console.log('____allCmsResources', allCmsResources);
    return allCmsResources;
  };

  return useQuery({
    queryFn: () => populateCmsResources(),
    queryKey: [CacheKeys.spaceAnalytics_fetchAllCmsResources, space?.id, endDate],
  });
};

export default useCmsMaterialsQuery;
