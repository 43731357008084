import React from 'react';
import PropTypes from 'prop-types';
import DefaultLogo from '../images/spce-logo.png';
import './LogoSpace.scss';

const LogoSpace = ({ src, className, alt }) => {
  return (
    <div className={`logo-space ${className}`}>
      <img src={src || DefaultLogo} alt={alt || 'SPCE logo'} />
    </div>
  );
};

LogoSpace.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default LogoSpace;
