import React, { PureComponent } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';

import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _uniqBy from 'lodash/uniqBy';
import _remove from 'lodash/remove';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  ThemeProvider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from '../../i18n';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import DndFileUploader from '../../components/DndFileUploader3/DndFileUploader';
import { deleteResource } from '../../resources/ResourceServices';
import ConfirmService from '../../components/ConfirmService';
import { sendNotification } from '../../commons/utils';
import { removeFontNameExtension } from '../../cms/commons/FontSelect/utils';
import './UploadFontDialog.scss';
import OfficialButton from '../../components/OfficialButtons';
import FeatureFlagsContextProvider from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import currentOrganization from '../../commons/CurrentOrganization';
import AppProvider from '../../components/AppProvider';
import { defaultTheme } from '../../theme';
import queryCache from '../../app/queryCache';

const getFontsData = memoizeOne((fonts) => {
  const newFonts = [];
  if (fonts.length > 0) {
    fonts.forEach((font) => {
      const fontName = font.resourceName || font.name;
      const isNewFont = font.isNewFont || false;
      newFonts.push({
        id: font.resourceId || font.id,
        name: removeFontNameExtension(fontName),
        isNewFont,
      });
    });
  }

  return newFonts;
});

const DefaultFontAccept = 'application/font-woff, .ttf, .otf, .woff';

let resolve;
let containerElement;
let rootEl;

class UploadFontDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      resource: null,
      fonts: [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleResourceChange = this.handleResourceChange.bind(this);
    this.dndFileUploaderRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ fonts: getFontsData(this.props.fonts) });
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount UploadFontDialog');
    resolve = null;
    containerElement = null;
  }

  static destroy(retVal = false) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 0);
    }
    if (resolve) resolve(retVal);
  }

  static show(fonts, onChange) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(<UploadFontDialog fonts={fonts} onChange={onChange} />, containerElement);
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose() {
    this.setState({ isOpen: false }, () => {
      UploadFontDialog.destroy();
    });
  }

  handleResourceChange(fonts) {
    if (fonts) {
      const newFonts = getFontsData(fonts).map((font) => ({ ...font, isNewFont: true }));
      const newList = _uniqBy([...newFonts, ...this.state.fonts], 'id');
      this.setState({ fonts: getFontsData(newList) });
      if (this.props.onChange) this.props.onChange();
      // reset dndFileUploader component
      // if (this.dndFileUploaderRef.current?.handleDeleteResourceClick) {
      //   this.dndFileUploaderRef.current.handleDeleteResourceClick(font);
      // }
    }
  }

  async handleDeleteFont(event, resourceId) {
    event.preventDefault();

    const hasConfirmed = await ConfirmService.show(
      `${i18n.t('delete font')}`,
      `${i18n.t(
        'This font file will be deleted from your organization (if you are using the font in a space, it will still be active there). Do you want to delete?'
      )}`
    );

    if (!hasConfirmed) {
      return false;
    }

    try {
      const newFonts = this.state.fonts.slice();
      await deleteResource(resourceId);
      _remove(newFonts, (e) => e.id === resourceId);
      this.setState({ fonts: newFonts });
      if (this.props.onChange) this.props.onChange();
      sendNotification(i18n.t('Deleted successfully'), { type: 'success' });
      return true;
    } catch (error) {
      sendNotification(error.message, { type: 'error' });
      return false;
    }
  }

  renderFontList() {
    if (!this.state.fonts) return null;
    return (
      <List className="fonts-container">
        {this.state.fonts.map((item) => {
          return (
            <ListItem
              key={item.id || item.resourceId}
              className={`${item.isNewFont ? 'new-font' : ''}`}
              onClick={(e) => this.handleDeleteFont(e, item.id)}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" style={{ fontFamily: item.name }}>
                    {item.name}
                  </Typography>
                }
              />
              <ListItemIcon>
                {item.isNewFont && (
                  <span className="icon-correct">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                )}
                <DeleteIcon className="delete-icon" />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    );
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    // this component is used in UserContainer > DesignSettingContainer > DesignSetting, only admin can access.
    const isAdmin = true;
    const organizationId = currentOrganization.getOrganizationId();

    return (
      <ResponsiveDialog
        modal
        maxWidth="lg"
        className="white confirm-dialog upload-font-dialog"
        onClose={this.handleClose}
      >
        <div className="upload-font-dialog-content">
          <span className="title">{i18n.t('Manage your fonts')}</span>
          <div className="uploader-area">
            <ThemeProvider theme={defaultTheme}>
              <QueryClientProvider client={queryCache}>
                <AppProvider isPortal>
                  <FeatureFlagsContextProvider organizationId={organizationId}>
                    <DndFileUploader
                      ref={this.dndFileUploaderRef}
                      accept={DefaultFontAccept}
                      spaceId="00000000-0000-0000-0000-000000000000"
                      hideUrlInput
                      multiple
                      // hideFileNameInput
                      fileInputLabel={i18n.t('Upload Font')}
                      onChange={this.handleResourceChange}
                      isPortal
                      // canEditNameSameStep={false}
                      isAdmin={isAdmin}
                    />
                  </FeatureFlagsContextProvider>
                </AppProvider>
              </QueryClientProvider>
            </ThemeProvider>
          </div>
          <Divider />
          {this.renderFontList()}
          <div className="large-rounded-button submit-button">
            <OfficialButton
              label={i18n.t('Done')}
              onClick={this.handleClose}
              variant="large-primary"
            />
          </div>
        </div>
      </ResponsiveDialog>
    );
  }
}

UploadFontDialog.propTypes = {
  fonts: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
};

export default UploadFontDialog;
