import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import memoizeOne from 'memoize-one';
import _groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import { useQuery } from '@tanstack/react-query';
import { sendNotification } from '../../commons/utils';
import queryCache, { CacheKeys } from '../../app/queryCache';
import i18n from '../../i18n';
import ConfirmService from '../../components/ConfirmService';

import './Notifications.scss';
import SettingForm from './SettingForm';
import {
  fetchNotificationSettingForParticipants,
  updateNotificationSettingForParticipants,
} from './service';
import Loading from '../../components/Loading';
import { notificationTitles, labelSettingParticipants } from './constant';
import { mapTodoActivities } from './function';

const groupSettings = memoizeOne((data) => {
  const newData = mapTodoActivities(data);

  const group = _groupBy(newData, 'context');
  return group;
});

const NotificationPage = () => {
  const [settings, setSettings] = useState({});

  const notificationQuery = useQuery({
    queryKey: [CacheKeys.fetchNotificationSettingForParticipants],
    queryFn: async () => {
      const resp = await fetchNotificationSettingForParticipants();
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (notificationQuery?.data?.ruleSettings) {
      const notification = groupSettings(
        notificationQuery?.data.ruleSettings.filter((x) => x.context !== 'ParticipantInteraction')
      );
      setSettings(notification);
    }
  }, [notificationQuery?.data?.ruleSettings]);

  function checkAsLeastANotifyEnabled(data, newSetting) {
    let isAllEmailIsUnable = true;
    if (!data) {
      return false;
    }
    data.map((value) => {
      const hasRuleActivated = value.rules.find((rule) => rule.type === 'Email' && rule.enabled);
      if (hasRuleActivated) {
        isAllEmailIsUnable = false;
      }
      return value;
    });
    const newSettingEmailEnabled = newSetting.rules.find((i) => i.type === 'Email' && i.enabled);
    return isAllEmailIsUnable && newSettingEmailEnabled;
  }

  async function handleOnchangeSetting(setting) {
    try {
      queryCache.setQueryData([CacheKeys.fetchNotificationSettingForParticipants], (oldData) => {
        const newSettings = cloneDeep(oldData?.ruleSettings);
        const foundIndex = newSettings?.findIndex(
          (item) => item.context === setting.context && item.event === setting.event
        );
        newSettings[foundIndex] = setting;
        if (setting.event === 'EVENT_ACTIVITY_UPDATED') {
          const index = newSettings?.findIndex(
            (item) => item.context === setting.context && item.event === 'EVENT_ACTIVITY_ASSIGED'
          );
          newSettings[index].rules = setting.rules;
        }
        if (checkAsLeastANotifyEnabled(oldData.ruleSettings, setting)) {
          ConfirmService.show(
            'Notification activated',
            `Please note that this only activates notifications for new spaces. If you want to activate notifications for the spaces you've already created, you can click the three dots on a space and activate notifications for that specific space.`,
            'ok',
            null,
            true,
            null,
            false
          );
        }
        return {
          ...oldData,
          ruleSettings: newSettings,
        };
      });
      queryCache.removeQueries([CacheKeys.fetchSpaceNotificationSettings], { exact: false });

      await updateNotificationSettingForParticipants(setting);
      if (setting.synTodoEvent) {
        updateNotificationSettingForParticipants({
          ...setting,
          event: setting.synTodoEvent,
        });
      }
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    }
  }

  const renderSettingBoxForParticipant = (value) => {
    const info = notificationTitles[value];
    if (!info) {
      return null;
    }
    return (
      <>
        {settings[value].map((setting, index) => (
          <SettingForm
            type={`${info.title}-participant-tab`}
            key={index}
            name={value.type}
            defaultValue={setting}
            onChange={handleOnchangeSetting}
            labelSetting={labelSettingParticipants}
            hideAppNotification
            contextSetting="participant"
          />
        ))}
      </>
    );
  };

  const renderSettingForParticipant = () => {
    if (notificationQuery.isLoading) {
      <Loading />;
    }
    if (!settings) {
      return '';
    }

    return (
      <div className="user-limit-content styled-scrollbar">
        <div className="message-info">
          <div className="title">{i18n.t('Email notifications')}</div>
          <span className="message">
            {i18n.t('Choose which notifications you want your users to get, and how often.')}
          </span>
        </div>
        <div className="setting-boxs">
          <Grid container className="setting-row header" spacing={2}>
            <Grid xs={8} md={8} item>
              {i18n.t('Engagement')}
            </Grid>
            <Grid xs={2} md={3} item>
              {i18n.t('Frequency')}
            </Grid>
            <Grid xs={2} md={1} item />
          </Grid>
          {Object.keys(settings).map((item) => renderSettingBoxForParticipant(item))}
        </div>
      </div>
    );
  };

  return renderSettingForParticipant();
};

NotificationPage.propTypes = {
  handleDrawerToggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default NotificationPage;
