import React from 'react';
import { Popover } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import i18n from '../../i18n';
import { ResourceReferenceList } from '../../globalResources/context/Interfaces';
import './GlobalResourceUsageButton.scss';
import { getResourceUsageData } from '../../resources/ResourceServices';
import Loading from '../Loading';
import OfficialButton from '../OfficialButtons';

type GlobalResourceUsageButtonProps = {
  resourceId?: string;
};

export default function GlobalResourceUsageButton(props: GlobalResourceUsageButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [displayData, setDisplayData] = React.useState<Array<ResourceReferenceList> | null>(null);
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setAnchorEl(event.currentTarget);
      setIsProcessing(true);
      const data = await getResourceUsageData(props.resourceId);
      setDisplayData(data);
    } catch (error) {
      setIsProcessing(false);
    }
    setIsProcessing(false);
  };
  const handleClose = () => {
    setIsProcessing(false);
    setDisplayData(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popupId = open ? 'simple-popover' : undefined;
  return (
    <div>
      <OfficialButton variant="small-secondary" onClick={handleClick} label={i18n.t('View')} />

      <Popover
        id={popupId}
        open={open}
        className="global-resource-usage-popover"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {isProcessing ? (
          <div className="loading">
            <Loading />
          </div>
        ) : (
          displayData?.map(({ categoryName, children }, key) => (
            <div className="customized-dropdown-item-wrapper" key={key}>
              <div className="item-header">
                <div className="item-name">
                  {categoryName} ({children.length})
                </div>
              </div>
              {children.map(({ name, id }) => (
                <a
                  className="item-body"
                  key={id}
                  href={`${location.origin}/s/${id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="item-body-content">{name}</div>
                  <ArrowForwardIosIcon color="inherit" style={{ fontSize: 'inherit' }} />
                </a>
              ))}
            </div>
          ))
        )}
      </Popover>
    </div>
  );
}
