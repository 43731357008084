import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import SpaceTemplateDesigner from './SpaceTemplateDesigner';
import ThemeSettingsContextProvider from '../components/context/ThemeSettingsContextProvider';
import GlobalDndContext from '../../components/GlobalDndContext';
import Loading from '../../components/Loading';
import SpaceContextProvider from '../../spaces/SpaceContextProvider';
import NoAccessDialog from './NoAccessDialog';
import useSpaceInvitationQuery from './useSpaceInvitationQuery';
import { deleteResourcesPropertyInTheme } from '../cmsHelpers';

export const getDataFromQuery = memoizeOne((queryData, spaceId) => {
  const spaceData = queryData;
  const space = {
    ...spaceData?.space,
    id: spaceId,
    templateRefId: spaceData?.spaceTemplate?.id,
    templateCreatedByUserInfoId: spaceData?.spaceTemplate?.createdByUserInfoId,
  };
  const code = spaceData?.invitation?.code;
  deleteResourcesPropertyInTheme(space.customTheme);
  const theme = {
    customTheme: space.customTheme,
    theme: space.theme,
    isTemplate: space.isTemplate,
  };
  return {
    code,
    space,
    theme,
  };
});

function SpaceDesignMode(props) {
  const { spaceId, shouldCreateHiddenUser } = props;

  const fetchSpaceInvitationQuery = useSpaceInvitationQuery(spaceId, shouldCreateHiddenUser);

  if (fetchSpaceInvitationQuery?.isError) {
    return <NoAccessDialog />;
  }
  if (fetchSpaceInvitationQuery.isLoading) {
    return <Loading color="#000" />;
  }

  const { code, space } = getDataFromQuery(fetchSpaceInvitationQuery.data, spaceId);
  return (
    <SpaceContextProvider space={space} code={code} isPortal>
      <ThemeSettingsContextProvider
        space={space}
        fetchSpaceInvitationQuery={fetchSpaceInvitationQuery}
      >
        <GlobalDndContext keyList={space.id}>
          <SpaceTemplateDesigner />
        </GlobalDndContext>
      </ThemeSettingsContextProvider>
    </SpaceContextProvider>
  );
}

SpaceDesignMode.propTypes = {
  spaceId: PropTypes.string.isRequired,
  shouldCreateHiddenUser: PropTypes.bool,
};

export default SpaceDesignMode;
