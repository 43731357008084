import React, { lazy, useState, useEffect, useRef, Suspense } from 'react';
import currentOrganization from '../commons/CurrentOrganization';
import { useLocalStorage } from '../commons/CustomHooks';
import './SpacesOverview.scss';
import { SubscriptionType } from '../commons/Subscription/subscriptionConstants';
import { useAppContext } from '../components/AppProvider';
import GroupButtons from './GroupButtons';

const SpaceListContainer = lazy(() => import('./SpaceListContainer'));
const TemplateListContainer = lazy(() => import('./TemplateListContainer'));

const CreateSpaceDialog = lazy(() => import('../createSpace/CreateSpaceDialog'));
const CreateSpaceOrTemplateDialog = lazy(() => import('./CreateSpaceOrTemplateDialog'));

function SpacesOverview() {
  const organization = currentOrganization.getData();
  const isEnterprise =
    organization?.subscriptionTypeId === SubscriptionType.enterprise ||
    organization?.subscriptionTypeId === SubscriptionType.super;

  const { userInfo, isMobile } = useAppContext();

  const [showSpaceTemplateDialog, setShowSpaceTemplateDialog] = useState(false);

  const [selectedTab, setSelectedTab] = useLocalStorage(`selectedTab-${userInfo.id}`, 'Spaces');

  const showTemplates = selectedTab === 'Templates';

  const createSpaceRef = useRef();

  useEffect(() => {
    sessionStorage.removeItem('silentLoginAttemps');
    window.history.replaceState({}, document.title);
  }, []);

  function handleDialogClose() {
    setShowSpaceTemplateDialog(false);
  }

  function handleChangeTab(value) {
    setSelectedTab(value);
  }

  function handleOpenCreateSpacePopup(space, action) {
    createSpaceRef?.current?.onOpen(space, action);
  }

  function renderContent() {
    switch (selectedTab) {
      case 'Spaces':
        return (
          <SpaceListContainer
            onOpenEditSpace={handleOpenCreateSpacePopup}
            setShowSpaceTemplateDialog={setShowSpaceTemplateDialog}
            isEnterprise={isEnterprise}
          />
        );
      case 'Templates':
        return (
          <TemplateListContainer
            onOpenEditSpace={handleOpenCreateSpacePopup}
            setShowSpaceTemplateDialog={setShowSpaceTemplateDialog}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="app-body space-list-container">
      <section>
        <div className="space-list-content-wrapper">
          <GroupButtons
            isMobile={isMobile}
            handleChangeTab={handleChangeTab}
            showTemplates={showTemplates}
            selectedTab={selectedTab}
          />
          <Suspense fallback={<div className="sections-container-loading" />}>
            {renderContent()}
          </Suspense>
        </div>
        {showSpaceTemplateDialog && (
          <Suspense fallback={null}>
            <CreateSpaceOrTemplateDialog
              open
              selectedTab={selectedTab}
              onClose={handleDialogClose}
              onCreateSpace={handleOpenCreateSpacePopup}
            />
          </Suspense>
        )}
        <CreateSpaceDialog ref={createSpaceRef} />
      </section>
    </div>
  );
}

export default SpacesOverview;
