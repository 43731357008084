import React from 'react';
import Button from '@mui/material/Button';
import '../../variables.scss';
import './styles.scss';
import LightTooltip from '../LightTooltip';
import i18n from '../../i18n';
import { renderStyling } from './functions';

type OfficialButtonProps = {
  label: string;
  icon?: React.ReactNode;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  variant:
    | 'regular-primary'
    | 'regular-secondary'
    | 'regular-green'
    | 'regular-red'
    | 'regular-dark-grey'
    | 'rectangle-primary'
    | 'rectangle-secondary'
    | 'rectangle-green'
    | 'rectangle-grey'
    | 'rectangle-dark-grey'
    | 'large-primary'
    | 'large-secondary'
    | 'small-primary'
    | 'small-secondary'
    | 'small-green'
    | 'small-grey'
    | 'small-dark-grey'
    | 'small-rectangle-secondary'
    | 'small-rectangle-green-secondary'
    | 'small-rectangle-dark-grey';

  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  processingText?: string | Element;
  toolTipMessage?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  className?: string;
  isProcessing?: boolean;
  style?: React.CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  dataId?: string;
};

type Ref = HTMLButtonElement;

function handleOnClickDisabledButton(e: React.MouseEvent<HTMLButtonElement>) {
  e.preventDefault();
  e.stopPropagation();
}

const OfficialButton = React.forwardRef<Ref, OfficialButtonProps>(
  (
    {
      variant,
      icon,
      label,
      disabled,
      toolTipMessage,
      placement,
      onClick,
      isProcessing,
      className = '',
      processingText = 'Processing...',
      style,
      type,
      dataId,
      startIcon,
    },
    ref
  ) => {
    const isButtonDisabled = disabled || isProcessing;

    const styling = renderStyling(variant);

    const renderButtonContent = () => {
      if (isProcessing) {
        return processingText;
      }
      return (
        <>
          {startIcon}
          {i18n.t(label)}
          {icon}
        </>
      );
    };

    const renderDisabledButtonWithTooltip = () => {
      return (
        <LightTooltip
          title={toolTipMessage || ''}
          placement={placement}
          disableHoverListener={!toolTipMessage}
        >
          <Button
            ref={ref}
            className={`spce-btn ${styling} ${className} disabled`}
            onClick={handleOnClickDisabledButton}
            style={style}
          >
            <>{renderButtonContent()}</>
          </Button>
        </LightTooltip>
      );
    };

    function handleOnClickButton(e: React.MouseEvent<HTMLButtonElement>) {
      if (isButtonDisabled) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        if (onClick) {
          onClick(e);
        }
      }
    }

    function renderButton() {
      if (type) {
        return (
          <button
            ref={ref}
            className={`spce-btn ${styling} ${className}${isButtonDisabled ? ' disabled' : ''}`}
            onClick={handleOnClickButton}
            style={style}
            type={type}
            data-id={dataId}
          >
            <>{renderButtonContent()}</>
          </button>
        );
      }

      return (
        <Button
          ref={ref}
          disabled={isButtonDisabled}
          className={`spce-btn ${styling} ${className}`}
          onClick={onClick}
          style={style}
          data-id={dataId}
        >
          <>{renderButtonContent()}</>
        </Button>
      );
    }

    const renderButtonWithTooltip = () => {
      return (
        <LightTooltip
          title={toolTipMessage || ''}
          placement={placement}
          disableHoverListener={!toolTipMessage}
        >
          {renderButton()}
        </LightTooltip>
      );
    };

    if (!toolTipMessage) {
      return renderButton();
    }

    if (isButtonDisabled && !!toolTipMessage) {
      return renderDisabledButtonWithTooltip();
    }

    return renderButtonWithTooltip();
  }
);

export default OfficialButton;
