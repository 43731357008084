import React from 'react';
import { Box, Typography } from '@mui/material';
import i18n from '../../../i18n';

import './style.scss';

type SpaceUserAvatarProps = {
  icon: string;
  title: string;
  description: string;
  className?: string;
};

function SpaceModalHeader({ className, icon, title, description }: SpaceUserAvatarProps) {
  return (
    <Box display="flex" alignItems="center" className="logo-header">
      <Box className="box-title">
        <Box className={`box ${className}`}>
          <span className={`${icon}`}></span>
        </Box>
        <Box className="box-info" alignItems="center">
          <Typography className="box-title">{i18n.t(title)}</Typography>
          <span>{i18n.t(description)}</span>
        </Box>
      </Box>
    </Box>
  );
}

export default SpaceModalHeader;
